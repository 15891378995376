import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  position: relative;
  padding: 40px;

  h1.topo {
    font-size: 2em;
    line-height: 1.5em;
    color: var(--info-color);
  }
`;
export const ContainerCard = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;
export const Form = styled.form`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
  h1 {
    font-size: 1.5em;
    line-height: 1.5em;
    color: var(--info-color);
    margin-bottom: 50px;
    @media screen and (max-width: 600px) {
      margin-left: 20px;
    }
  }
  .MuiFormControl-root.MuiTextField-root {
    margin-left: 20px;
    @media screen and (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
  .form_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  label {
    color: #000;
  }

  input,
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }

  button[type='submit'] {
    width: 100%;
    max-width: 280px;
    padding: 0 1.5rem;
    height: 4rem;
    background: #33cc95;
    color: #ffffff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.8rem;
    font-weight: 600;
    margin-left: 20px;
    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
`;

export const TabelaContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  table {
    width: 100%;
    border-spacing: 0 0.5rem;
    padding: 20px;

    th {
      color: var(--text-body);
      font-weight: 400;
      padding: 15px 1rem;
      text-align: left;
      line-height: 1.5em;
      background-color: #ffffff;
      filter: brightness(0.9);
      &.status {
        text-align: center;
      }
      &.acao {
        max-width: 50px;
        text-align: center;
      }
      @media screen and (max-width: 600px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }

    tr {
      cursor: pointer;
      border: 2px solid #ccc;
      text-align: left;
      &:hover {
        filter: brightness(0.9);
      }
    }

    td {
      padding: 1rem 1rem;
      border: 0;
      background: var(--shape);
      font-weight: 400;
      color: var(--text-body);

      @media screen and (max-width: 600px) {
        &:nth-child(2) {
          display: none;
        }
      }

      &:first-child {
        color: var(--text-title);
      }

      &.deletar {
        color: #ccc;
        text-align: center;
        p {
          color: var(--red);
        }
        button {
          background-color: transparent;
          color: #ccc;
          padding: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    table td::before {
      /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
`;
