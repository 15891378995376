import * as S from "./styles";
import moment from 'moment';
import 'moment/locale/pt-br';
import React, {useState} from "react";
import { Loading } from "../Loading/index";
import { useDashboard } from "../../hooks/dashboard";
import {Box} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';


moment.locale('pt-br');

export function Dashboard() {
    const { dados, isLoading, filtraLista } = useDashboard();
    const [dataInicial, setDataInicio] = useState('');
    const [dataFinal, setDataFinal] = useState('');

     const handleFiltra = async event => {
        event.preventDefault();

        let data = {
            dataFinal: moment(dataFinal, "DD-MM-YYYY").format('YYYY-M-DD'),
            dataInicial: moment(dataInicial, "DD-MM-YYYY").format('YYYY-M-DD')
        }

        let result =  await filtraLista(data);
        
        if(result.erro === false){
            setDataFinal("");
            setDataInicio("");
        }
    }
 
    return (    
        <S.Container> 
            <h1 className="topo">Quantidade de auditorias por pasta</h1>
            {isLoading ?
            <Loading />
            :
            <>
            <S.TabelaContainer>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Pasta</th>
                                <th>Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? 
                                <tr>
                                    <td></td>
                                </tr>
                            : 
                            dados.itens &&
                            Object.entries(dados.itens).map((cliente) =>  (
                                <tr
                                    key={cliente[0].toString('base64')}
                                >
                                    <td data-label="Nome">{cliente[0] || ""}</td>
                                    <td data-label="Em atraso">
                                        {isLoading ? <Skeleton /> : cliente[1]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </S.TabelaContainer>
            </>
            }
        </S.Container>
    );
}
