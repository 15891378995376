import React, { useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import * as S from "./styles";
import logo from '../../assets/logo.svg';
import { logout } from "../../services/auth";
import { useHistory } from "react-router-dom";


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


const Sidebar = ({activePage}) => {
  
  const [sidebar, setSidebar] = useState(true);
  const [sidebarResponsive, setSidebarResponsive] = useState(true);
  let history = useHistory();

  const showSidebar = (e) => {
    setSidebar(!sidebar)
  };
  var showSidebarResponsive = () => sidebarResponsive === false ? setSidebar(true) : setSidebar(!sidebar);

  useEffect(() => {
      function handleResize() {
          if (window.innerWidth > 1300) {
              setSidebar(true);
              setSidebarResponsive(false);
          } else {
              setSidebar(false);
              setSidebarResponsive(true);
          }
      }

      handleResize();

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Logout = () => {
    logout();
  };
  const onPerfil = () => {
    history.push("/perfil");
  };
  
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <S.Nav onClick={showSidebar}>
          <S.NavIcon to='#' >
            <FaIcons.FaBars  />
          </S.NavIcon>
        </S.Nav>
        <S.SombraResponsive sidebar={sidebar}  onClick={showSidebarResponsive} ></S.SombraResponsive>
        <S.SidebarNav sidebar={sidebar}>
          <S.SidebarWrap>
            <img src={logo} alt="logo"/>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className="nav-user"
            >
              <ListItem button onClick={handleClick}>
                <ListItemText primary={String(localStorage.getItem('NPROWORK'))} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button  onClick={onPerfil} >
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Perfil" />
                  </ListItem>
                  <ListItem button onClick={Logout} >
                    <ListItemIcon>
                      <ExitToAppIcon /> 
                    </ListItemIcon>
                    <ListItemText primary="Sair" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
            {SidebarData.map((item, index) => (item?.visible || item?.subNav?.visible) && <SubMenu item={item} active={activePage} key={index} />)}
          </S.SidebarWrap>
        </S.SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
