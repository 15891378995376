import api from "../services/api";
import {createContext, useState, useContext } from 'react';
import { toast } from 'react-toastify';

const GrupoContext = createContext({});

export const GrupoProvider = (props) =>  {
    const [grupoLista, setGrupoLista] = useState([]);
    const [grupoPorUUID, setGrupoPorUUID] = useState([]);
    const [isLoadingView, setIsLoading] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [paginationGrupo, setPagination] = useState([] || '');
    const [activePage, setPageActive] = useState('');
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);



    async function handleGrupo(uuid) {
        try {

            setIsLoading(true);
            const response = await api.get('/grupo/tarefa/'+uuid);
            
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setPagination(data);
            setGrupoLista(response.data);
            setIsLoading(false);

          } catch (err) {
            setIsLoading(false); 
        }
    }
    // Paginação
    async function grupoPage(page) {
        try {
            setIsLoading(true);
            
            const response = await api.get('/grupo?page='+page);             

            setGrupoLista(response.data);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setIsLoading(false);
            setPagination(data);

        } catch (err) {
            setIsLoading(false); 
        }
    }

    // USUÁRIOS POR UUID

    async function getGrupoPorUuid(uuid,page) {
        try {
            setIsLoadingEdit(true);
            const responseedit = await api.get('/grupo/'+uuid); 

            setGrupoPorUUID([responseedit.data]);
            setPageActive(page);
            setIsLoadingEdit(false);
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false);
        }
    }

    // Criar OS USUÁRIOS
    async function criaGrupo(UsuarioInputEdit, modal) {
        try {
            setIsLoading(true);

            const response = await api.post('/grupo', UsuarioInputEdit); 
      
            let keysToRemove = ["buscaAtivaGrupo", "salvarBuscaGrupo"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));

            setIsLoading(true);
            const responseRecarrega = await api.get('/grupo/tarefa/'+UsuarioInputEdit.checklist);
            
            const data = [{
                    'pagina_atual' : responseRecarrega.data.pagina_atual,
                    'proxima_pagina' : responseRecarrega.data.proxima_pagina,
                    'pagina_anterior' : responseRecarrega.data.pagina_anterior,
                    'ultima_pagina' : responseRecarrega.data.ultima_pagina,
                    'total' : responseRecarrega.data.total
                }
            ];
            setPagination(data);
            setGrupoLista(responseRecarrega.data);
            setIsLoading(false); 
            modal();

            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            return {
                erro: false
            };
        } catch (err) {
            setIsLoading(false); 
             toast.error(err.response.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
            return {
                erro: true
            };
        }
    } 

    
    // EDITANDO OS USUÁRIOS
    async function editaGrupo(UsuarioInputEdit, uuid) {
        try {

            setIsLoadingEdit(true);
            const response = await api.put('/grupo/'+uuid, UsuarioInputEdit); 

            toast.success(response?.data?.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoadingEdit(false);

        } catch (err) {
            toast.error(err.response?.data?.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false); 
        }
    } 


    // Pesquisar Catálogo
    
    async function searchGrupo(search, Modal) {
        try {
            setIsLoadingSearch(true);
            
            if(search.titulo === '' && search.cpf === '' && search.tipo === '' && search.status === ''){
                handleGrupo();
            } else {
                 const response = await api.get('/grupo?titulo='+search.titulo+'&cpf='+search.cpf.replace(/\D/g, '')+'&tipo='+search.tipo+'&status='+search.status); 

                 if(response.data.error === true ){
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                 }else {
                    localStorage.setItem('buscaAtivaGrupo', 'ativa');
                    localStorage.setItem('salvarBuscaGrupo', JSON.stringify(search));
                    
                    setGrupoLista(response.data);
                    const data = [{
                        'quantidade_pagina' : response.data.quantidade_pagina,
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                        }
                    ];
                    setPagination(data);
                 }
            }
            
            setIsLoadingSearch(false);
            Modal();
            

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }
    async function limpaPesquisaGrupo() {
        try {
            setIsLoadingSearch(true);
            
            handleGrupo();
            let keysToRemove = ["buscaAtivaGrupo", "salvarBuscaGrupo"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                handleGrupo();

            setIsLoadingSearch(false);

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }

    
    
    async function deletarGrupo(uuid, checklist) {
        try {
            setIsLoading(true);
            let response = await api.delete('/grupo/'+uuid);     
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoading(true);
            const responseRecarrega = await api.get('/grupo/tarefa/'+checklist);
            
            const data = [{
                    'pagina_atual' : responseRecarrega.data.pagina_atual,
                    'proxima_pagina' : responseRecarrega.data.proxima_pagina,
                    'pagina_anterior' : responseRecarrega.data.pagina_anterior,
                    'ultima_pagina' : responseRecarrega.data.ultima_pagina,
                    'total' : responseRecarrega.data.total
                }
            ];
            setPagination(data);
            setGrupoLista(responseRecarrega.data);
            setIsLoading(false); 

        } catch (err) {
            setIsLoading(true);

            toast.success(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });

            setIsLoading(true);
            const responseRecarrega = await api.get('/grupo/tarefa/'+uuid);
            
            const data = [{
                    'pagina_atual' : responseRecarrega.data.pagina_atual,
                    'proxima_pagina' : responseRecarrega.data.proxima_pagina,
                    'pagina_anterior' : responseRecarrega.data.pagina_anterior,
                    'ultima_pagina' : responseRecarrega.data.ultima_pagina,
                    'total' : responseRecarrega.data.total
                }
            ];
            setPagination(data);
            setGrupoLista(responseRecarrega.data);

            setIsLoading(false); 
        }
    }
 

    return (
        <GrupoContext.Provider value={{isLoadingView, grupoLista, getGrupoPorUuid, grupoPorUUID, editaGrupo, criaGrupo, grupoPage, paginationGrupo, activePage, isLoadingEdit, searchGrupo, limpaPesquisaGrupo, isLoadingSearch, deletarGrupo, handleGrupo}}>  
            {props.children}
        </GrupoContext.Provider>
    )

}



export function useGrupo() {
    const context = useContext(GrupoContext);

    return context;
}
