import * as S from "./styles";
import { useState, useEffect } from 'react';
import { useCheckList } from "../../hooks/checklist"
import { useItemRespostas } from "../../hooks/itemRespostas"
import { useGrupo } from "../../hooks/grupo"
import { TextField, FormControl, Card, CardContent, Select, MenuItem, InputLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Paper} from "@material-ui/core";
import Draggable from 'react-draggable';
import { CriaNovoItemCheckListModal } from "../CriaNovoItemCheckListModal/index";
import { EditNovoItemCheckListModal } from "../EditNovoItemCheckListModal/index";
import api from "../../services/api";
import { toast } from 'react-toastify';
import { Loading } from "../Loading/index";
import Skeleton from "react-loading-skeleton";

import {
    useParams
  } from "react-router-dom";


function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export function EditarGrupo() {
    
    const { isLoading, checklistItem, paginationItem, deletarItemCheckList, getCheckListItemPorUuid } = useCheckList();
    const { itemRespostasListaAtiva } = useItemRespostas();    
    const { editaGrupo, isLoadingEdit } = useGrupo();
    const { id } = useParams();
    const { checklist } = useParams();

    const [titulo, setTitulo] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
        
    const [openConfirmDeletar, setOpenConfirmarDeletar] = useState(false);
    const [abreModalAdd, setAbreModalAdd] = useState(false);
    const [abreModalEdit, setAbreModalEdit] = useState(false);
    const [uuidItemEdit, setUuidItemEdit] = useState('');
    const [nomeDelItem, setNomeCheckList] = useState('');
    const [uuidDelItem, setUuidCheckList] = useState('');
    const [updatePage, setUpdatePage] = useState(false);
    
    
    // Abre modal de add item
    function handleAbreModalAdd(){
        setAbreModalAdd(true);
    }

    function handleFechaAddtoModal(){
        setAbreModalAdd(false);
    }

    // Abre modal de editar item
    function handleAbreModalEdit(uuid){
        setUuidItemEdit(uuid);
        setAbreModalEdit(true);
    }

    function handleFechaEdittoModal(){
        setUuidItemEdit('');
        setAbreModalEdit(false);
    }
    
    
    useEffect(() => {
        setLoading(true);
        api.get("/grupo/"+id)
            .then(({ data }) => {
                setLoading(false);
                setTitulo(data.titulo || "");
                setStatus(data?.status || "");
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response?.data.message, {
                    position: toast.POSITION.TOP_CENTER
                }); 
    
            })
            .finally((response) => setLoading(false));

            getCheckListItemPorUuid(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePage]);





    const handlEditChecklist = async event => {
        event.preventDefault();

        let data = {
            titulo,
            status,
        }

        await editaGrupo(data, id);
        
        
    }

    
    
    // modal de deletar item
    const handleCloseDeletarConfirm = async () => {
        setOpenConfirmarDeletar(false);
        setNomeCheckList('');
        setUuidCheckList('');
    };
    

    const handleDeletarConfirm = async (uuid) => {
        setOpenConfirmarDeletar(false);
        await deletarItemCheckList(uuid, id);
        setNomeCheckList('');
        setUuidCheckList('');
    };

    const handleClickOpenConfirm = async (nome, uuid) => {
        setOpenConfirmarDeletar(true);
        setNomeCheckList(nome);
        setUuidCheckList(uuid);
    };


    return (
        <S.Wrapper>
            <S.Container onSubmit={handlEditChecklist}>
                {loading ? 
                    <Loading />
                :
                <>
                    <S.ContainerCardForm>
                        <div className="card-form">
                            <Card className="item">
                                <CardContent>
                                    <S.TituloTopo>Dados</S.TituloTopo>
                                    <S.Column>
                                        <FormControl>
                                            <TextField
                                            variant="outlined"
                                            label={'Título'}
                                            value={titulo}
                                            placeholder="Título"
                                            onChange={event => setTitulo(event.target.value)}
                                            />
                                        </FormControl>
                                    
                                    </S.Column>
                                </CardContent>
                            </Card>
                        
                            <Card className="item">
                                <CardContent>
                                    <S.TituloTopo>Acesso</S.TituloTopo>
                                    <S.Column>
                                        <FormControl variant="outlined"  >
                                            <InputLabel id="demo-simple-select-required-label">Status</InputLabel>
                                            <Select
                                                variant="outlined"
                                                onChange={event => setStatus(event.target.value)}
                                                value={status}
                                                label={'Status'}
                                                placeholder="Status"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione um status</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Ativo</MenuItem>
                                                <MenuItem value={2}>Inativo</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </S.Column>
                                </CardContent>
                            </Card>
                        </div>
                    </S.ContainerCardForm>
                    <button type="submit">
                        {isLoadingEdit ? 'Carregando...' : 'Atualizar'}
                    </button>
                </>
                }
            </S.Container>

            <S.ContainerItem>
                <h2>Questões</h2>
                <button className="add" type="button" onClick={() => handleAbreModalAdd()}>
                    Nova questão
                </button>
                <S.Container>

                {isLoading ? 
                <Loading />
                : 
                    <table>
                        <thead>
                            <tr>
                                <th>Texto</th>
                                <th>Data de criação</th>
                                <th>Ordem</th>
                        <th className="status">Status</th>
                                <th className="acao">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {checklistItem ? checklistItem.map(lista =>  (
                                <tr key={lista.uuid}>
                                    <td data-label="Texto" onClick={() => handleAbreModalEdit(lista.uuid,paginationItem[0].pagina_atual)}>{isLoading ? <Skeleton /> : lista.texto}</td>
                                    <td data-label="Data de criação" onClick={() => handleAbreModalEdit(lista.uuid,paginationItem[0].pagina_atual)}>
                                        {isLoading ? <Skeleton /> : lista.data_criacao?.br}
                                    </td>
                                    <td data-label="Ordem" onClick={() => handleAbreModalEdit(lista.uuid,paginationItem[0].pagina_atual)}>
                                        {isLoading ? <Skeleton /> : lista.ordem}
                                    </td>
                                    <td data-label="Status" className={lista.status === 1 ? 'ativo' : 'inativo'} 
                                    onClick={() => handleAbreModalEdit(lista.uuid,paginationItem[0].pagina_atual)}>
                                        {isLoading ? <Skeleton /> : <div></div>}
                                    </td>
                                    <td data-label="Deletar" className="deletar" onClick={() => handleClickOpenConfirm(lista.descricao, lista.uuid)} >
                                        <p>
                                            Deletar
                                        </p>
                                    </td>
                                </tr>
                            )) : null}

                        </tbody>
                    </table>
                }
                        
                </S.Container>
            </S.ContainerItem>            
            {abreModalAdd ? <CriaNovoItemCheckListModal
              abre={abreModalAdd}
              fecha={handleFechaAddtoModal}
              uuid={id}
              checklist={checklist}
              itens={itemRespostasListaAtiva.length > 0 ? itemRespostasListaAtiva : []}
            /> : null}
            {abreModalEdit ? <EditNovoItemCheckListModal
              abre={abreModalEdit}
              fecha={handleFechaEdittoModal}
              uuid={uuidItemEdit}
              checklist={checklist}
              grupo={id}
              itens={itemRespostasListaAtiva.length > 0 ? itemRespostasListaAtiva : []}
              setUpdatePage={setUpdatePage}
            /> : null}
            
            <Dialog
            open={openConfirmDeletar}
            onClose={handleCloseDeletarConfirm}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Deletar
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Tem certeza que deseja deletar o item {nomeDelItem || ''}, essa ação não poderá ser desfeita
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={() => handleCloseDeletarConfirm()} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => handleDeletarConfirm(uuidDelItem || '')} color="secondary">
                    Deletar
                </Button>
                </DialogActions>
            </Dialog> 
        </S.Wrapper>
    );
}





