import api from '../services/api';
import { createContext, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import 'moment/locale/pt-br';

moment.locale('pt-br');

const AuditoriaContext = createContext({});

export const AuditoriaProvider = (props) => {
  const [pastaListaAtiva, setPastaListaAtiva] = useState([]);
  const [usuarioLista, setUsuarioLista] = useState([]);
  const [empresaListaAtiva, setEmpresaListaAtiva] = useState([]);
  const [origemListaAtiva, setOrigemListaAtiva] = useState([]);
  const [tarefaImagem, setTarefaImagem] = useState([]);
  const [verTarefaUUID, setVerTarefaPorUUID] = useState([]);
  const [isLoadingAuditoria, setIsLoadingAuditoria] = useState(false);
  const [isLoadingResposta, setIsLoadingResposta] = useState(false);
  const [verRespostaUUID, setVerRespostaPorUUID] = useState([]);

  async function VisualizarPasta() {
    try {
      setIsLoadingAuditoria(true);
      const responseedit = await api.get('/cliente');

      setPastaListaAtiva([responseedit.data]);

      setIsLoadingAuditoria(false);
      return {
        erro: false,
        dados: [responseedit.data]
      };
    } catch (err) {
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingAuditoria(false);
      return {
        erro: true
      };
    }
  }

  async function VisualizarTarefaPorUUID(uuid) {
    try {
      setIsLoadingAuditoria(true);
      const responseedit = await api.get('/tarefa/' + uuid);

      let responseImagem = await api.get('/imagem/' + uuid + '/tarefas');
      setTarefaImagem([responseImagem.data]);
      setVerTarefaPorUUID([responseedit.data]);

      setIsLoadingAuditoria(false);
      return {
        erro: false,
        dado: responseedit.data,
        imagem: [responseImagem.data]
      };
    } catch (err) {
      setIsLoadingAuditoria(true);
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingAuditoria(false);
      return {
        erro: true
      };
    }
  }

  async function visualizarListadeUsuario(dados = false) {
    try {
      let query = '?status=1';
      if (dados.auditor) {
        query += '&auditor=true';
      }
      if (dados.quantidade) {
        query += `&quantidade=${dados.quantidade}`;
      }
      setIsLoadingAuditoria(true);
      const response = await api.get('/usuario' + query);

      setUsuarioLista(response.data.itens);

      setIsLoadingAuditoria(false);
      return {
        erro: false
      };
    } catch (err) {
      setIsLoadingAuditoria(true);
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingAuditoria(false);
      return {
        erro: true
      };
    }
  }

  async function visualizaListadeEmpresaAtiva() {
    try {
      setIsLoadingAuditoria(true);
      const response = await api.get('/empresa?status=1');

      setEmpresaListaAtiva(response.data.itens);

      setIsLoadingAuditoria(false);
      return {
        erro: false
      };
    } catch (err) {
      setIsLoadingAuditoria(true);
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingAuditoria(false);
      return {
        erro: true
      };
    }
  }

  async function visualizaListadeOrigemAtiva(quantidade = null) {
    try {
      setIsLoadingAuditoria(true);
      let qnt = quantidade ? `&quantidade=${quantidade}` : '&quantidade=15';
      const response = await api.get(`/origem?status=1${qnt}`);

      setOrigemListaAtiva(response.data);

      setIsLoadingAuditoria(false);
      return {
        erro: false
      };
    } catch (err) {
      setIsLoadingAuditoria(true);
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingAuditoria(false);
      return {
        erro: true
      };
    }
  }

  async function uploadArquivo(dados) {
    try {
      setIsLoadingAuditoria(true);

      let data = {...dados, usuario: localStorage.getItem('LPROWORK')}

      let response = await api.post('/imagem', data);

      let responseImagem = await api.get(
        '/imagem/' + dados.vinculo + '/tarefas'
      );
      setTarefaImagem([responseImagem.data]);

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      setIsLoadingAuditoria(false);
      return {
        erro: false,
        dado: [responseImagem.data]
      };
    } catch (err) {
      setIsLoadingAuditoria(true);

      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      setIsLoadingAuditoria(false);
      return {
        erro: true
      };
    }
  }

  // EDITANDO
  async function editaTarefa(
    EmpresaInputEdit,
    uuid,
    EmailInputEdita,
    id_email
  ) {
    try {
      setIsLoadingAuditoria(true);

      const data = new FormData();
      data.append('cliente', EmpresaInputEdit.cliente);
      data.append('responsavel', EmpresaInputEdit.responsavel);
      data.append('solicitante', EmpresaInputEdit.solicitante);
      data.append('inicio_previsto', EmpresaInputEdit.inicio_previsto);
      data.append('conclusao_prevista', EmpresaInputEdit.conclusao_prevista);
      data.append('acao', EmpresaInputEdit.acao);
      data.append('problema', EmpresaInputEdit.problema);
      data.append('empresa', EmpresaInputEdit.empresa);
      data.append('origem', EmpresaInputEdit.origem);
      data.append('inicio', EmpresaInputEdit.inicio);
      data.append('conclusao', EmpresaInputEdit.conclusao);
      data.append('status', EmpresaInputEdit.status);
      data.append('tipo', EmpresaInputEdit.tipo);
      data.append('dia', EmpresaInputEdit.dia);
      data.append('dias_semana', EmpresaInputEdit.dias_semana);
      data.append('recorrente', EmpresaInputEdit.recorrente);

      const response = await api.post('/tarefa/' + uuid, data);

      if (response.data.error === false) {
        if (
          EmailInputEdita.acao === 'edit' &&
          EmailInputEdita.emailExecuta === true
        ) {
          let dataEmail = {
            destinatario: EmailInputEdita.destinatario,
            assunto: EmailInputEdita.assunto,
            corpo: EmailInputEdita.corpo,
            status: 1
          };
          await api.put('/email/' + id_email, dataEmail);
        } else if (
          EmailInputEdita.acao === 'add' &&
          EmailInputEdita.emailExecuta === true
        ) {
          let dataEmail = {
            vinculo: EmailInputEdita.vinculo,
            destinatario: EmailInputEdita.destinatario,
            assunto: EmailInputEdita.assunto,
            corpo: EmailInputEdita.corpo,
            status: 1
          };
          await api.post('/email', dataEmail);
        }
      }

      const responsepage = await api.get('/tarefa/' + uuid);
      setVerTarefaPorUUID([responsepage.data]);

      setIsLoadingAuditoria(false);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: false
      };
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingAuditoria(false);
      return {
        erro: true
      };
    }
  }

  // Gerar tarefas pela tag

  async function addTaskTag(tarefa) {
    try {
      setIsLoadingAuditoria(true);
      await api.post('/tarefa/tag', { tarefa });
      setIsLoadingAuditoria(false);
      return {
        erro: false
      };
    } catch (err) {
      setIsLoadingAuditoria(false);
    }
  }

  async function deletarTarefaRecorrente(uuid) {
    try {
      setIsLoadingAuditoria(true);

      let response = await api.delete('/recorrente/' + uuid);

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      setIsLoadingAuditoria(false);
      return {
        erro: false
      };
    } catch (err) {
      setIsLoadingAuditoria(true);

      toast.success(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      setIsLoadingAuditoria(false);
      return {
        erro: true
      };
    }
  }

  async function createTarefaRecorrente(UsuarioInputEdit, modal) {
    try {
      setIsLoadingAuditoria(true);

      const response = await api.post('/recorrente', UsuarioInputEdit);

      let keysToRemove = [
        'buscaAtivaTarefaRecorrente',
        'salvarBuscaTarefaRecorrente'
      ];

      keysToRemove.forEach((k) => localStorage.removeItem(k));

      setIsLoadingAuditoria(false);

      modal();
      window.location.reload();

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: false
      };
    } catch (err) {
      setIsLoadingAuditoria(false);
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: true
      };
    }
  }
  async function deletarArquivo(dados, reload = true) {
    try {
      setIsLoadingAuditoria(true);

      let response = await api.delete('/imagem/' + dados);

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      reload ?? window.location.reload();

      setIsLoadingResposta(false);
      setIsLoadingAuditoria(false);
    } catch (err) {
      setIsLoadingAuditoria(true);

      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      setIsLoadingResposta(false);
      setIsLoadingAuditoria(false);
    }
  }

  async function VisualizarRespostaPorUUID(uuid, filtro = false) {
    try {
      setIsLoadingResposta(true);
      let filtroBusca = "";
      if(filtro){
        filtroBusca = `&filtro=${filtro}`
      }
      const responseedit = await api.get(
        '/resposta/tarefa/' + uuid + '?quantidade=1000&resposta=conforme' + filtroBusca
      );

      setVerRespostaPorUUID(responseedit.data);

      setIsLoadingResposta(false);
      setIsLoadingAuditoria(false);
      return {
        erro: false
      };
    } catch (err) {
      setVerRespostaPorUUID([]);
      setIsLoadingResposta(false);
      setIsLoadingAuditoria(false);
      return {
        erro: true
      };
    }
  }

  return (
    <AuditoriaContext.Provider
      value={{
        VisualizarPasta,
        isLoadingAuditoria,
        pastaListaAtiva,
        VisualizarTarefaPorUUID,
        verTarefaUUID,
        visualizarListadeUsuario,
        usuarioLista,
        visualizaListadeEmpresaAtiva,
        empresaListaAtiva,
        origemListaAtiva,
        visualizaListadeOrigemAtiva,
        tarefaImagem,
        uploadArquivo,
        editaTarefa,
        addTaskTag,
        deletarTarefaRecorrente,
        createTarefaRecorrente,
        deletarArquivo,
        VisualizarRespostaPorUUID,
        isLoadingResposta,
        verRespostaUUID
      }}
    >
      {props.children}
    </AuditoriaContext.Provider>
  );
};

export function useAuditoria() {
  const context = useContext(AuditoriaContext);

  return context;
}
