import * as S from './styles';
import { useState, useEffect, useCallback } from 'react';
import { useAcao } from '../../hooks/acao';
import { useEmpresa } from '../../hooks/empresa';
import { useAuditoria } from '../../hooks/auditoria';
import { useCliente } from '../../hooks/cliente';
import {
  TextField,
  FormControl,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  Paper
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { toast } from 'react-toastify';
import { Loading } from '../Loading/index';

import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

import { useParams } from 'react-router-dom';

moment.locale('pt-br');

export function FormEditaAcao() {
  let history = useHistory();
  const { id } = useParams();

  const { empresaListaAtiva } = useEmpresa();
  const { visualizaListadeClienteAtiva, clienteAtivo } = useCliente();
  const { editAcao, isLoading, getAcaoPorUuid, acaoPorUUID } = useAcao();
  const {
    usuarioLista,
    visualizarListadeUsuario,
    origemListaAtiva,
    visualizaListadeEmpresaAtiva,
    visualizaListadeOrigemAtiva
  } = useAuditoria();

  const gestor = Number(localStorage.getItem('GSPROWORK'));

  const usuarioPermissao = Number(localStorage.getItem('MMPROWORK'));

  /**
   * useEffects
   */
  async function start() {
    await getAcaoPorUuid(id);
    await visualizarListadeUsuario({ auditor: true, quantidade: 1000 });
    await visualizaListadeEmpresaAtiva();
    await visualizaListadeOrigemAtiva(1000);
    await visualizaListadeClienteAtiva(1000);
  }

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    setProblema(acaoPorUUID?.problema);
    setCliente(acaoPorUUID?.cliente);
    setAcao(acaoPorUUID?.acao);
    setOrigem(acaoPorUUID?.origem);
    setStatus(acaoPorUUID?.status);
    setResponsavel(acaoPorUUID?.responsavel?.uuid);
    setEmpresa(acaoPorUUID?.empresa);
    setInicioPrevisto(acaoPorUUID?.inicio_previsto?.valor);
    setConclusaoPrevista(acaoPorUUID?.conclusao_prevista?.valor);
    setInicio(acaoPorUUID?.inicio?.valor);
    setConclusao(acaoPorUUID?.conclusao?.valor);
    setAuditoria(acaoPorUUID?.auditoria);
  }, [acaoPorUUID]);

  /**
   * State
   */
  const [responsavel, setResponsavel] = useState('');
  const [cliente, setCliente] = useState('');
  const [problema, setProblema] = useState('');
  const [acao, setAcao] = useState('');
  const [origem, setOrigem] = useState('');
  const [status, setStatus] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [inicio_previsto, setInicioPrevisto] = useState(null);
  const [conclusao_prevista, setConclusaoPrevista] = useState(null);
  const [inicio, setInicio] = useState(null);
  const [conclusao, setConclusao] = useState(null);
  const [auditoria, setAuditoria] = useState('');

  const minDate = new Date(Date.now());

  /**
   * Handles
   */
  const onInicioPrevisto = (date) => {
    setInicioPrevisto(date);
  };

  const onConclusaoPrevista = (date) => {
    setConclusaoPrevista(date);
  };

  const onInicio = (date) => {
    setInicio(date);
  };

  const onConclusao = (date) => {
    setConclusao(date);
  };

  const updateHandler = (evt) => {
    evt.preventDefault();

    let data = {
      responsavel,
      cliente,
      problema,
      acao,
      origem,
      status,
      empresa,
      inicio_previsto: moment(inicio_previsto).format('DD/MM/yyyy HH:mm:ss'),
      conclusao_prevista: moment(conclusao_prevista).format(
        'DD/MM/yyyy HH:mm:ss'
      ),
      inicio: moment(inicio).format('DD/MM/yyyy HH:mm:ss'),
      conclusao: moment(conclusao).format('DD/MM/yyyy HH:mm:ss'),
      auditoria
    };
    editAcao(data, id);
  };

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <S.ContainerCardForm>
            <div className="card-form">
              <Card className="item">
                <CardContent>
                  <S.TituloTopo>Dados</S.TituloTopo>
                  <S.Column>
                    <FormControl>
                      <TextField
                        variant="outlined"
                        label={'Nome'}
                        value={acao}
                        placeholder="Nome"
                        onChange={(event) => setAcao(event.target.value)}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        variant="outlined"
                        label={'Descrição'}
                        value={problema}
                        placeholder="Descrição"
                        onChange={(event) => setProblema(event.target.value)}
                      />
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-required-label">
                        Status
                      </InputLabel>
                      <Select
                        variant="outlined"
                        disabled
                        value={status}
                        label={'Status'}
                        placeholder="Status"
                        defaultValue=""
                      >
                        <MenuItem key="1" value="">
                          <em>Selecione o status</em>
                        </MenuItem>
                        <MenuItem key="2" value="1">
                          <em>Pendente</em>
                        </MenuItem>
                        <MenuItem key="3" value="2">
                          <em>Aceita</em>
                        </MenuItem>
                        <MenuItem key="6" value="6">
                          <em>Não aceita</em>
                        </MenuItem>
                        <MenuItem key="4" value="3">
                          <em>Iniciada</em>
                        </MenuItem>
                        <MenuItem key="5" value="4">
                          <em>Concluída</em>
                        </MenuItem>
                        <MenuItem key="6" value="5">
                          <em>Cancelada</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </S.Column>
                </CardContent>
              </Card>
              <Card className="item">
                <CardContent>
                  <S.TituloTopo>Vínculos</S.TituloTopo>
                  <S.Column>
                    {usuarioPermissao === 1 ? (
                      <FormControl className="form_control" variant="outlined">
                        <InputLabel id="demo-simple-select-required-label">
                          Empresa
                        </InputLabel>
                        <Select
                          variant="outlined"
                          onChange={(event) => setEmpresa(event.target.value)}
                          value={empresa}
                          label={'Empresa'}
                          placeholder="Empresa"
                          disabled
                        >
                          <MenuItem value="">
                            <em>Selecione um empresa</em>
                          </MenuItem>
                          {empresaListaAtiva.length > 0
                            ? empresaListaAtiva.map((lista) => (
                                <MenuItem key={lista.uuid} value={lista.id}>
                                  {lista.nome}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    ) : null}
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-required-label">
                        Pasta
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={(event) => setCliente(event.target.value)}
                        value={cliente}
                        label={'Pasta'}
                        placeholder="Pasta"
                        defaultValue=""
                      >
                        <MenuItem key={'responsavel1'} value="">
                          <em>Selecione uma pasta</em>
                        </MenuItem>
                        {clienteAtivo && clienteAtivo.length > 0
                          ? clienteAtivo.map((lista) => (
                              <MenuItem key={lista.uuid} value={lista?.uuid}>
                                {lista.nome}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-required-label">
                        Responsável
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={(event) => setResponsavel(event.target.value)}
                        value={responsavel}
                        label={'Responsável'}
                        placeholder="Responsável"
                        defaultValue=""
                      >
                        <MenuItem key={'responsavel1'} value="">
                          <em>Selecione um responsável</em>
                        </MenuItem>
                        {usuarioLista && usuarioLista.length > 0
                          ? usuarioLista.map((lista) => (
                              <MenuItem key={lista.uuid} value={lista?.uuid}>
                                {lista.nome}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                    <FormControl className="form_control" variant="outlined">
                      <TextField
                        variant="outlined"
                        label={'Código da auditoria'}
                        value={auditoria}
                        placeholder="Codigo da auditoria"
                        onChange={(event) => setAuditoria(event.target.value)}
                      />
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-required-label">
                        Origem
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={(event) => setOrigem(event.target.value)}
                        value={origem}
                        label={'Origem'}
                        placeholder="Origem"
                        defaultValue=""
                        disabled={gestor === 1 ? true : false}
                      >
                        <MenuItem key="0" value="">
                          <em>Selecione uma origem</em>
                        </MenuItem>
                        {origemListaAtiva?.itens
                          ? origemListaAtiva?.itens.map((lista) => (
                              <MenuItem key={lista.id} value={lista?.id}>
                                {lista.titulo}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </S.Column>
                </CardContent>
              </Card>
              <Card className="item">
                <CardContent>
                  <S.TituloTopo>Datas</S.TituloTopo>
                  <S.Column>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <S.Row>
                        <FormControl className="data">
                          <KeyboardDateTimePicker
                            inputVariant="outlined"
                            ampm={false}
                            label="Início previsto"
                            placeholder="Início de previsto"
                            value={inicio_previsto}
                            onChange={(event) => onInicioPrevisto(event)}
                            disabled
                            minDate={minDate}
                            error={false}
                            helperText={null}
                            format="dd/MM/yyyy HH:mm"
                          />
                        </FormControl>
                      </S.Row>
                      <S.Row>
                        <FormControl className="data">
                          <KeyboardDateTimePicker
                            inputVariant="outlined"
                            ampm={false}
                            label="Conclusão prevista"
                            placeholder="Conclusão prevista"
                            value={conclusao_prevista}
                            onChange={(event) => onConclusaoPrevista(event)}
                            disabled
                            error={false}
                            helperText={null}
                            format="dd/MM/yyyy HH:mm"
                          />
                        </FormControl>
                      </S.Row>
                      <S.Row>
                        <FormControl className="data">
                          <KeyboardDateTimePicker
                            inputVariant="outlined"
                            ampm={false}
                            label="Início"
                            placeholder="Início"
                            value={inicio}
                            disabled
                            disablePast
                            error={false}
                            helperText={null}
                            format="dd/MM/yyyy HH:mm"
                          />
                        </FormControl>
                      </S.Row>
                      <S.Row>
                        <FormControl className="data">
                          <KeyboardDateTimePicker
                            inputVariant="outlined"
                            ampm={false}
                            label="Conclusão"
                            placeholder="Conclusão"
                            value={conclusao}
                            disabled
                            disablePast
                            error={false}
                            helperText={null}
                            format="dd/MM/yyyy HH:mm"
                          />
                        </FormControl>
                      </S.Row>
                    </MuiPickersUtilsProvider>
                  </S.Column>
                </CardContent>
              </Card>
            </div>
          </S.ContainerCardForm>
          <button className="primary" onClick={(evt) => updateHandler(evt)}>
            {isLoading ? 'Carregando...' : 'Atualizar'}
          </button>
        </>
      )}
    </S.Container>
  );
}
