
import React, {useState} from "react";
import {Box} from '@material-ui/core'
import { UsuarioTable } from "../../components/UsuarioTable/index";
import { VisualizarUsuarioModal } from "../../components/VisualizarUsuarioModal/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { UsuarioProvider } from "../../hooks/user";
import { EmpresaProvider } from "../../hooks/empresa";

export function Usuario() {
  const [abreModalVisualizar, setAbreModalVisualizar] = useState(false);
    
    function handleAbreModalVisualizar(){
        setAbreModalVisualizar(true);
    }

    function handleFechaVisualizarModal(){
        setAbreModalVisualizar(false);
    }


    
  return (
      <UsuarioProvider>
        <EmpresaProvider>
          <div style={{display: 'flex'}}>
              <Sidebar activePage={'2'} />
              <div style={{width: '100%'}}>
                <Topbar pageTitle={'Usuário'} />
                <Box className="container_admin">
                    <UsuarioTable  verUsuarioModal={handleAbreModalVisualizar}  />
                </Box>
              </div>
          </div>
          {abreModalVisualizar ? 
            <VisualizarUsuarioModal
                fecha={handleFechaVisualizarModal} 
                abre={abreModalVisualizar}
            /> 
            : null }
        </EmpresaProvider>
      </UsuarioProvider>
  );
};


