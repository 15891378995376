import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    align-items: flex-start;
    
    padding: 20px;

    .left{
        display: flex;
        flex-direction: row;
        align-items: center;
        .input {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @media screen and (max-width: 740px) {
            flex-direction: column;
        }
    }

    button {
        height: 56px;
        padding: 0 2rem;
        font-size: 1rem;
        color: #FFF;
        background-color: var(--info-color);
        border-color: var(--info-color);
        border-radius: 0.25rem;
        border: none;
        transition: filter 0.2s;
        cursor: pointer;
        margin-left: 35px;
        svg {
            display: none;
        }
        

        &:hover{
            filter: brightness(0.9)
        }
        &:focus{
            outline: none
        }

    }
    button.buttonRelatorio {
        background-color: var(--relatorio-color);
        margin-left: 10px;
        @media screen and (max-width: 740px) {
            width: 60px;
            height: 40px;
            padding: 0px;
            display: flex;
            align-items:center;
            justify-content: center;
            margin-top: 7px;
            margin-right: -10px;
            svg {
                display: block;
            }
            span {
                display: none;
            }
        }
    }
`

export const Divider = styled.div`
    padding: 10px;
`