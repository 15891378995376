import { useState } from 'react';
import * as S from "./styles";
import { useTarefa } from "../../hooks/tarefa";
import { useHistorico } from "../../hooks/historico";
import { useOrigem } from "../../hooks/origem";
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Paper, Accordion, AccordionDetails, AccordionSummary, AccordionActions, Typography, Divider} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Draggable from 'react-draggable';
import { Loading } from "../Loading/index";

import { HistoricoComponent } from "..//HistoricoComponent/index"
function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export function TarefaTable({verModalHistorico}) {
    let history = useHistory();
    const { tarefaLista, tarefaPage, isLoading, pagination, deletarTarefa } = useTarefa();
    const { visualizarHistoricoPorUUID } = useHistorico();
    const { origemLista } = useOrigem();

    const [openConfirm, setOpenConfirmarDeletar] = useState(false);
    const [nomeTarefa, setNomeTarefa] = useState('');
    const [uuidTarefa, setUuidTarefa] = useState('');
    const [recorrenteTarefa, setRecorrente] = useState('');
  
     const handleSelect = async (event, value) => {
        await tarefaPage(value);
    }
    
    const handleClickOpenEdit = async (uuid) => {
        history.push("/checklist/"+uuid);
    };

    const handleClickOpenChecklistConfirm = async (uuid) => {
        history.push("/checklist/questionario/"+uuid);
    };


    function onOpenNewUserModal() {
        history.push("/checklist/novo");
    }
  
 

    const handleClickOpenConfirm = async (nome, uuid, recorrente) => {
        setOpenConfirmarDeletar(true);
        setNomeTarefa(nome);
        setUuidTarefa(uuid);
        setRecorrente(recorrente);
    };

    const handleCloseConfirm = async () => {
        setOpenConfirmarDeletar(false);
        setNomeTarefa('');
        setUuidTarefa('');
        setRecorrente('');
    };
    

    const handleDeletaConfirm = async (uuid, recorrente) => {
        setOpenConfirmarDeletar(false);
    
       let result =  await deletarTarefa(uuid, recorrente);
        
        if(result.erro === false){
            setNomeTarefa('');
            setUuidTarefa('');
            setRecorrente('');

        }
    };

    /* ABRIR HISTÓRICO */
        const [abreModalHistorico, setAbreModalHistorico] = useState(false);


        function handleFechaHistoricotoModal(){
            setAbreModalHistorico(false);
        }


    const handleClickOpenHistoricoConfirm = async (uuid) => {
       let result = await visualizarHistoricoPorUUID(uuid);

       if(result.erro === false){
            verModalHistorico();
       }
       
    }
    
    

    const diaDaSemana = ["Domingo","Segunda-Feira", "Terça-Feira","Quarta-Feira", "Quinta-Feira", "Sexta-Feira","Sábado"];
    
    const frequenciaTarefa = {
        1: "Semanal",
        2: "Mensal"
    }
    

    function getKeyByValueId(object, value) { 
        return Object.keys(object).find(key =>  object[key].id === value);
    }

    return (
        <S.Container>
            <button type="button" onClick={onOpenNewUserModal}>
                Novo checklist
            </button>
            <div className="totalItens"><h1>Total de registros: {pagination[0]?.total}</h1></div>
            {isLoading ? 
                <Loading />
                : tarefaLista.map(lista =>  (
                    <Accordion key={lista.uuid}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        >
                        <S.Column>
                            <S.TituloGrande> <span>Pasta</span>: {lista?.cliente?.nome }  -
                                <S.NavUsuario to={'/cliente/'+lista?.cliente?.uuid}>
                                    ver cliente
                                </S.NavUsuario> 
                            </S.TituloGrande>
                            <S.TituloGrande> <span>Ação</span>: {lista.acao}</S.TituloGrande>
                            <S.TituloPequeno>Problema: {lista.problema}</S.TituloPequeno>
                            <S.TituloPequeno>
                                <div className="responsavel">
                                    <p>Responsável: {lista?.responsavel?.nome || '' }</p> -
                                    <S.NavUsuario to={'/usuario/'+lista?.responsavel?.uuid}>
                                        ver responsável
                                    </S.NavUsuario>
                                </div>
                            </S.TituloPequeno>
                        </S.Column>
                        </AccordionSummary>
                        <AccordionDetails>
                            <S.Column>
                                <Typography  variant="caption">
                                    Origem: {origemLista[getKeyByValueId(origemLista,lista.origem)]?.titulo || ''}
                                </Typography>
                                {lista.recorrente !== null ?
                                <>
                                    <Typography variant="caption">
                                        Frequência: {frequenciaTarefa[lista.recorrente.tipo]}
                                    </Typography>
                                    {lista.recorrente.tipo === 2 ?
                                    <Typography variant="caption">
                                        Dia: {lista.recorrente.dia}
                                    </Typography>
                                    :
                                    <Typography variant="caption">
                                        Dia da semana: {JSON.parse(lista.recorrente.dias_semana.replace(/"/g, '')).map(i =>  (
                                                
                                                (i ? ', ' : '') + diaDaSemana[i]
                                                ))}
                                    </Typography>
                                    }
                                </>
                                : null}
                                <Typography  variant="caption">
                                    Solicitante: {lista?.solicitante?.nome || ''} -
                                <S.NavUsuario to={'/usuario/'+lista?.solicitante?.uuid}>
                                    ver solicitante
                                </S.NavUsuario>
                                </Typography>
                                <Typography variant="caption">
                                    Início previsto: {lista.inicio_previsto.br} 
                                </Typography>
                                <Typography variant="caption">
                                    Conclusão prevista: {lista.conclusao_prevista.data}
                                </Typography>
                                <Typography variant="caption">
                                    Início: {lista.inicio.data} 
                                </Typography>
                                <Typography variant="caption">
                                    Conclusão: {lista.conclusao.data}
                                </Typography>
                                <Typography variant="caption">
                                    Data de criação: {lista.data_criacao.br}
                                </Typography>
                            </S.Column>
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <S.ButtonDeletar onClick={() => handleClickOpenConfirm(lista.acao, lista.uuid, lista?.recorrente?.uuid)}>
                                Deletar
                                </S.ButtonDeletar>
                            <S.ButttonEditar color="primary" onClick={() => handleClickOpenEdit(lista.uuid,pagination[0].pagina_atual)} >
                                Ver
                            </S.ButttonEditar>
                            <S.ButttonChecklist onClick={() => handleClickOpenChecklistConfirm(lista.uuid)}>
                                Questionários
                            </S.ButttonChecklist>
                            <S.ButttonHistorico onClick={() => handleClickOpenHistoricoConfirm(lista.uuid)}>
                                Históricos
                            </S.ButttonHistorico>
                        </AccordionActions>
                    </Accordion>
                ))}
            <S.ContainerPagination>
                {pagination.length ?
                <Pagination
                    showLastButton={true}
                    showFirstButton={true}
                    color="primary"
                    count={pagination[0].ultima_pagina}
                    page={Number(pagination[0].pagina_atual)}
                    onChange={handleSelect}
                />
                : null}
            </S.ContainerPagination>
            
            <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            className="modal_dialog"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Deletar
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Tem certeza que deseja deletar a tarefa {nomeTarefa || ''}, essa ação não poderá ser desfeita
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={() => handleCloseConfirm()} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => handleDeletaConfirm(uuidTarefa, recorrenteTarefa)} color="secondary">
                    Deletar
                </Button>
                </DialogActions>
            </Dialog> 
          {abreModalHistorico ? 
            <HistoricoComponent
                vinculo={uuidTarefa}
                tabela="tarefas"
                fecha={handleFechaHistoricotoModal} 
                abre={abreModalHistorico}
            /> 
            : null }
        </S.Container>
    );
}
