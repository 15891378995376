import styled from 'styled-components'

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
    @media (max-width: 768px) {
	    flex-direction: column;
    }
    .MuiFormControl-root{
        margin-bottom: 20px;
    }
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-right: 5px;
    .MuiFormControl-root{
        margin-bottom: 8px;
    }
`;

export const TituloTopo = styled.h1`
    width: 100%;
    color: var(--info-color);
    font-size: 1.2em;
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 5px;

`
export const Container = styled.form`
    min-height: 60vh;
    max-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .dia_semana {
        margin-top: 40px;
    }

    .titulo {
        width: 100%;
        h2 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #CCC;
        }
        .close  {
          position: absolute;
          right: 10px;
          top: 10px;
          color: #CCC;
        }
    }

    .grid_container {
        margin-top: 10;
        margin-bottom: 20;
        width: 100%;
        flex-grow: 1;

        .form_control {
            margin-bottom: 20px;
        }
    }

    .botao {
        background-color: #f5f5f5;
        color: white;
        margin: 10px;
        &.add {
            background-color: var(--info-color);
        }
        &.cancelar {
            background-color: #999;
            color: white;
        }
    }

    .container_acao {
        width: 100%;
        border-top: 1px solid #CCC;
        .buttonGroup{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
`

export const ButtonImage = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: solid 2px #CCC;

    img {
        object-fit: cover;
    }
`
