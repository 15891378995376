import * as S from "./styles";
import React, { useRef } from "react";
import { Dialog, DialogTitle, IconButton, DialogContent} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import ReactToPrint from "react-to-print";

export function ImagemModal({abre, fecha, link}) {
    let componentRef = useRef();

    return (
        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container>
                <DialogTitle className="titulo">
                    <IconButton className="fecha" onClick={fecha}>
                        <CloseIcon />
                        Fechar
                    </IconButton>
                </DialogTitle> 
                <DialogContent  className="grid_container">
                    {link ?
                        <S.Container>
                            <img src={link} alt="qrcode" ref={el => (componentRef.current = el)} />
                            <a
                                href={link}
                                download="qrcode"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Download
                            </a>
                         
                             <ReactToPrint
                                trigger={() => <button className="imprimir">Imprimir</button>}
                                content={() => componentRef.current}
                            />
                            
                        </S.Container>
                    : null}
                </DialogContent>
            </S.Container>
        </Dialog>
    );
}


