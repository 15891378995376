import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { toast } from 'react-toastify';

const OrigemContext = createContext({});

export const OrigemProvider = (props) => {
  const [origemLista, setOrigemLista] = useState([]);
  const [origemListaAtiva, setOrigemListaAtiva] = useState([]);
  const [origemPorUUID, setOrigemPorUUID] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [pagination, setPagination] = useState([] || '');
  const [activePage, setPageActive] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const handleOrigem = useCallback(async () => {
    const buscaAtiva = localStorage.getItem('salvarBuscaOrigem');

    if (buscaAtiva && buscaAtiva !== '') {
      let search = JSON.parse(buscaAtiva);
      const response = await api.get(
        '/origem?titulo=' +
          search.titulo +
          '&cpf=' +
          search.cpf.replace(/\D/g, '') +
          '&tipo=' +
          search.tipo +
          '&status=' +
          search.status
      );
      setIsLoading(true);

      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setPagination(data);
      setOrigemLista(response.data);
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const response = await api.get('/origem');
        const data = [
          {
            pagina_atual: response.data.pagina_atual,
            proxima_pagina: response.data.proxima_pagina,
            pagina_anterior: response.data.pagina_anterior,
            ultima_pagina: response.data.ultima_pagina,
            total: response.data.total
          }
        ];
        setPagination(data);
        setOrigemLista(response.data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/origem') {
      handleOrigem();
    }
  }, [handleOrigem]);

  const handleOrigemAtiva = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsLoading(false);
      const response = await api.get('/origem?status=1&quantidade=1000');

      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setPagination(data);
      setOrigemListaAtiva(response.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname !== '/origem') {
      handleOrigemAtiva();
    }
  }, [handleOrigemAtiva]);

  // Paginação
  async function origemPage(page) {
    try {
      setIsLoading(true);

      const response = await api.get('/origem?page=' + page);

      setOrigemLista(response.data);
      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setIsLoading(false);
      setPagination(data);
    } catch (err) {
      setIsLoading(false);
    }
  }

  // USUÁRIOS POR UUID

  async function getOrigemPorUuid(uuid, page) {
    try {
      setIsLoadingEdit(true);
      const responseedit = await api.get('/origem/' + uuid);

      setOrigemPorUUID([responseedit.data]);
      setPageActive(page);
      setIsLoadingEdit(false);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingEdit(false);
    }
  }

  // Criar OS USUÁRIOS
  async function criaOrigem(UsuarioInputEdit, modal) {
    try {
      setIsLoading(true);

      const response = await api.post('/origem', UsuarioInputEdit);

      let keysToRemove = ['buscaAtivaOrigem', 'salvarBuscaOrigem'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));

      setIsLoading(false);
      handleOrigem();
      modal();

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: false
      };
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: true
      };
    }
  }

  // EDITANDO OS USUÁRIOS
  async function editaOrigem(UsuarioInputEdit, uuid, fecha) {
    try {
      setIsLoadingEdit(true);
      const response = await api.put('/origem/' + uuid, UsuarioInputEdit);

      const responsepage = await api.get('/origem');

      setOrigemLista(responsepage.data);
      const datapage = [
        {
          quantidade_pagina: responsepage.data.quantidade_pagina,
          pagina_atual: responsepage.data.pagina_atual,
          proxima_pagina: responsepage.data.proxima_pagina,
          pagina_anterior: responsepage.data.pagina_anterior,
          ultima_pagina: responsepage.data.ultima_pagina,
          total: responsepage.data.total
        }
      ];
      setPagination(datapage);
      fecha();

      setIsLoadingEdit(false);
      toast.success(response?.data?.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (err) {
      toast.error(err.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingEdit(false);
    }
  }

  // Pesquisar Catálogo

  async function searchOrigem(search, Modal) {
    try {
      setIsLoadingSearch(true);

      if (
        search.titulo === '' &&
        search.cpf === '' &&
        search.tipo === '' &&
        search.status === ''
      ) {
        handleOrigem();
      } else {
        const response = await api.get(
          '/origem?titulo=' +
            search.titulo +
            '&cpf=' +
            search.cpf.replace(/\D/g, '') +
            '&tipo=' +
            search.tipo +
            '&status=' +
            search.status
        );

        if (response.data.error === true) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
        } else {
          localStorage.setItem('buscaAtivaOrigem', 'ativa');
          localStorage.setItem('salvarBuscaOrigem', JSON.stringify(search));

          setOrigemLista(response.data);
          const data = [
            {
              quantidade_pagina: response.data.quantidade_pagina,
              pagina_atual: response.data.pagina_atual,
              proxima_pagina: response.data.proxima_pagina,
              pagina_anterior: response.data.pagina_anterior,
              ultima_pagina: response.data.ultima_pagina,
              total: response.data.total
            }
          ];
          setPagination(data);
        }
      }

      setIsLoadingSearch(false);
      Modal();
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }
  async function limpaUser() {
    try {
      setIsLoadingSearch(true);

      handleOrigem();
      let keysToRemove = ['buscaAtivaOrigem', 'salvarBuscaOrigem'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));
      handleOrigem();

      setIsLoadingSearch(false);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  async function deletarOrigem(uuid) {
    try {
      setIsLoading(true);
      let response = await api.delete('/origem/' + uuid);

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      handleOrigem();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);

      toast.success(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      handleOrigem();

      handleOrigem();
      setIsLoading(false);
    }
  }

  return (
    <OrigemContext.Provider
      value={{
        isLoading,
        origemLista,
        getOrigemPorUuid,
        origemPorUUID,
        editaOrigem,
        criaOrigem,
        origemPage,
        pagination,
        activePage,
        isLoadingEdit,
        searchOrigem,
        limpaUser,
        isLoadingSearch,
        deletarOrigem,
        origemListaAtiva
      }}
    >
      {props.children}
    </OrigemContext.Provider>
  );
};

export function useOrigem() {
  const context = useContext(OrigemContext);

  return context;
}
