import api from "../services/api";
import {createContext, useState, useContext, } from 'react';
import { toast } from 'react-toastify';

const RespostaContext = createContext({});

export const RespostaProvider = (props) =>  {
    const [respostaPorUUID, setRespostaPorUUID] = useState([]);
    const [isLoading, setIsLoadingEdit] = useState(false);
    const [isLoadingEditID, setIsLoadingEditID] = useState(false);
    const [VerRespostaPorUUID, setVerRespostaPorUUID] = useState([]);


    // USUÁRIOS POR UUID

    async function getRespostaPorUuid(uuid,page) {
        try {
            setIsLoadingEdit(true);
            const responseedit = await api.get('/resposta/'+uuid); 

            setRespostaPorUUID([responseedit.data]);
            setIsLoadingEdit(false);
            setTimeout(() => {
                window.location.pathname = '/resposta/'+uuid;
            }, 1000);
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false);
        }
    }


    
    async function VisualizarRespostaPorUUID(uuid) {
        try {
            setIsLoadingEditID(true);
            const responseedit = await api.get('/resposta?tarefa='+uuid); 
            setVerRespostaPorUUID(responseedit.data.itens);

            setIsLoadingEditID(false);
            
            return {
                erro: false
            }
        } catch (err) {
            setIsLoadingEditID(true);
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingEditID(false);
            return {
                erro: true,
            }
        }
    }

    async function sendPdfRespostas(uuid) {
        try {
            setIsLoadingEdit(true);
            await api.post('/resposta/sendPdf/'+uuid);
            setIsLoadingEdit(false);   
            toast.success('Email enviado com sucesso!', {
                position: toast.POSITION.TOP_CENTER
              });       
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false);
        }
    }
    

    

    return (
        <RespostaContext.Provider value={{isLoading, getRespostaPorUuid, respostaPorUUID, VisualizarRespostaPorUUID, isLoadingEditID, VerRespostaPorUUID, sendPdfRespostas}}>  
            {props.children}
        </RespostaContext.Provider>
    )

}



export function useResposta() {
    const context = useContext(RespostaContext);

    return context;
}
