import api from "../services/api";
import {createContext, useState, useContext, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

const UsuarioContext = createContext({});

export const UsuarioProvider = (props) =>  {
    const [usuarioLista, setUsuarioLista] = useState([]);
    const [usuarioPorUUID, setUsuarioPorUUID] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [pagination, setPagination] = useState([] || '');
    const [activePage, setPageActive] = useState('');
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [VerUsuarioPorUUID, setVerUsuarioPorUUID] = useState([]);


    const handleUsuario = useCallback(async () => {
        
        const buscaAtiva = localStorage.getItem('salvarBuscaUsuario');

        if(buscaAtiva && buscaAtiva !== ''){
            let search = JSON.parse(buscaAtiva);
            const response = await api.get('/usuario?nome='+search.nome+'&status='+search.status); 
            setIsLoading(true);
            
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setPagination(data);
            setUsuarioLista(response.data.itens);
            setIsLoading(false); 
        }else{
            setIsLoading(true);
            const response = await api.get('/usuario');
            
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setPagination(data);
            setUsuarioLista(response.data.itens);
            setIsLoading(false);
        }
      }, []);

    useEffect(() => {
        handleUsuario();
      }, [handleUsuario]);

         
    // Paginação
    async function userPage(page) {
        try {
            setIsLoading(true);
            
            const response = await api.get('/usuario?page='+page);             

            setUsuarioLista(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setIsLoading(false);
            setPagination(data);

        } catch (err) {
            setIsLoading(false); 
        }
    }

    // USUÁRIOS POR UUID

    async function getUsuarioPorUuid(uuid,page) {
        try {
            setIsLoadingEdit(true);
            const responseedit = await api.get('/usuario/'+uuid); 

            setUsuarioPorUUID([responseedit.data]);
            setPageActive(page);
            setIsLoadingEdit(false);
            setTimeout(() => {
                window.location.pathname = '/usuario/'+uuid;
            }, 1000);
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false);
        }
    }

    
    async function VisualizarUsuarioPorUUID(uuid) {
        try {
            setIsLoading(true);
            const responseedit = await api.get('/usuario/'+uuid); 

            let nome = responseedit?.data.nome;
            setVerUsuarioPorUUID([responseedit.data, nome]);

            setIsLoading(false);
            return {
                erro: false
            }

        } catch (err) {
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoading(false);
            return {
                erro: true,
            }
        }
    }
    
    // Criar OS USUÁRIOS
    async function createUsuario(UsuarioInputEdit) {
        try {
            setIsLoading(true);

            const response = await api.post('/usuario', UsuarioInputEdit); 
            
            let keysToRemove = ["buscaAtivaUsuario", "salvarBuscaUsuario"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));

            setIsLoading(false);
            handleUsuario();

            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            return {
                erro: false
            };
        } catch (err) {
            setIsLoading(false); 
             toast.error(err.response.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
            return {
                erro: true
            };
        }
    } 

    
    // EDITANDO OS USUÁRIOS
    async function editUsuario(UsuarioInputEdit, uuid) {
        try {

            setIsLoadingEdit(true);

            const response = await api.put('/usuario/'+uuid, UsuarioInputEdit); 

            const responsepage = await api.get('/usuario');    
            setUsuarioLista(responsepage.data.itens);
            const datapage = [{
                    'quantidade_pagina' : responsepage.data.quantidade_pagina,
                    'pagina_atual' : responsepage.data.pagina_atual,
                    'proxima_pagina' : responsepage.data.proxima_pagina,
                    'pagina_anterior' : responsepage.data.pagina_anterior,
                    'ultima_pagina' : responsepage.data.ultima_pagina,
                    'total' : responsepage.data.total
                }
            ];
            setPagination(datapage);

            setIsLoadingEdit(false);
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            return {
                erro: false,
                lista: response.data.dados
            };
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false); 
            return {
                erro: true
            };
        }
    } 

    
    // Pesquisar Catálogo
    
    async function searchUsuario(search, Modal) {
        try {
            setIsLoadingSearch(true);
            
            if(search.nome === '' && search.status === ''){
                handleUsuario();
            } else {
                 const response = await api.get('/usuario?nome='+search.nome+'&status='+search.status); 

                 if(response.data.error === true ){
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                 }else {
                    localStorage.setItem('buscaAtivaUsuario', 'ativa');
                    localStorage.setItem('salvarBuscaUsuario', JSON.stringify(search));
                    
                    setUsuarioLista(response.data.itens);
                    const data = [{
                        'quantidade_pagina' : response.data.quantidade_pagina,
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                        }
                    ];
                    setPagination(data);
                 }
            }
            
            setIsLoadingSearch(false);
            Modal();
            

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }
    async function limpaUser() {
        try {
            setIsLoadingSearch(true);
            
            handleUsuario();
            let keysToRemove = ["buscaAtivaUsuario", "salvarBuscaUsuario"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }
 

    return (
        <UsuarioContext.Provider value={{isLoading, usuarioLista, getUsuarioPorUuid, usuarioPorUUID, editUsuario, createUsuario, userPage, pagination, activePage, isLoadingEdit, searchUsuario, limpaUser, isLoadingSearch, VerUsuarioPorUUID, VisualizarUsuarioPorUUID}}>  
            {props.children}
        </UsuarioContext.Provider>
    )

}



export function useUsuario() {
    const context = useContext(UsuarioContext);

    return context;
}
