import * as S from "./styles";
import { useState } from 'react';
import { useEmpresa } from "../../hooks/empresa"
import CloseIcon from "@material-ui/icons/Close";
import { TextField, FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle, DialogActions, ButtonGroup, Button, IconButton, Grid } from "@material-ui/core";


export function CriaNovoEmpresaModal({ abre, fecha }) {
    const { criaEmpresa } = useEmpresa();

    const [nome, setNome] = useState("");
    const [status, setStatus] = useState("");

    const handleCria = async event => {
        event.preventDefault();
        
        let data = {
            nome,
            status
        }

        await criaEmpresa(data, fecha);
 
    }  


    return (

        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container onSubmit={handleCria}>
            <DialogTitle className="titulo">
                Cadastre Empresas
                <IconButton className="fecha" onClick={fecha}>
                <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent  className="grid_container">
                <Grid container>
                    <Grid item xs={12}>
                        <S.Column>
                            <FormControl className="form_control">
                                <TextField
                                variant="outlined"
                                label={'Nome'}
                                value={nome}
                                placeholder="Nome"
                                onChange={event => setNome(event.target.value)}
                                />
                            </FormControl>
                            
                            <FormControl className="form_control" variant="outlined" >
                                <InputLabel id="demo-simple-select-required-label">Status</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={event => setStatus(event.target.value)}
                                    value={status}
                                    label={'Status'}
                                    placeholder="Status"
                                >
                                    <MenuItem value="">
                                        <em>Selecione um status</em>
                                    </MenuItem>
                                    <MenuItem value={1}>Ativo</MenuItem>
                                    <MenuItem value={2}>Inativo</MenuItem>
                                </Select>
                            </FormControl>

                        </S.Column>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions className="container_acao">
                <ButtonGroup
                orientation="horizontal"
                color="primary"
                className="buttonGroup"
                aria-label="vertical outlined primary button group"
                >
                <Button
                    className="botao add"
                    variant="contained"
                    type="submit"
                >
                    Cadastrar
                </Button>
                </ButtonGroup>
            </DialogActions>
            </S.Container>
        </Dialog>
    )
}