
import React, {useState} from "react";
import {Box} from '@material-ui/core'
import { CheckListTarefaTable } from "../../components/CheckListTarefaTable/index";

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { CriaNovoCheckListTarefaModal } from "../../components/CriaNovoCheckListTarefaModal/index";
import { TarefaProvider } from "../../hooks/tarefa";
import { CheckListProvider } from "../../hooks/checklist";

export function CheckListTarefa() {
    
  const [abreModalAdd, setAbreModalAdd] = useState(false);
  
  function handleAbreModalAdd(){
      setAbreModalAdd(true);
  }

  function handleFechaAddtoModal(){
    setAbreModalAdd(false);
  }
    
    
  return (
      <TarefaProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'4'} />
            <div style={{width: '100%'}}>
              <Topbar pageTitle={'Questionários - Checklist'} />
              <Box className="container_admin">
                  <CheckListTarefaTable onOpenAddModal={handleAbreModalAdd}  />
              </Box>
            </div>
            <CheckListProvider>
              {abreModalAdd ? <CriaNovoCheckListTarefaModal
                abre={abreModalAdd}
                fecha={handleFechaAddtoModal}
              /> : null}  
            </CheckListProvider>
        </div>
      </TarefaProvider>
  );

};


