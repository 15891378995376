import React from 'react'
import {Box} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

export default function ErrorMessage({message}) {
    return (
        <Box my={4}>
            <Alert severity="error">{message}</Alert>
        </Box>
    )
}