import React, {useState, useCallback} from 'react';
import * as S from "./styles";
import { TextField, FormControl,  Dialog, DialogContent, DialogTitle, DialogActions, ButtonGroup, Button,  Grid } from "@material-ui/core";
import { useHistorico } from "../../hooks/historico"
import { useAuditoria } from "../../hooks/auditoria"
import { usePasta } from "../../hooks/pasta"

export function HistoricoComponent({vinculo, tabela, fecha, abre, acao = 'add', textoEdit, uuid = '', btnFechar = false, refresh = false}){
    const { criaHistorico, criaHistoricoAcao, visualizarHistoricoPorUUID} = useHistorico();
    const { visualizarHistoricoPorCliente } = usePasta();
    const { VisualizarRespostaPorUUID } = useAuditoria();
    const [texto, setTexto] = useState(textoEdit || '');

    const handleTarefa = useCallback(async () => {
        await VisualizarRespostaPorUUID(uuid);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleCliente = useCallback(async () => {
        await visualizarHistoricoPorCliente(vinculo);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleTarefas = useCallback(async () => {
        await visualizarHistoricoPorUUID(vinculo);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);


    const handleCadastraHistorico = async event => {
        event.preventDefault();
        let data = {
            vinculo,
            tabela,
            texto,
            status: 1
        }

        if(tabela === 'respostas'){
          let result =  await criaHistoricoAcao(data, fecha, acao);
            if(result.erro === false && result.tabela === 'respostas'){
                handleTarefa();
            }
        } else if(tabela === 'clientes'){
          let result =  await criaHistorico(data, fecha);
            if(result.erro === false && result.tabela === 'clientes'){
                handleCliente();
            }
        }else if(tabela === 'tarefas'){
          let result =  await criaHistorico(data, fecha);
            if(result.erro === false && result.tabela === 'tarefas'){
                handleTarefas();
            }
        }else if(tabela !== 'clientes' && tabela !== 'respostas'  && tabela !== 'tarefas'){
           await criaHistorico(data, fecha);
        }

        if(refresh){
            refresh();
        }

    }



    return (

        <Dialog open={abre} close={fecha} fullWidth  maxWidth="xs">
            <S.Container onSubmit={handleCadastraHistorico}>
                <DialogTitle className="titulo">
                    Salvar histórico
                </DialogTitle> 

                <DialogContent  className="grid_container">
                    <Grid container>
                        <Grid item xs={12}>
                            <S.Column>
                                <FormControl className="form_control">
                                    <TextField
                                    variant="outlined"
                                    label={'Digite um texto'}
                                    value={texto}
                                    placeholder="Digite um texto"
                                    multiline
                                    rows={10}
                                    onChange={event => setTexto(event.target.value)}
                                    />
                                </FormControl>
                            </S.Column>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className="container_acao">
                    <ButtonGroup
                    orientation="horizontal"
                    color="primary"
                    className="buttonGroup"
                    aria-label="vertical outlined primary button group"
                    >
                        <Button
                            className="botao edit"
                            variant="contained"
                            type="submit"
                        >
                            Salvar
                        </Button>
                        {btnFechar && <Button 
                            className="botao close"
                            color="secondary"
                            variant="contained" 
                            onClick={fecha}>
                            Fechar
                        </Button>
                        }
                        
                    </ButtonGroup>
                </DialogActions>
            </S.Container>
        </Dialog>
    )


}