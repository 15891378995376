import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { toast } from 'react-toastify';

import moment from 'moment';

import 'moment/locale/pt-br';

moment.locale('pt-br');

const CheckListContext = createContext({});

export const CheckListProvider = (props) => {
  const [checklistListaAtiva, setCheckListListaAtiva] = useState([]);
  const [checklistLista, setCheckListLista] = useState([]);
  const [checklistPorUUID, setCheckListPorUUID] = useState([]);
  const [checklistItem, setCheckListItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [pagination, setPagination] = useState([] || '');
  const [paginationItem, setPaginationItem] = useState([] || '');
  const [activePage, setPageActive] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [VerCheckListPorUUID, setVerCheckListPorUUID] = useState([]);

  const handleCheckList = useCallback(async () => {
    const buscaAtiva = localStorage.getItem('salvarBuscaCheckList');

    if (buscaAtiva && buscaAtiva !== '') {
      const response = await api.get(
        '/checklist' + buscaAtiva
      );
      setIsLoading(true);

      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setPagination(data);
      setCheckListLista(response.data.itens);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      const response = await api.get('/checklist');

      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setPagination(data);
      setCheckListLista(response.data.itens);
    }
  }, []);

  useEffect(() => {
    handleCheckList();
  }, [handleCheckList]);

  const handleCheckListAtiva = useCallback(async () => {
    try {
      setIsLoading(false);
      const response = await api.get('/checklist?status=1&quantidade=1000');

      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setPagination(data);
      setCheckListListaAtiva(response.data.itens);
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleCheckListAtiva();
  }, [handleCheckListAtiva]);

  // Paginação
  async function checklistPage(page) {
    try {
      setIsLoading(true);

      const response = await api.get('/checklist?page=' + page);

      setCheckListLista(response.data.itens);
      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setIsLoading(false);
      setPagination(data);
    } catch (err) {
      setIsLoading(false);
    }
  }

  //  POR UUID

  async function getCheckListItemPorUuid(uuid) {
    try {
      setIsLoadingEdit(true);
      const responseedit = await api.get('/grupo/' + uuid);
      const data = [
        {
          pagina_atual: responseedit.data.pagina_atual,
          proxima_pagina: responseedit.data.proxima_pagina,
          pagina_anterior: responseedit.data.pagina_anterior,
          ultima_pagina: responseedit.data.ultima_pagina,
          total: responseedit.data.total
        }
      ];
      setPaginationItem(data);
      setCheckListItem(responseedit.data.perguntas);
      setIsLoadingEdit(false);
    } catch (err) {
      setIsLoadingEdit(false);
    }
  }

  //  POR UUID

  async function getCheckListPorUuid(uuid, page) {
    try {
      setIsLoadingEdit(true);
      const responseedit = await api.get('/checklist/' + uuid);

      setCheckListPorUUID([responseedit.data]);
      setPageActive(page);
      setIsLoadingEdit(false);
      setTimeout(() => {
        window.location.pathname = '/questionario/' + uuid;
      }, 1000);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingEdit(false);
    }
  }

  async function VisualizarCheckListPorUUID(uuid) {
    try {
      setIsLoading(true);
      const responseedit = await api.get('/checklist/' + uuid);

      let titulo = responseedit?.data.titulo;
      setVerCheckListPorUUID([responseedit.data, titulo]);

      setIsLoading(false);
      return {
        erro: false
      };
    } catch (err) {
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoading(false);
      return {
        erro: true
      };
    }
  }

  // Criar
  async function criaCheckList(UsuarioInputEdit, modal) {
    try {
      setIsLoading(true);

      const response = await api.post('/checklist', UsuarioInputEdit);

      let keysToRemove = ['buscaAtivaCheckList', 'salvarBuscaCheckList'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));

      setIsLoading(false);
      handleCheckList();
      modal();

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: false
      };
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: true
      };
    }
  }

  // EDITANDO
  async function editChecklist(UsuarioInputEdit, uuid) {
    try {
      setIsLoadingEdit(true);

      const response = await api.put('/checklist/' + uuid, UsuarioInputEdit);

      const responsepage = await api.get('/checklist');
      setCheckListLista(responsepage.data.itens);
      const datapage = [
        {
          quantidade_pagina: responsepage.data.quantidade_pagina,
          pagina_atual: responsepage.data.pagina_atual,
          proxima_pagina: responsepage.data.proxima_pagina,
          pagina_anterior: responsepage.data.pagina_anterior,
          ultima_pagina: responsepage.data.ultima_pagina,
          total: responsepage.data.total
        }
      ];
      setPagination(datapage);

      setIsLoadingEdit(false);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingEdit(false);
    }
  }

  // Pesquisar

  async function searchCheckList(search, Modal) {
    try {
      setIsLoadingSearch(true);
        const response = await api.get(
          '/checklist?titulo=' + search.titulo + '&status=' + search.status
        );

      if (response.data.error === true) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      } else {
        localStorage.setItem('buscaAtivaCheckList', 'ativa');
        localStorage.setItem('salvarBuscaCheckList', '?titulo=' + search.titulo + '&status=' + search.status);

        setCheckListLista(response.data.itens);
        const data = [
          {
            quantidade_pagina: response?.data?.quantidade_pagina,
            pagina_atual: response?.data?.pagina_atual,
            proxima_pagina: response?.data?.proxima_pagina,
            pagina_anterior: response?.data?.pagina_anterior,
            ultima_pagina: response?.data?.ultima_pagina,
            total: response?.data?.total
          }
        ];
        setPagination(data);
      }

      setIsLoadingSearch(false);
      Modal();
    } catch (err) {
      toast.error(err.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  async function limpaChecklist() {
    try {
      setIsLoadingSearch(true);

      handleCheckList();
      let keysToRemove = ['buscaAtivaCheckList', 'salvarBuscaCheckList'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));
      window.location.reload();

      setIsLoadingSearch(false);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  async function deletarCheckList(uuid) {
    try {
      setIsLoading(true);
      let response = await api.delete('/checklist/' + uuid);

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      window.location.reload();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);

      toast.success(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      handleCheckList();

      window.location.reload();
      setIsLoading(false);
    }
  }

  // ITENS

  // Criar
  async function criaItemCheckList(UsuarioInputEdit, modal) {
    try {
      setIsLoading(true);

      const response = await api.post('/item', UsuarioInputEdit);

      let keysToRemove = [
        'buscaAtivaItemCheckList',
        'salvarBuscaItemCheckList'
      ];

      keysToRemove.forEach((k) => localStorage.removeItem(k));

      setIsLoading(false);
      modal();

      const responseedit = await api.get('/grupo/' + UsuarioInputEdit.grupo);

      const data = [
        {
          pagina_atual: responseedit.data.pagina_atual,
          proxima_pagina: responseedit.data.proxima_pagina,
          pagina_anterior: responseedit.data.pagina_anterior,
          ultima_pagina: responseedit.data.ultima_pagina,
          total: responseedit.data.total
        }
      ];
      setPaginationItem(data);

      setCheckListItem(responseedit.data.perguntas);

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: false
      };
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: true
      };
    }
  }

  async function deletarItemCheckList(uuid, checklist) {
    try {
      setIsLoading(true);
      let response = await api.delete('/item/' + uuid);

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      const responseedit = await api.get('/grupo/' + checklist);

      const data = [
        {
          pagina_atual: responseedit.data.pagina_atual,
          proxima_pagina: responseedit.data.proxima_pagina,
          pagina_anterior: responseedit.data.pagina_anterior,
          ultima_pagina: responseedit.data.ultima_pagina,
          total: responseedit.data.total
        }
      ];
      setPaginationItem(data);

      setCheckListItem(responseedit.data.perguntas);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);

      const responseedit = api.get('/grupo/' + checklist);
      if (responseedit.data !== undefined) {
        const data = [
          {
            pagina_atual: responseedit.data.pagina_atual,
            proxima_pagina: responseedit.data.proxima_pagina,
            pagina_anterior: responseedit.data.pagina_anterior,
            ultima_pagina: responseedit.data.ultima_pagina,
            total: responseedit.data.total
          }
        ];
        setPaginationItem(data);
        setCheckListItem(responseedit.data.perguntas || '');
      } else {
        setPaginationItem('');
        setCheckListItem('');
      }

      setIsLoading(false);
    }
  }

  async function editItemCheckList(UsuarioInputEdit, uuid, modal, grupo) {
    try {
      setIsLoadingEdit(true);

      const response = await api.put('/item/' + uuid, UsuarioInputEdit);

      const responsepage = await api.get('/grupo/' + grupo);
      setCheckListItem(responsepage.data.perguntas);
      const datapage = [
        {
          quantidade_pagina: responsepage.data.quantidade_pagina,
          pagina_atual: responsepage.data.pagina_atual,
          proxima_pagina: responsepage.data.proxima_pagina,
          pagina_anterior: responsepage.data.pagina_anterior,
          ultima_pagina: responsepage.data.ultima_pagina,
          total: responsepage.data.total
        }
      ];
      setPagination(datapage);
      modal();

      setIsLoadingEdit(false);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingEdit(false);
    }
  }

  // Paginação
  async function checklistItemPage(uuidGrupo, page) {
    try {
      setIsLoading(true);

      const response = await api.get('/grupo/' + uuidGrupo + '?page=' + page);

      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setPaginationItem(data);
      setCheckListItem(response.data.perguntas);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  async function downloadRelatorioChecklist() {
    try {
        setIsLoading(true);
        const busca = localStorage.getItem('salvarBuscaCheckList');

        let urlRelatorio = busca ? busca : '';

        let response = await api.get('/checklist/relatorio' + urlRelatorio, {responseType: 'blob'});   
        var date = new Date();
        var formattedDate = moment(date).format('YYYYMMDD');
        let filename = 'relatorio_questionarios_'+formattedDate;
    
        const url = window.URL.createObjectURL(new Blob([response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        setIsLoading(false);      

    } catch (err) {
        setIsLoading(true);   
        toast.error('Erro!', {
            position: toast.POSITION.TOP_CENTER
        });
        setIsLoading(false); 
    }
  }

  return (
    <CheckListContext.Provider
      value={{
        isLoading,
        checklistLista,
        getCheckListPorUuid,
        checklistPorUUID,
        editChecklist,
        criaCheckList,
        checklistPage,
        pagination,
        activePage,
        isLoadingEdit,
        searchCheckList,
        limpaChecklist,
        isLoadingSearch,
        VerCheckListPorUUID,
        VisualizarCheckListPorUUID,
        deletarCheckList,
        getCheckListItemPorUuid,
        checklistItem,
        paginationItem,
        criaItemCheckList,
        deletarItemCheckList,
        editItemCheckList,
        checklistItemPage,
        checklistListaAtiva,
        downloadRelatorioChecklist
      }}
    >
      {props.children}
    </CheckListContext.Provider>
  );
};

export function useCheckList() {
  const context = useContext(CheckListContext);

  return context;
}
