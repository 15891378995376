
import React, {useState} from "react";
import {Box} from '@material-ui/core'
import { OrigemTable } from "../../components/OrigemTable/index";

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { OrigemProvider } from "../../hooks/origem";
import { EmpresaProvider } from "../../hooks/empresa";
import { CriaNovaOrigemModal } from "../../components/CriaNovaOrigemModal/index";
import { EditaOrigemModal } from "../../components/EditaOrigemModal/index";
import { VisualizaOrigemModal } from "../../components/VisualizaOrigemModal/index";

export function Origem() {
    
  const [abreModalAdd, setAbreModalAdd] = useState(false);
  const [abreModalEdita, setAbreModalEdita] = useState(false);

  function handleAbreModalAdd(){
      setAbreModalAdd(true);
  }

  function handleFechaAddtoModal(){
    setAbreModalAdd(false);
  }
    function handleAbreModalEdita(){
      setAbreModalEdita(true);
  }

  function handleFechaEditaModal(){
    setAbreModalEdita(false);
  }

  const [abreModalVisualizar, setAbreModalVisualizar] = useState(false);
  
  function handleAbreModalVisualizar(){
      setAbreModalVisualizar(true);
  }

  function handleFechaVisualizarModal(){
      setAbreModalVisualizar(false);
  }


  return (
      <OrigemProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'7'} />
            <div style={{width: '100%'}}>
              <Topbar pageTitle={'Origens'} />
              <Box className="container_admin">
                  <OrigemTable onOpenAddModal={handleAbreModalAdd} onOpenEditaModal={handleAbreModalEdita}  abreVisualizar={handleAbreModalVisualizar}  />
              </Box>
            </div>
            <EmpresaProvider>
            {abreModalAdd ? <CriaNovaOrigemModal
              abre={abreModalAdd}
              fecha={handleFechaAddtoModal}
            /> : null}
            {abreModalEdita ? <EditaOrigemModal
              abre={abreModalEdita}
              fecha={handleFechaEditaModal}
            /> : null}
            {abreModalVisualizar ? <VisualizaOrigemModal
              abre={abreModalVisualizar}
              fecha={handleFechaVisualizarModal}
              onOpenEditaModal={handleAbreModalEdita}  
            /> : null}
            </EmpresaProvider>
        </div>
      </OrigemProvider>
  );
};


