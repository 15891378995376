import * as S from "./styles";
import { useState }  from 'react';
import { useTarefa } from "../../hooks/tarefa";
import CloseIcon from "@material-ui/icons/Close";
import InputMask from "react-input-mask";
import { TextField, FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle, DialogActions, ButtonGroup, Button, IconButton, Grid } from "@material-ui/core";


export function DownloadRelatorioModal({ abre, fecha, tabela }) {
    const { downloadRelatorioTarefa } = useTarefa();

    const [inicio, setInicio] = useState("");
    const [conclusao, setConclusao] = useState("");
    const [status, setStatus] = useState(0);

    const handlePesquisa = async event => {
        event.preventDefault();
        
        let data = {
            inicio,
            conclusao,
            status
        }

        await downloadRelatorioTarefa(data, fecha);

    }
 
    return (

        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container onSubmit={handlePesquisa}>
                <DialogTitle className="titulo">
                    Relatório
                    <IconButton className="fecha" onClick={fecha}>
                    <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent  className="grid_container">
                    <Grid container>
                        <Grid item xs={12}>
                            <S.Column>
                                <FormControl>
                                    <InputMask
                                    type="text"
                                    mask="99/99/9999"
                                    placeholder="Data de inicio"
                                    onChange={event => setInicio(event.target.value)}
                                    value={inicio}
                                    maskChar=" "
                                    >
                                    {() => <TextField variant="outlined" label={'Data de inicio'} />}
                                    </InputMask>
                                </FormControl>
                                <FormControl>
                                    <InputMask
                                    type="text"
                                    mask="99/99/9999"
                                    placeholder="Data de conclusão"
                                    onChange={event => setConclusao(event.target.value)}
                                    value={conclusao}
                                    maskChar=" "
                                    >
                                    {() => <TextField variant="outlined" label={'Data de conclusão'} />}
                                    </InputMask>
                                </FormControl>
                                <FormControl className="form_control" variant="outlined" >
                                    <InputLabel id="demo-simple-select-required-label">Status</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={event => setStatus(event.target.value)}
                                        value={status}
                                        label={'Status'}
                                        placeholder="Status"
                                    >
                                        <MenuItem value="">
                                            <em>Selecione um status</em>
                                        </MenuItem>
                                        <MenuItem value={0}>Todos</MenuItem>
                                        <MenuItem value={1}>Pendente</MenuItem>
                                        <MenuItem value={2}>Concluída</MenuItem>
                                        <MenuItem value={3}>Atrasada</MenuItem>
                                    </Select>
                                </FormControl>
                            </S.Column>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className="container_acao">
                    <ButtonGroup
                    orientation="horizontal"
                    color="primary"
                    className="buttonGroup"
                    aria-label="vertical outlined primary button group"
                    >
                    <Button
                        className="botao edit"
                        variant="contained"
                        type="submit"
                    >
                        Download
                    </Button>
                    </ButtonGroup>
                </DialogActions>
            </S.Container>
        </Dialog>
    )
}
