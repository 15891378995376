import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';
import { AssignmentInd, Assignment } from '@material-ui/icons';

const permissao = JSON.parse(localStorage.getItem('PERMISSAO_USER_EPROWORK')) ?? [];
const master = Number(localStorage.getItem('MMPROWORK'));

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    visible: permissao.includes('per_dashboard_visualizar'),
    icon: <AiIcons.AiOutlineDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  },
  {
    title: 'Usuários',
    path: '/usuario',
    visible: permissao.includes('per_usuario_visualizar'),
    icon: <RiIcons.RiUserFill />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  },
  {
    title: 'Pastas',
    path: '/pasta',
    visible: permissao.includes('per_pasta_visualizar'),
    icon: <AssignmentInd />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  },
  {
    title: 'Tarefas',
    path: '/tarefa',
    visible: permissao.includes('per_tarefa_visualizar'),
    icon: <Assignment />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  },
  {
    title: 'Checklist',
    path: '/checklist',
    visible: permissao.includes('per_checklist_visualizar'),
    icon: <Assignment />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Agenda',
        path: '/agenda',
        visible: permissao.includes('per_agenda_visualizar'),
        icon: <AiIcons.AiFillCalendar />
      },
      {
        title: 'Origens',
        path: '/origem',
        visible: permissao.includes('per_origem_visualizar'),
        icon: <AiIcons.AiOutlineDashboard />
      }
    ]
  },
  {
    title: 'Questionários',
    path: '/questionario',
    visible: permissao.includes('per_questionario_visualizar'),
    icon: <AiIcons.AiFillCheckSquare />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Gerenciar questionario',
        path: '/questionario',
        visible: permissao.includes('gerenciar_questionario'),
        icon: <AiIcons.AiFillCheckSquare />
      },
      {
        title: 'Cadastrar respostas',
        path: '/item-respostas',
        visible: permissao.includes('cadastrar_respostas'),
        icon: <AiIcons.AiFillSnippets />
      }
    ]
  },
  {
    title: 'Empresas',
    path: '/empresas',
    icon: <RiIcons.RiBriefcaseFill />,
    visible: master === 1 ? true : false,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  }
];
