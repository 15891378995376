
import React, {useState} from "react";
import {Box} from '@material-ui/core'
import { EmpresaTable } from "../../components/EmpresaTable";

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { EmpresaProvider } from "../../hooks/empresa";
import { CriaNovoEmpresaModal } from "../../components/CriaNovoEmpresaModal";
import { EditaEmpresaModal } from "../../components/EditaEmpresaModal";

export function Empresa() {
    
  const [abreModalAdd, setAbreModalAdd] = useState(false);
  const [abreModalEdita, setAbreModalEdita] = useState(false);

  function handleAbreModalAdd(){
      setAbreModalAdd(true);
  }

  function handleFechaAddtoModal(){
    setAbreModalAdd(false);
  }
    function handleAbreModalEdita(){
      setAbreModalEdita(true);
  }

  function handleFechaEditaModal(){
    setAbreModalEdita(false);
  }

  return (
      <EmpresaProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'7'} />
            <div style={{width: '100%'}}>
              <Topbar pageTitle={'Empresas'} />
              <Box className="container_admin">
                  <EmpresaTable onOpenAddModal={handleAbreModalAdd} onOpenEditaModal={handleAbreModalEdita}   />
              </Box>
            </div>
            {abreModalAdd ? <CriaNovoEmpresaModal
              abre={abreModalAdd}
              fecha={handleFechaAddtoModal}
            /> : null}
            {abreModalEdita ? <EditaEmpresaModal
              abre={abreModalEdita}
              fecha={handleFechaEditaModal}
            /> : null}
        </div>
      </EmpresaProvider>
  );
};


