import * as S from "./styles";
import { useState, useCallback, useEffect } from 'react';
import { useTag } from "../../hooks/tag"
import { TextField, FormControl,Dialog, DialogContent, DialogTitle, DialogActions, Button,Paper} from "@material-ui/core";

import { v4 as uuidv4 } from 'uuid';

import { AdicionaTagitemModal } from "../../components/AdicionaTagitemModal/index";

import { EditaTagitemModal } from "../../components/EditaTagitemModal/index";
import { DeletaTagItemModal } from "../../components/DeletaTagItemModal/index";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Draggable from 'react-draggable';
function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}


export function AdicionaTagModal({ abre, fecha, uuid}) {

    const { criaTag, VisualizarTagPorUUID, tagItemPorUUID, VisualizarItemTagPorUUID } = useTag();


    const [nome_tag, setNomeTag] = useState('');
    const [id_tag, setIdItem] = useState('');
    const [vinculo_item, setVinculoitem] = useState('');
    const [nome_item, setNomeitem] = useState('');

    const [uuidGerado, setUUIDGerado] = useState('');

    useEffect(() => {
       setUUIDGerado(uuidv4());
    }, []);


    const handleListaTag = useCallback(async () => {
        await VisualizarTagPorUUID(uuid);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);



    const handleListaItemTag = useCallback(async () => {
        await VisualizarItemTagPorUUID(uuidGerado);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuidGerado]);
    
    

    const HandleCriaTag = async (e) => {
        e.preventDefault();
        
        let data = {
            uuid: uuidGerado,
            vinculo: uuid,
            titulo: nome_tag,
            status: 1
        }

        let result = await criaTag(data);

        if(result.erro === false) {
            fecha();
            // eslint-disable-next-line react-hooks/rules-of-hooks
            handleListaTag();
            handleListaItemTag();
        }
    };

    //ITEMS
    const [openConfirmItemTag, setModalItemTag] = useState(false);

    function handleAbreItemModalTag(){
        setModalItemTag(true);
    }

    function handleCloseItemModalTag(){
        setModalItemTag(false);
    }
    //EDITA ITEMS
    const [openConfirmEditaItemTag, setModalEditaItemTag] = useState(false);

    function handleAbreEditaItemModalTag(indice, vinculo, nome){
        setModalEditaItemTag(true);
        setIdItem(indice);
        setVinculoitem(vinculo);
        setNomeitem(nome);
    }

    function handleCloseEditaItemModalTag(){
        setModalEditaItemTag(false);
        setIdItem("");
        setVinculoitem("");
        setNomeitem("");
    }

    //DELETA ITEMS
    const [openConfirmDeletaItemTag, setModalDeletaItemTag] = useState(false);

    function handleDeletaItemModalTag(indice, vinculo, nome){
        setModalDeletaItemTag(true);
        setIdItem(indice);
        setVinculoitem(vinculo);
    }

    function handleCloseDeletaItemModalTag(){
        setModalDeletaItemTag(false);
        setIdItem("");
        setVinculoitem("");
        setNomeitem("");
    }


    return (
        <>
        <Dialog
            open={abre}
            onClose={fecha}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            className="modal_dialog"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <p> Adicionar Tag</p>
            </DialogTitle>
            <DialogContent>
                <S.FormQrCode onSubmit={HandleCriaTag}> 
                    <S.Column>
                        <FormControl variant="outlined" >
                            <TextField
                            variant="outlined"
                            label={'Nome'}
                            value={nome_tag}
                            placeholder=""
                            onChange={event => setNomeTag(event.target.value)}
                            />
                        </FormControl>

                        <FormControl>
                            <S.TagLista onClick={() => handleAbreItemModalTag()}>
                                <h1>Item <p style={{cursor: 'pointer'}}  onClick={() => handleAbreItemModalTag()}>+</p></h1>
                                <div className="lista_item">
                                    <S.TagLista>
                                        <div className="lista_item">
                                            {tagItemPorUUID && tagItemPorUUID.map(lista =>  (
                                            
                                                <div className="tag" >
                                                    <p>{lista.nome}</p>
                                                    <div className="icon">
                                                        <EditIcon style={{cursor: 'pointer'}}  onClick={() => handleAbreEditaItemModalTag(lista.id, lista.vinculo, lista.nome)} />
                                                        <DeleteIcon style={{cursor: 'pointer'}}   onClick={() => handleDeletaItemModalTag(lista.id, lista.vinculo)}  />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </S.TagLista>
                                </div>
                            </S.TagLista>
                        </FormControl>
                    </S.Column>

                    <DialogActions>
                        <Button onClick={fecha} color="primary">
                            Cancelar
                        </Button>
                        <Button
                            className="botao add"
                            variant="contained"
                            type="submit"
                        >
                        Cadastrar
                        </Button>
                    </DialogActions>
                </S.FormQrCode>
            </DialogContent>
        </Dialog>

        {openConfirmItemTag ? 
            <AdicionaTagitemModal abre={openConfirmItemTag} fecha={handleCloseItemModalTag} uuid={uuidGerado} />
        : null}
        {openConfirmEditaItemTag ? 
            <EditaTagitemModal abre={openConfirmEditaItemTag} fecha={handleCloseEditaItemModalTag} vinculo={vinculo_item} indice={id_tag}  nome={nome_item} />
        : null}
        {openConfirmDeletaItemTag ? 
            <DeletaTagItemModal abre={openConfirmDeletaItemTag} fecha={handleCloseDeletaItemModalTag}  indice={id_tag} vinculo={vinculo_item}  uuidTag={uuidGerado} />
        : null}
        </>
    )
}



