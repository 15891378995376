import {  useEffect, useCallback } from 'react';
import * as S from "./styles";
import { Accordion, AccordionDetails, AccordionSummary, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from "react-router-dom"
import { useResposta } from "../../hooks/resposta"
import { Loading } from "../Loading/index";


export function RespostaTableUUID() {
    const { isLoadingEditID, VerRespostaPorUUID, VisualizarRespostaPorUUID } = useResposta();


    const { id } = useParams();

    const handleResposta = useCallback(async () => {
        await VisualizarRespostaPorUUID(id); 
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleResposta();
    }, [handleResposta]);


    return (
        <S.Container>
            {isLoadingEditID ? 
                <Loading />
            :
            <>
            <S.InfoQuestionario>
                <h1><b>Ação</b>:  {VerRespostaPorUUID[0]?.tarefa?.acao}</h1>
                <p><b>Problema</b>: {VerRespostaPorUUID[0]?.tarefa?.problema}</p>
            </S.InfoQuestionario>
            {VerRespostaPorUUID.map((lista, index) =>  (
            <Accordion key={index}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
                >
                <S.Column>
                    <S.TituloGrande> <span>Usuário</span>: {lista.usuario.nome}</S.TituloGrande>
                    <S.Respostas> 
                        <span>Respostas:</span> 
                        {
                        Object.keys(lista.respostas).map((key, values) => 
                         <p key={key}><span>{key}</span>{ ": " + lista.respostas[key]}</p>
                        )
                        }
                    </S.Respostas>
                </S.Column>
                </AccordionSummary>
                <AccordionDetails>
                    <S.Column>
                        <Typography variant="caption">
                            Início previsto: {lista?.tarefa?.inicio_previsto} 
                        </Typography>
                        <Typography variant="caption">
                            Conclusão prevista: {lista?.tarefa?.conclusao_prevista}
                        </Typography>
                        <Typography variant="caption">
                            Início: {lista?.tarefa?.inicio} 
                        </Typography>
                        <Typography variant="caption">
                            Conclusão: {lista?.tarefa?.conclusao}
                        </Typography>
                        <Typography variant="caption">
                            Data de criação: {lista?.tarefa?.data_criacao}
                        </Typography>
                    </S.Column>
                </AccordionDetails>
            </Accordion>
            ))}
            </>
            }
        </S.Container>
    );
}