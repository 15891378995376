import React from "react";
import {Box} from '@material-ui/core'
import { CriarAcao } from "../../components/CriarAcao";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { AcaoProvider } from "../../hooks/acao";
import { AuditoriaProvider } from '../../hooks/auditoria';
import { ClienteProvider } from '../../hooks/cliente';

import { EmpresaProvider } from "../../hooks/empresa";

export function NewAcao() {
    
  return (
    <AcaoProvider>
      <AuditoriaProvider>
        <ClienteProvider>
          <EmpresaProvider>
            <div style={{display: 'flex'}}>
                <Sidebar activePage={'8'} />
                <div style={{width: '100%'}}>
                    <Topbar pageTitle={'Criar tarefa'} />
                    <Box  className="container_admin">
                        <CriarAcao />
                    </Box>
                </div>
            </div>
          </EmpresaProvider>
        </ClienteProvider>
      </AuditoriaProvider>
    </AcaoProvider>
  );
};