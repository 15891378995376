import api from "../services/api";
import { createContext, useState, useContext} from 'react';
import { toast } from 'react-toastify';

const HistoricoContext = createContext({});

export const HistoricoProvider = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingHistorico, setIsLoadingHistorico] = useState(true);
    const [historicoPorUUID, setVerHistoricoPorUUID] = useState([]);
    const [historicoCliente, setVerHistoricoPorCliente] = useState([]);
    const [historico, setHistorico] = useState([]);



    // Criar
    async function criaHistorico(dados, modal) {
        try {
            setIsLoading(true);

            const response = await api.post('/historico', dados); 
            setIsLoading(false);

            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            modal();

            return {
                erro: false,
                tabela: dados.tabela
            };
        } catch (err) {
            setIsLoading(false); 
             toast.error(err.response.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
            
            return {
                erro: true,
                tabela: dados.tabela
            };
        }
    } 
    // Criar por acao
    async function criaHistoricoAcao(dados, modal, acao) {
        try {
            setIsLoadingHistorico(true);
            if(acao === 'add'){
                const response = await api.post('/historico', dados); 

                setIsLoadingHistorico(false);

                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                modal();

                return {
                    erro: false,
                    tabela: dados.tabela
                };
            }else if(acao === 'edit'){

                const response = await api.put('/historico/'+dados.vinculo, dados); 

                setIsLoadingHistorico(false);

                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                modal();

                return {
                    erro: false,
                    tabela: dados.tabela
                };
            }

        } catch (err) {
            setIsLoadingHistorico(false); 
             toast.error(err.response.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
            return {
                erro: true
            };
        }
    } 

    async function visualizarHistoricoPorUUID(uuid, modal) {
        try {
            setIsLoadingHistorico(true);
            const responseedit = await api.get('/historico/'+uuid+'/tarefas'); 

            setVerHistoricoPorUUID(responseedit.data.itens);
           
            setIsLoadingHistorico(false);
            return {
                erro: false
            }

        } catch (err) {
            setIsLoadingHistorico(true);
           
            setIsLoadingHistorico(false);
            return {
                erro: true,
                message: err.response?.data.message
            }
        }
    }

    async function visualizarListaHistorico(uuid, tabela) {
        try {
            setIsLoadingHistorico(true);
            const responseedit = await api.get('/historico/'+uuid+'/'+tabela); 

            setHistorico(responseedit.data.itens);
           
            setIsLoadingHistorico(false);
            return {
                erro: false
            }

        } catch (err) {           
            setIsLoadingHistorico(false);
            return {
                erro: true,
                message: err.response?.data.message
            }
        }
    }



    async function visualizarHistoricoPorCliente(uuid, modal) {
        try {
            setIsLoading(true);
            const responseedit = await api.get('/historico/'+uuid+'/clientes'); 

            setVerHistoricoPorCliente(responseedit.data.itens);
           
            setIsLoading(false);
            return {
                erro: false
            }

        } catch (err) {
            setIsLoading(true);
           
            setIsLoading(false);
            return {
                erro: true,
                message: err.response?.data.message
            }
        }
    }


    return (
        <HistoricoContext.Provider value={{ isLoading, criaHistorico, historicoPorUUID, visualizarHistoricoPorUUID, visualizarHistoricoPorCliente, historicoCliente, criaHistoricoAcao, isLoadingHistorico, historico, visualizarListaHistorico }}>
            {props.children}
        </HistoricoContext.Provider>
    )

}



export function useHistorico() {
    const context = useContext(HistoricoContext);

    return context;
}