import * as S from "./styles";
import { useState } from 'react';
import { useAuditoria } from "../../hooks/auditoria"
import { TextField, FormControl, Select, MenuItem, InputLabel, FormControlLabel, Checkbox, Dialog, DialogTitle, IconButton, DialogContent, Grid, DialogActions , ButtonGroup, Button} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export function FormCadastraTarefaRecorrenteModal({tarefa, abre, fecha}) {
    const {  createTarefaRecorrente, isLoading } = useAuditoria();

    const [dia, setCada] = useState('');
    const [tipo, setFrequencia] = useState('');
    const [dias_semana, setDiaSemana] = useState([]);
    


    const handlCreateRecorrente = async event => {
        event.preventDefault();

        let data = {
            tarefa,
            tipo: Number(tipo),
            dia,
            dias_semana: dias_semana ? JSON.stringify(dias_semana) : [],
            status: 1
        }

        let resultado =  await createTarefaRecorrente(data, fecha);
        
        
        if(resultado.erro === false){
            setCada("");
            setFrequencia("");
            setDiaSemana("");
        }
    }

    const frequenciaTarefa = {
        1: "Semanal",
        2: "Mensal"
    }


    function frequenciaTarefaMontagem() {
        var es = Object.entries(frequenciaTarefa);
        return es.map((item) => (
            <MenuItem key={`origem${item[0]}`} value={item[0]}>
                {item[1]}
            </MenuItem>
        ));
    }
    const diaDaSemana = {
        '0': "Domingo",
        '1': "Segunda-Feira",
        '2': "Terça-Feira",
        '3': "Quarta-Feira",
        '4': "Quinta-Feira",
        '5': "Sexta-Feira",
        '6': "Sábado"
    }

    function arrayRemove(arr, value) { 
    
        return arr.filter(function(ele){ 
            return ele !== value; 
        });
    }
    const toggleDiaSemana = (event) => {
        let atual = dias_semana;
        let contem = atual.indexOf(event.target.value) >= 0 ;
      
        if (contem === true) {
            setDiaSemana(arrayRemove(atual,event.target.value ));
        } else {
            setDiaSemana( [ ...dias_semana, event.target.value ] );
        }
    }

    function diaSemanaTarefaMontagem() {
        var es = Object.entries(diaDaSemana);
        return es.map((item) => (
            <FormControlLabel
                control={
                <Checkbox
                 checked={
                    dias_semana.indexOf(String(item[0])) >= 0 ? true : false
                 }
                  name="diaSemana" 
                  key={item[0]} 
                  value={item[0]}  
                  onChange={toggleDiaSemana} />
                }
                key={`diaSemana${item[0]}`}
                label={item[1]}
            />
        ));
    }


    return (
        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container onSubmit={handlCreateRecorrente}>
                <DialogTitle className="titulo">
                    Adicionar recorrência
                    <IconButton className="fecha" onClick={fecha}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle> 
                <DialogContent  className="grid_container">
                    <Grid container>
                        <Grid item xs={12}>
                            <S.Column>
                                <S.TituloTopo>Repetir</S.TituloTopo>

                                <FormControl variant="outlined">
                                    <InputLabel>Frequência</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={event => setFrequencia(event.target.value)}
                                        value={tipo}
                                        label={'Frequência'}
                                        placeholder="Frequência"
                                    >
                                        {frequenciaTarefaMontagem()}
                                    </Select>
                                </FormControl>

                                {tipo !== '' && tipo === '2' ?
                                <>
                                <FormControl variant="outlined">
                                    <TextField
                                        variant="outlined"
                                        onChange={event => setCada(event.target.value)}
                                        value={dia}
                                        label={'Dia'}
                                        placeholder="Dia"
                                    />
                                </FormControl>
                                </>
                                : tipo !== '' && tipo === '1' ?
                                <>
                                <InputLabel className="mt20 dia_semana">Dia da semana</InputLabel>
                                <FormControl className="mt20">
                                    {diaSemanaTarefaMontagem()}
                                </FormControl>
                                </>
                                : null }

                            </S.Column>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className="container_acao">
                    <ButtonGroup
                    orientation="horizontal"
                    color="primary"
                    className="buttonGroup"
                    aria-label="vertical outlined primary button group"
                    >
                    <Button
                        className="botao add"
                        variant="contained"
                        type="submit"
                    >
                    {isLoading ? 'Carregando...' : 'Cadastrar'}
                    </Button>
                    </ButtonGroup>
                </DialogActions>
            </S.Container>
        </Dialog>
    );
}






