import React from "react";
import {Box} from '@material-ui/core'
import { VisualizaCliente } from "../../components/VisualizaCliente/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { TarefaProvider } from "../../hooks/tarefa";
import { ClienteProvider } from "../../hooks/cliente";
import { UsuarioProvider } from "../../hooks/user";
import { HistoricoProvider } from "../../hooks/historico";
import { OrigemProvider } from "../../hooks/origem"


export function VisualizarCliente() {


    
  return (
    <ClienteProvider>
      <TarefaProvider>
         <UsuarioProvider>
          <HistoricoProvider>
            <OrigemProvider>
                <div style={{display: 'flex'}}>
                    <Sidebar activePage={'3'} />
                    <div style={{width: '100%'}}>
                        <Topbar pageTitle={'Visualizar pasta'} />
                        <Box className="container_admin">
                            <VisualizaCliente />
                        </Box>
                    </div>
                </div>
              </OrigemProvider>
            </HistoricoProvider>
          </UsuarioProvider>
      </TarefaProvider>
    </ClienteProvider>
  );
};


