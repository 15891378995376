import axios from 'axios';
import { getToken, getClient } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Middleware: Requisição - Token
// Realiza ação em todas as requisições, no caso, envia o Token
api.interceptors.request.use(async (config) => {
  const token = getToken();
  const client = getClient();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.CLIENTE = client ? client : 'soulmalls';
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data?.error === 5401) {
      localStorage.clear();
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
