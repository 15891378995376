
import React from "react";
import {Box} from '@material-ui/core'
import { ClienteTable } from "../../components/ClienteTable/index";

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { ClienteProvider } from "../../hooks/cliente";
import { EmpresaProvider } from "../../hooks/empresa";
import { PastaProvider } from "../../hooks/pasta";

export function Cliente() {
    
    
  return (
      <ClienteProvider>
          <EmpresaProvider>
            <div style={{display: 'flex'}}>
                <Sidebar activePage={'3'} />
                <div style={{width: '100%'}}>
                  <Topbar pageTitle={'Pastas'} />
                  <Box className="container_admin">
                      <ClienteTable />
                  </Box>
                </div>
            </div>
          </EmpresaProvider>
      </ClienteProvider>
  );
};


