import * as S from './styles';
import { useState } from 'react';
import { useCliente } from '../../../hooks/cliente';
import CloseIcon from '@material-ui/icons/Close';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ButtonGroup,
  Button,
  IconButton,
  Grid
} from '@material-ui/core';

export function SearchClienteModal({ setBusca, busca, uuid }) {
  const { searchCliente } = useCliente();

  const [nome, setNome] = useState('');
  const [status, setStatus] = useState('');

  const handlePesquisa = async (event) => {
    event.preventDefault();

    let data = {
      nome,
      status
    };

    await searchCliente(data);

    setBusca(false);
  };

  return (
    <Dialog
      open={busca}
      onClose={() => setBusca(false)}
      className="modal_dialog"
    >
      <S.Container onSubmit={handlePesquisa}>
        <DialogTitle className="titulo">
          Pesquise
          <IconButton className="fecha" onClick={() => setBusca(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className="grid_container">
          <Grid container>
            <Grid item xs={12}>
              <S.Column>
                <FormControl className="form_control">
                  <TextField
                    variant="outlined"
                    label={'Nome'}
                    value={nome}
                    placeholder="Nome"
                    onChange={(event) => setNome(event.target.value)}
                  />
                </FormControl>
                <FormControl className="form_control" variant="outlined">
                  <InputLabel id="demo-simple-select-required-label">
                    Status
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setStatus(event.target.value)}
                    value={status}
                    label={'Status'}
                    placeholder="Status"
                  >
                    <MenuItem key="1" value="">
                      <em>Selecione o status</em>
                    </MenuItem>
                    <MenuItem key="2" value="1">
                      <em>Ativo</em>
                    </MenuItem>
                    <MenuItem key="3" value="2">
                      <em>Inativo</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </S.Column>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="container_acao">
          <ButtonGroup
            orientation="horizontal"
            color="primary"
            className="buttonGroup"
            aria-label="vertical outlined primary button group"
          >
            <Button className="botao edit" variant="contained" type="submit">
              Pesquisar
            </Button>
          </ButtonGroup>
        </DialogActions>
      </S.Container>
    </Dialog>
  );
}
