import * as S from "./styles";
import { useState }  from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import CloseIcon from "@material-ui/icons/Close";
import { FormControl, Dialog, DialogContent, DialogTitle, DialogActions, ButtonGroup, Button, IconButton, Grid, TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import { useDashboard } from "../../hooks/dashboard";

moment.locale('pt-br');

export function DownloadRelatorioDashboardModal({ abre, fecha }) {

    const { downloadLista } = useDashboard();
    const [dataInicial, setDataInicio] = useState('');
    const [dataFinal, setDataFinal] = useState('');

     const handlePesquisa = async event => {
        event.preventDefault();

        let data = {
            dataFinal: moment(dataFinal, "DD-MM-YYYY").format('YYYY-M-DD'),
            dataInicial: moment(dataInicial, "DD-MM-YYYY").format('YYYY-M-DD')
        }

        await downloadLista(data);
      
    }

 
    return (

        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container onSubmit={handlePesquisa}>
                <DialogTitle className="titulo">
                    Relatório
                    <IconButton className="fecha" onClick={fecha}>
                    <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent  className="grid_container">
                    <Grid container>
                        <S.Row>
                            <FormControl>
                                <InputMask
                                type="text"
                                mask="99/99/9999"
                                placeholder="Data de início"
                                onChange={event => setDataInicio(event.target.value)}
                                value={dataInicial}
                                maskChar=" "
                                >
                                {() => <TextField variant="outlined" label={'Data de início'} />}
                                </InputMask>
                            </FormControl>
                            <FormControl>
                                <InputMask
                                type="text"
                                mask="99/99/9999"
                                placeholder="Data final"
                                onChange={event => setDataFinal(event.target.value)}
                                value={dataFinal}
                                maskChar=" "
                                >
                                {() => <TextField variant="outlined" label={'Data final'} />}
                                </InputMask>
                            </FormControl>
                        </S.Row>
                    </Grid>
                </DialogContent>

                <DialogActions className="container_acao">
                    <ButtonGroup
                    orientation="horizontal"
                    color="primary"
                    className="buttonGroup"
                    aria-label="vertical outlined primary button group"
                    >
                    <Button
                        className="botao edit"
                        variant="contained"
                        type="submit"
                    >
                        Download
                    </Button>
                    </ButtonGroup>
                </DialogActions>
            </S.Container>
        </Dialog>
    )
}
