import api from "../services/api";
import {createContext, useState, useContext, } from 'react';
import { toast } from 'react-toastify';

const TagContext = createContext({});

export const TagProvider = (props) =>  {
    const [tagPorUUID, setTagPorUUID] = useState([]);
    const [tagItemPorUUID, setTagItemPorUUID] = useState([]);
    const [isLoadingTag, setIsLoadingTag] = useState(false);
    const [isLoadingEditID, setIsLoadingEditID] = useState(false);


    // CRIA TAG

    async function criaTag(dados) {
        try {
            setIsLoadingTag(true);
            const responseedit = await api.post('/tag', dados); 
            toast.success(responseedit.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoadingTag(false);
            return {
                erro: false
            }
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingTag(false);

            return {
                erro: true
            }
        }
    }


    // EDITA POR UUID
    
    async function EditaTagPorUUID(uuid, dados) {
        try {
            setIsLoadingEditID(true);
            await api.put('/tag/'+uuid, dados); 

            setIsLoadingEditID(false);
            
            return {
                erro: false
            }
        } catch (err) {
            setIsLoadingEditID(true);
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingEditID(false);
            return {
                erro: true,
            }
        }
    }


    // DELETA POR UUID
    
    async function DeletaTagPorUUID(uuid) {
        try {
            setIsLoadingEditID(true);
            await api.delete('/tag/'+uuid); 

            setIsLoadingEditID(false);
            
            return {
                erro: false
            }
        } catch (err) {
            setIsLoadingEditID(true);
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingEditID(false);
            return {
                erro: true,
            }
        }
    }

    // TAG POR UUID
    
    async function VisualizarTagPorUUID(uuid) {
        try {
            setIsLoadingEditID(true);
            const responseedit = await api.get('/tag/'+uuid); 
            setTagPorUUID(responseedit.data);

            setIsLoadingEditID(false);
            
            return {
                erro: false
            }
        } catch (err) {
            setIsLoadingEditID(true);
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingEditID(false);
            return {
                erro: true,
            }
        }
    }

    // Item TAG POR UUID
    
    async function VisualizarItemTagPorUUID(uuid) {
        try {
            setIsLoadingEditID(true);
            const responseedit = await api.get('/taglista/'+uuid); 
            setTagItemPorUUID(responseedit.data);

            setIsLoadingEditID(false);
            
            return {
                erro: false
            }
        } catch (err) {
            setIsLoadingEditID(true);
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingEditID(false);
            return {
                erro: true,
            }
        }
    }
    
    
    // CRIA ITEM TAG

    async function criaItemTag(dados) {
        try {
            setIsLoadingTag(true);
            const responseedit = await api.post('/taglista', dados); 
            toast.success(responseedit.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoadingTag(false);
            return {
                erro: false
            }
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingTag(false);

            return {
                erro: true
            }
        }
    }
    // EDITA ITEM TAG

    async function editaItemTag(indice, uuid, dados) {
        try {
            setIsLoadingTag(true);
            const responseedit = await api.put('/taglista/'+indice+'/'+uuid, dados); 
            toast.success(responseedit.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoadingTag(false);
            return {
                erro: false
            }
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingTag(false);

            return {
                erro: true
            }
        }
    }

    // DELETA ITEM TAG
    async function deletaItemTag(indice, uuid) {
        try {
            setIsLoadingTag(true);
            const responseedit = await api.delete('/taglista/'+indice+'/'+uuid); 
            toast.success(responseedit.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoadingTag(false);
            return {
                erro: false
            }
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingTag(false);

            return {
                erro: true
            }
        }
    }
    

    

    return (
        <TagContext.Provider value={{isLoadingTag, tagPorUUID, isLoadingEditID, criaTag, VisualizarTagPorUUID, EditaTagPorUUID, DeletaTagPorUUID, VisualizarItemTagPorUUID, tagItemPorUUID, criaItemTag, editaItemTag, deletaItemTag}}>  
            {props.children}
        </TagContext.Provider>
    )

}



export function useTag() {
    const context = useContext(TagContext);

    return context;
}
