const lista = [
    {
        dashboard: {
            titulo: 'Dashboard',
            permissao: 'per_dashboard',
            lista: {
                per_dashboard_visualizar: 'Visualizar',
            },
        },
        usuario: {
            titulo: 'Usuário',
            permissao: 'per_usuario',
            lista: {
                per_usuario_visualizar: 'Visualizar',
                per_usuario_add: 'Adicionar',
                per_usuario_deletar: 'Deletar',
                per_usuario_editar: 'Editar',
            },
        },
        pasta: {
            titulo: 'Pasta',
            permissao: 'per_pasta',
            lista: {
                per_pasta_visualizar: 'Visualizar',
                per_pasta_add: 'Adicionar',
                per_pasta_deletar: 'Deletar',
                per_pasta_editar: 'Editar',
            },
        },
        tarefa: {
            titulo: 'Tarefa',
            permissao: 'per_tarefa',
            lista: {
                per_tarefa_visualizar: 'Visualizar',
                per_tarefa_add: 'Adicionar',
                per_tarefa_deletar: 'Deletar',
                per_tarefa_editar: 'Editar',
                per_tarefa_relatorio: 'Relatório',
            },
        },
        checklist: {
            titulo: 'Checklist',
            permissao: 'per_checklist',
            lista: {
                per_checklist_visualizar: 'Visualizar',
            },
        },
        agenda: {
            titulo: 'Agenda',
            permissao: 'per_agenda',
            lista: {
                per_agenda_visualizar: 'Visualizar',
                per_agenda_add: 'Adicionar',
                per_agenda_deletar: 'Deletar',
                per_agenda_editar: 'Editar',
            },
        },
        origem: {
            titulo: 'Origem',
            permissao: 'per_origem',
            lista: {
                per_origem_visualizar: 'Visualizar',
                per_origem_add: 'Adicionar',
                per_origem_deletar: 'Deletar',
                per_origem_editar: 'Editar',
            },
        },
        questionario: {
            titulo: 'Questionário',
            permissao: 'per_questionario',
            lista: {
                per_questionario_visualizar: 'Visualizar',
            },
        },
        gerenciar_questionario: {
            titulo: 'Gerenciar questionários',
            permissao: 'per_gerenciar_questionario',
            lista: {
                per_gerenciar_questionario_visualizar: 'Visualizar',
                per_gerenciar_questionario_add: 'Adicionar',
                per_gerenciar_questionario_deletar: 'Deletar',
                per_gerenciar_questionario_editar: 'Editar',
                per_gerenciar_questionario_relatorio: 'Relatório',
            },
        },
        cadastrar_respostas: {
            titulo: 'Cadastrar respostas',
            permissao: 'per_cadastrar_respostas',
            lista: {
                per_cadastrar_respostas_visualizar: 'Visualizar',
                per_cadastrar_respostas_add: 'Adicionar',
                per_cadastrar_respostas_deletar: 'Deletar',
                per_cadastrar_respostas_editar: 'Editar',
            },
        },
        tag: {
            titulo: 'Tag',
            permissao: 'per_tag',
            lista: {
                per_tag_visualizar: 'Visualizar',
                per_tag_add: 'Adicionar',
                per_tag_deletar: 'Deletar',
                per_tag_editar: 'Editar',
            },
        },
        auditoria: {
            titulo: 'Auditoria',
            permissao: 'per_auditoria',
            lista: {
                per_auditoria_visualizar: 'Visualizar',
                per_auditoria_add: 'Adicionar',
                per_auditoria_deletar: 'Deletar',
                per_auditoria_editar: 'Editar',
                per_auditoria_relatorio: 'Relatório',
            },
        },
        historico: {
            titulo: 'Histórico',
            permissao: 'per_historico',
            lista: {
                per_historico_visualizar: 'Visualizar',
                per_historico_deletar: 'Deletar',
            },
        },
        respostas: {
            titulo: 'Respostas',
            permissao: 'per_respostas',
            lista: {
                per_respostas_visualizar: 'Visualizar',
                per_respostas_deletar: 'Deletar',
            },
        },
    }
];

let listaPermissao = lista.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y)], []);

export default listaPermissao[0];