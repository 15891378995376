import * as S from "./styles";
import { useOrigem } from "../../hooks/origem";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, DialogTitle, DialogActions, ButtonGroup, Button, IconButton, Grid } from "@material-ui/core";


export function VisualizaOrigemModal({ abre, fecha,onOpenEditaModal }) {
    const { getOrigemPorUuid, origemPorUUID } = useOrigem();


    const handleClickOpenEdit = async (e,uuid) => {
        e.preventDefault();
       await getOrigemPorUuid(uuid);
        onOpenEditaModal();
    };

    let gerente = Number(localStorage.getItem('GTPROWORK'));
    let master = Number(localStorage.getItem('MMPROWORK'));
    let administrador = Number(localStorage.getItem('MPROWORK'));

 
    return (
        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container>
                <DialogTitle className="topo">
                    <p>Visualizar Origem</p>
                    <IconButton className="fecha" onClick={fecha}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent  className="grid_container">
                    <Grid container>
                        <Grid item xs={12}>
                            <S.Column>
                                <h1>Dados</h1>
                                <div className="titulo">
                                    <h2>Título: <span>{origemPorUUID[0]?.titulo}</span></h2>
                                    <h2>Status: <span>{origemPorUUID[0]?.status === 2 ? 'Desativado' : 'Ativo'}</span></h2>
                                </div>
                            </S.Column>
                        </Grid>
                    </Grid>
                </DialogContent>

                {administrador === 1 || gerente === 1 || master === 1 ?
                <DialogActions className="container_acao">
                    <ButtonGroup
                    orientation="horizontal"
                    color="primary"
                    className="buttonGroup"
                    aria-label="vertical outlined primary button group"
                    >
                    <Button
                        className="botao edit"
                        variant="contained"
                        type="button"
                        onClick={(e) => handleClickOpenEdit(e, origemPorUUID[0]?.uuid)}
                    >
                        Editar
                    </Button>
                    </ButtonGroup>
                </DialogActions>
                : null}
            </S.Container>
        </Dialog>
    )
}