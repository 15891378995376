import styled from 'styled-components'

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
    @media (max-width: 768px) {
	    flex-direction: column;
    }
    .MuiFormControl-root{
        margin-bottom: 20px;
    }
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-right: 5px;
    .MuiFormControl-root{
        margin-bottom: 8px;
    }
`;

export const Container = styled.form`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0px;


    label{
        color: var(--info-color);
    }


    input,
    select {
        width: 100%;
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 0.25rem;
        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;
        &::placeholder{
            color: var(--text-body);
        }
        //todo input que tiver um input acima vai receber margin top
        & + input {
            margin-top: 0.25rem;
        }

    }
    .MuiSelect-selectMenu{
        border: 1px solid #d7d7d7;
        background: #e7e9ee;
        &::placeholder{
            color: var(--text-body);
        }
    }

    h2 {
        width: 100%;
        color: var(--red);
        font-size: 1.5rem;
        margin-bottom: 5px;
        border-bottom: 1px solid #d7d7d7;
        padding-left: 10px;
        padding-top: 20px;
    }

    button[type=submit] {
        width: 100%;
        max-width: 280px;
        padding: 0 1.5rem;
        height: 4rem;
        background: #33CC95;
        color: #FFFFFF;
        border-radius: 0.25rem;
        border: 0;
        font-size: 1rem;
        margin-top: 1.8rem;
        font-weight: 600;
        margin-left: 20px;
        cursor: pointer;
        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.9);
        }
        @media (max-width: 768px) {
            margin-left: 0px;
        }
    }
`

export const ContainerCardForm = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
        flex-direction: column;
    }

    .card-form {
        width: 100%;
        max-width: 1000px;
        display: flex;
        gap: 1rem;
        margin: 0px 1rem;
        .item{
            flex: 1;
            width: calc((100% / 2) - 20px);
        }

        @media (max-width: 900px) {
            width: 100%;
            margin: 0px;
            flex-direction: column;
            h3{
                margin-top: 40px;
            }
            .item{
                flex: 1;
                width: 100%;
            }
        }
        .rs-uploader-picture .rs-uploader-trigger-btn {
            width: 150px;
            height: 150px;
        }
        .rs-uploader-picture .rs-uploader-trigger-btn {
            border-radius: 50%;
            border: solid 2px #CCC;
        }
        .rs-uploader-trigger-btn {
            img {
                object-fit: cover;
            }
        }
        .rs-icon-size-5x,
        .rs-icon-size-5x.rs-icon {
            font-size: 150px;
            height: 0px;
            margin-left: -2px;
            margin-top: -17px;
        }
        .rs-icon-size-5x, .cYHYoN .card-form .rs-icon-size-5x.rs-icon{
            margin-top: 0px !important;
        }
	

    }

`

export const TituloTopo = styled.h1`
    width: 100%;
    color: var(--info-color);
    font-size: 1.2em;
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 5px;

`
export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

`

export const ContainerItem = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem 1rem;
    margin-bottom: 50px;
    @media (max-width: 1300px) {
        top: 400px;
    }
    @media (max-width: 900px) {
        top: 600px;
    }

    button.add {
        height: 3rem;
        padding: 0 2rem;
        font-size: 1rem;
        color: #FFF;
        background-color: var(--info-color);
        border-color: var(--info-color);
        border-radius: 0.25rem;
        border: none;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: filter 0.2s;
        cursor: pointer;

        &:hover{
            filter: brightness(0.9)
        }
        &:focus{
            outline: none
        }
    }


    h2 {
        width: 100%;
        color: var(--info-color);
        font-size: 1.5rem;
        margin-bottom: 15px;
        border-bottom: 1px solid #d7d7d7;
        padding-left: 20px;
        padding-top: 20px;
    }

    table {
        width: 100%;
        border-spacing: 0 0.5rem;

        th {
            color: var(--text-body);
            font-weight: 400;
            padding: 15px 2rem;
            text-align: left;
            line-height: 1.5em;
            background-color: #FFFFFF;
            filter: brightness(0.9);
            &.status {
                text-align: center;
            }
            &.acao {
                max-width: 50px;
                text-align: center;
            }
        }

        tr {
            cursor: pointer;
            border: 2px solid #CCC;
            &:hover{
                filter: brightness(0.9);
            }
        }
        
        td {
            padding: 1rem 2rem;
            border: 0;
            background: var(--shape);
            font-weight: 400;
            color: var(--text-body);

            @media screen and (max-width: 600px) {
                &:nth-child(2) {
                    display: none;
                }
            }

            &:first-child{
                max-width: 300px;
                color: var(--text-title);
            }

            &.deletar{
                color: #CCC;
                text-align: center;
                p{
                    color: var(--red);
                }
                button {
                    background-color: transparent;
                    color: #CCC;
                    padding: 0px;
                    margin-bottom:0px;
                }
            }


        }

        .ativo  {
            vertical-align: middle;
            div{
                margin: 0 auto;
                content: ' ';
                height: 25px;
                width: 8px;
                background: var(--green);
                border-radius: 2px;
            }
        }

        .inativo {
            vertical-align: middle;
            div{
                margin: 0 auto;
                content: ' ';
                height: 25px;
                width: 8px;
                background: var(--red);
                border-radius: 2px;
            }
        }
    }
    
    
    @media screen and (max-width: 768px) {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }
        
        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        
        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }
        
        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right;
            &.deletar, &.ativo, &.inativo {
                text-align: right;
                max-width: 100%;
                color: var(--text-body);
            }
            &.ativo, &.inativo {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                div {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    margin: 0px;
                    width: 100%;
                    max-width: 8px;
                }
            }
            &:first-child{
                max-width: 100%;
            }
        }
        
        table td::before {
            /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }
        
        table td:last-child {
            border-bottom: 0;
        }
    }

`


export const ContainerPagination = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .rs-pagination > li.rs-pagination-btn-active > a{
        color: var(--red);
    }
    .rs-table-pagination-start {
        display: none;
    }
`