import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  height: 60px;
  display: flex;
  color: #FFFF;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  text-decoration: none;
  font-size: 18px;
  border-radius: 0.25rem;
  margin-bottom: 10px;
  &.ativo,
  &:hover {
    background: #FFFFFF;
    cursor: pointer;
    color: #034075;
    svg {
      color: #034075 !important;
    }
    .seta {
      z-index: 10;
    }
    .seta svg {
      width: 35px;
      height: 35px;
    }
  }
  .container_title {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 10px;
  background: #FFF;
  text-decoration: none;
  color: #034075;
  font-size: 17px;
  border-radius: 0.25rem;
  &.ativo,
  &:hover {
    background: #FFF;
    filter: brightness(0.9);
    cursor: pointer;
  }
  svg{
    color: #034075 !important;
    margin-left: -10px;
  }
`;

const SubMenu = ({ item, active }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSubnav(!subnav);
  };
  var classe_ativo;

  if(active && active === '2' && item.title === 'Usuários'){
    classe_ativo = 'ativo';
  }else if(active && active === '1' && item.title === 'Home'){
    classe_ativo = 'ativo';
  }else if(active && active === '3' && item.title === 'Pastas'){
    classe_ativo = 'ativo';
  }else if(active && active === '8' && item.title === 'Tarefas'){
    classe_ativo = 'ativo';
  }else if(active && active === '4' && (item.title === 'CheckLists' || item.title === 'CheckLists - Recorrentes')){
    classe_ativo = 'ativo';
  }else if(active && active === '5' && item.title === 'Questionários'){
    classe_ativo = 'ativo';
  }else if(active && active === '6' && (item.title === 'Dashboard' || item.title === 'Dashboard - Visitas')){
    classe_ativo = 'ativo';
  }else if(active && active === '7' && (item.title === 'Origens')){
    classe_ativo = 'ativo';
  }else{
    classe_ativo = '';
  }


  return (    
    <>
    {!item.subNav? 
      <SidebarLink to={item.path || '/'} className={classe_ativo}>
        <div className="container_title">
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
      </SidebarLink>
      :
      <SidebarLink onClick={item.subNav && showSubnav} to="/" className={`seta ${classe_ativo}`} >
        <div className="container_title">
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
    }
      {subnav && item.subNav.map((item, index) => {
          return (
            <>
              <DropdownLink to={item.path || '/'} key={index}>
                {/* {item.icon} */}
                <SidebarLabel>{item.title}</SidebarLabel>
              </DropdownLink>
            </>
          );
        })
      }
    </>
  );
};

export default SubMenu;