import * as S from "./styles";
import { useState, useEffect, useCallback } from 'react';
import { useCheckList } from "../../hooks/checklist"
import { useItemRespostas } from "../../hooks/itemRespostas"
import { useGrupo } from "../../hooks/grupo";
import { useEmpresa } from "../../hooks/empresa";
import Pagination from '@material-ui/lab/Pagination';
import { TextField, FormControl, Card, CardContent, Select, MenuItem, InputLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Paper} from "@material-ui/core";
import Draggable from 'react-draggable';
import { CriaNovoGrupoModal } from "../CriaNovoGrupoModal/index";
import { useHistory } from "react-router-dom"

import api from "../../services/api";
import { toast } from 'react-toastify';
import { Loading } from "../Loading/index";
import Skeleton from "react-loading-skeleton";

import {
    useParams
  } from "react-router-dom";


  function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export function FormEditaChecklist() {
    let history = useHistory();
    
    const { empresaListaAtiva } = useEmpresa();
    const { editChecklist, isLoading, getCheckListItemPorUuid, checklistItemPage } = useCheckList();
    const { itemRespostasListaAtiva } = useItemRespostas();    
    const { grupoLista, isLoadingView, handleGrupo, paginationGrupo, deletarGrupo} = useGrupo();
    const { id } = useParams();

    const usuarioPermissao = Number(localStorage.getItem('MMPROWORK'));
    const [titulo, setTitulo] = useState('');
    const [status, setStatus] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [loading, setLoading] = useState(false);
        
    const [openConfirmDeletar, setOpenConfirmarDeletar] = useState(false);
    const [uuidItem, setUuidItem] = useState('');
    const [nomeDelItem, setNomeCheckList] = useState('');
    const [uuidDelItem, setUuidCheckList] = useState('');
    

    const handleLoadGrupo = useCallback(async () => {
        await handleGrupo(id);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
       
    
    useEffect(() => {
        setLoading(true);
        api.get("/checklist/"+id)
            .then(({ data }) => {
                setLoading(false);
                setTitulo(data.titulo || "");
                setEmpresa(data?.empresa || "");
                setStatus(data?.status || "");
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response?.data.message, {
                    position: toast.POSITION.TOP_CENTER
                }); 
    
            })
            .finally((response) => setLoading(false));

            getCheckListItemPorUuid(id);
            handleLoadGrupo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    


    const handlEditChecklist = async event => {
        event.preventDefault();

        let data = {
            titulo,
            empresa,
            status,
        }

        await editChecklist(data, id);
        
        
    }

    const handleSelect = async (event, value) => {
        await checklistItemPage(id,value);
    }
    
    
    // modal de deletar item
    const handleCloseDeletarConfirm = async () => {
        setOpenConfirmarDeletar(false);
        setNomeCheckList('');
        setUuidCheckList('');
    };
    

    const handleDeletarConfirm = async (uuid) => {
        setOpenConfirmarDeletar(false);
        await deletarGrupo(uuid, id);
        setNomeCheckList('');
        setUuidCheckList('');
    };

    const handleClickOpenConfirm = async (nome, uuid) => {
        setOpenConfirmarDeletar(true);
        setNomeCheckList(nome);
        setUuidCheckList(uuid);
    };


    //Adiciona grupo 
    const [abreModalAddGrupo, setAbreModalAddGrupo] = useState(false);
    function handleAbreModalAddGrupo(uuid){
        setUuidItem(uuid);
        setAbreModalAddGrupo(true);
    }

    function handleFechaAddtoModalGrupo(){
        setUuidItem('');
        setAbreModalAddGrupo(false);
    }
    
    const handleClickAbreGrupo = async (uuid) => {
        history.push("/grupo/"+uuid+"/"+id);
    };


    return (
        <S.Wrapper>
            <S.Container onSubmit={handlEditChecklist}>
                {loading ? 
                    <Loading />
                :
                <>
                    <S.ContainerCardForm>
                        <div className="card-form">
                            <Card className="item">
                                <CardContent>
                                    <S.TituloTopo>Dados</S.TituloTopo>
                                    <S.Column>
                                        <FormControl>
                                            <TextField
                                            variant="outlined"
                                            label={'Título'}
                                            value={titulo}
                                            placeholder="Título"
                                            onChange={event => setTitulo(event.target.value)}
                                            />
                                        </FormControl>
                                        {usuarioPermissao === 1 ?
                                            <FormControl className="form_control" variant="outlined" >
                                                <InputLabel id="demo-simple-select-required-label">Empresa</InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    onChange={event => setEmpresa(event.target.value)}
                                                    value={empresa}
                                                    label={'Empresa'}
                                                    placeholder="Empresa"
                                                >
                                                    <MenuItem value="">
                                                        <em>Selecione um empresa</em>
                                                    </MenuItem>
                                                    {empresaListaAtiva.length > 0 ? 
                                                        empresaListaAtiva.map(lista =>  (
                                                            <MenuItem key={lista.uuid} value={lista.id}>{lista.nome}</MenuItem>
                                                        ))
                                                    :null}
                                                </Select>
                                            </FormControl>
                                        : null}
                                    
                                    </S.Column>
                                </CardContent>
                            </Card>
                        
                            <Card className="item">
                                <CardContent>
                                    <S.TituloTopo>Acesso</S.TituloTopo>
                                    <S.Column>
                                        <FormControl variant="outlined"  >
                                            <InputLabel id="demo-simple-select-required-label">Status</InputLabel>
                                            <Select
                                                variant="outlined"
                                                onChange={event => setStatus(event.target.value)}
                                                value={status}
                                                label={'Status'}
                                                placeholder="Status"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione um status</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Ativo</MenuItem>
                                                <MenuItem value={2}>Inativo</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </S.Column>
                                </CardContent>
                            </Card>
                        </div>
                    </S.ContainerCardForm>
                    <button type="submit">
                        {isLoading ? 'Carregando...' : 'Atualizar'}
                    </button>
                </>
                }
            </S.Container>

            <S.ContainerItem>
                <h2>Grupos</h2>
                <button className="add" type="button" onClick={() => handleAbreModalAddGrupo(id)}>
                    Novo grupo
                </button>
                <S.Container>

                {isLoadingView ? 
                <Loading />
                : 
                    <table>
                        <thead>
                            <tr>
                                <th>Título</th>
                                <th className="status">Status</th>
                                <th className="acao">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {grupoLista ? grupoLista.map(lista =>  (
                                <tr key={lista.uuid}>
                                    <td data-label="Texto" onClick={() => handleClickAbreGrupo(lista.uuid,paginationGrupo[0].pagina_atual)}>{isLoading ? <Skeleton /> : lista.titulo}</td>
                                    <td data-label="Status" className={lista.status === 1 ? 'ativo' : 'inativo'} 
                                    onClick={() => handleClickAbreGrupo(lista.uuid,paginationGrupo[0].pagina_atual)}>
                                        {isLoading ? <Skeleton /> : <div></div>}
                                    </td>
                                    <td data-label="Deletar" className="deletar" onClick={() => handleClickOpenConfirm(lista.titulo, lista.uuid)} >
                                        <p>
                                            Deletar
                                        </p>
                                    </td>
                                </tr>
                            )) : null}

                        </tbody>
                    </table>
                }
                        
                    <S.ContainerPagination>
                        {paginationGrupo.length ?
                        <Pagination
                            showLastButton={true}
                            showFirstButton={true}
                            color="primary"
                            count={paginationGrupo[0].ultima_pagina}
                            page={Number(paginationGrupo[0].pagina_atual)}
                            onChange={handleSelect}
                        />
                        : null}
                    </S.ContainerPagination>
                </S.Container>
            </S.ContainerItem>
   
            {abreModalAddGrupo ? <CriaNovoGrupoModal
              abre={abreModalAddGrupo}
              fecha={handleFechaAddtoModalGrupo}
              uuid={uuidItem}
              itens={itemRespostasListaAtiva.length > 0 ? itemRespostasListaAtiva : []}

            /> : null}
            <Dialog
            open={openConfirmDeletar}
            onClose={handleCloseDeletarConfirm}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Deletar
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Tem certeza que deseja deletar o grupo {nomeDelItem || ''}, essa ação não poderá ser desfeita
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={() => handleCloseDeletarConfirm()} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => handleDeletarConfirm(uuidDelItem || '')} color="secondary">
                    Deletar
                </Button>
                </DialogActions>
            </Dialog> 
        </S.Wrapper>
    );
}






