import React from "react";
import {Box} from '@material-ui/core'
import { FormCadastraUsuario } from "../../components/FormCadastraUsuario/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { UsuarioProvider } from "../../hooks/user";
import { EmpresaProvider } from "../../hooks/empresa";


export function NewUser() {
    
  return (
    <UsuarioProvider>
      <EmpresaProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'2'} />
            <div style={{width: '100%'}}>
                <Topbar pageTitle={'Cadastrar usuários'} />
                <Box className="container_admin">
                    <FormCadastraUsuario />
                </Box>
            </div>
        </div>
      </EmpresaProvider>
    </UsuarioProvider>
  );
};


