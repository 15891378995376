import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { TarefaTable } from '../../components/TarefaTable/index';

import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar/index';
import { TarefaProvider } from '../../hooks/tarefa';
import { OrigemProvider } from '../../hooks/origem';
import { HistoricoProvider } from '../../hooks/historico';
import { ClienteProvider } from '../../hooks/cliente';
import { ListaHistoricoComponent } from '../../components/ListaHistoricoComponent/index';

export function Tarefa() {
  const [abreModal, setAbreModal] = useState(false);

  function handleAbreModalHistorico() {
    setAbreModal(true);
  }

  function handleFechaHistoricoModal() {
    setAbreModal(false);
  }

  return (
    <TarefaProvider>
      <HistoricoProvider>
        <OrigemProvider>
          <div style={{ display: 'flex' }}>
            <Sidebar activePage={'4'} />
            <div style={{ width: '100%' }}>
              <ClienteProvider>
                <Topbar pageTitle={'Checklists'} />
              </ClienteProvider>
              <Box className="container_admin">
                <TarefaTable verModalHistorico={handleAbreModalHistorico} />
              </Box>
            </div>
          </div>
          {abreModal ? (
            <ListaHistoricoComponent
              fecha={handleFechaHistoricoModal}
              abre={abreModal}
            />
          ) : null}
        </OrigemProvider>
      </HistoricoProvider>
    </TarefaProvider>
  );
}
