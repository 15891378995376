import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  padding: 40px;

  h1.topo {
    font-size: 2em;
    line-height: 1.5em;
    color: var(--info-color);
    margin-bottom: 18px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }

  @media screen and (max-width: 500px) {
    h1.topo {
      margin-top: 80px;
    }
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  table {
    min-width: 250px;
    border-collapse: collapse;
    border: 1px solid;
  }
  td.title {
    font-weight: bold;
  }
  td {
    padding: 5px;
    border: 1px solid;
  }
  td.cor {
    width: 50px;
  }
`;

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerGrafico = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 40px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 40px;
  .recharts-legend-item {
    flex: 1;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media screen and (max-width: 768px) {
    svg.recharts-surface {
      margin-left: -20px;
    }
    .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
      .recharts-cartesian-axis-ticks {
      display: none;
    }
    .recharts-legend-item {
      margin: 0px 20px;
    }
  }
  .recharts-legend-wrapper {
    bottom: 0px !important;
  }
`;

export const TabelaContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  table {
    width: 100%;
    border-spacing: 0 0.5rem;

    th {
      color: var(--text-body);
      font-weight: 400;
      padding: 15px 1rem;
      text-align: left;
      line-height: 1.5em;
      background-color: #ffffff;
      filter: brightness(0.9);
      &.status {
        text-align: center;
      }
      &.acao {
        max-width: 50px;
        text-align: center;
      }
      @media screen and (max-width: 600px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }

    tr {
      cursor: pointer;
      border: 2px solid #ccc;
      text-align: left;
      &:hover {
        filter: brightness(0.9);
      }
    }

    td {
      padding: 1rem 1rem;
      border: 0;
      background: var(--shape);
      font-weight: 400;
      color: var(--text-body);

      @media screen and (max-width: 600px) {
        &:nth-child(2) {
          display: none;
        }
      }

      &:first-child {
        color: var(--text-title);
      }

      &.deletar {
        color: #ccc;
        text-align: center;
        p {
          color: var(--red);
        }
        button {
          background-color: transparent;
          color: #ccc;
          padding: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    table td::before {
      /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
`;
