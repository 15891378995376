import React from 'react';
import { Box } from '@material-ui/core';
import { FormEditaTarefa } from '../../components/FormEditaTarefa/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar/index';
import { AuditoriaProvider } from '../../hooks/auditoria';
import { TagProvider } from '../../hooks/tag';
import { EmailProvider } from '../../hooks/email';
import { HistoricoProvider } from '../../hooks/historico';
import { AcaoProvider } from '../../hooks/acao';
import { RespostaProvider } from '../../hooks/resposta';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment';
import MomentUtils from '@date-io/moment';

export function EditTarefa() {
  return (
    <AuditoriaProvider>
      <TagProvider>
        <EmailProvider>
          <HistoricoProvider>
            <AcaoProvider>
              <RespostaProvider>
                <div style={{ display: 'flex' }}>
                  <Sidebar activePage={'4'} />
                  <div style={{ width: '100%' }}>
                    <Topbar pageTitle={'Editar Auditoria'} />
                    <Box className="container_admin">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <FormEditaTarefa />
                      </MuiPickersUtilsProvider>
                    </Box>
                  </div>
                </div>
              </RespostaProvider>
            </AcaoProvider>
          </HistoricoProvider>
        </EmailProvider>
      </TagProvider>
    </AuditoriaProvider>
  );
}
