import * as S from "./styles";
import { useUsuario } from "../../hooks/user";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom"
import { Dialog, DialogContent, DialogTitle, DialogActions, ButtonGroup, Button, IconButton, Grid } from "@material-ui/core";


export function VisualizarUsuarioModal({ abre, fecha }) {
    let history = useHistory();
    const { VerUsuarioPorUUID } = useUsuario();

    function handleClickOpenEdit(e,uuid) {
        e.preventDefault();
        history.push("/usuario/"+uuid);
    };

    let gerente = Number(localStorage.getItem('GTPROWORK'));
    let administrador = Number(localStorage.getItem('MPROWORK'));
    let master = Number(localStorage.getItem('MMPROWORK'));

    const permissao = JSON.parse(localStorage.getItem('PERMISSAO_USER_EPROWORK')) ?? [];

    return (
        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container>
                <DialogTitle className="topo">
                    <p>{VerUsuarioPorUUID[0]?.nome}</p>
                    <IconButton className="fecha" onClick={fecha}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent  className="grid_container">
                    <Grid container>
                        <Grid item xs={12}>
                            <S.Column>
                                <h1>Dados</h1>
                                <div className="titulo">
                                    <h2>Nome: <span>{VerUsuarioPorUUID[0]?.nome}</span></h2>
                                    <h2>CPF: <span>{VerUsuarioPorUUID[0]?.cpf.br}</span></h2>
                                    <h2>Email: <span>{VerUsuarioPorUUID[0]?.email}</span></h2>
                                    <h2>Celular: <span>{VerUsuarioPorUUID[0]?.celular.br}</span></h2>
                                    <h2>Telefone: <span>{VerUsuarioPorUUID[0]?.telefone.br}</span></h2>
                                    <h2>Data de nascimento: <span>{VerUsuarioPorUUID[0]?.data_nascimento.br}</span></h2>
                                </div>
                            </S.Column>
                            <S.Column>
                                <h1>Permissões</h1>
                                <div className="titulo">
                                    <h2>Master: <span>{VerUsuarioPorUUID[0]?.master === 2 ? 'Não' : 'Sim'}</span></h2>
                                    <h2>Administrador: <span>{VerUsuarioPorUUID[0]?.administrador === 2 ? 'Não' : 'Sim'}</span></h2>
                                    <h2>Gerente: <span>{VerUsuarioPorUUID[0]?.gerente === 2 ? 'Não' : 'Sim'}</span></h2>
                                    <h2>Gestor: <span>{VerUsuarioPorUUID[0]?.gestor === 2 ? 'Não' : 'Sim'}</span></h2>
                                    <h2>Auditor: <span>{VerUsuarioPorUUID[0]?.auditor === 2 ? 'Não' : 'Sim'}</span></h2>
                                    <h2>Analista: <span>{VerUsuarioPorUUID[0]?.analista === 2 ? 'Não' : 'Sim'}</span></h2>
                                </div>
                            </S.Column>
                        </Grid>
                    </Grid>
                </DialogContent>

                {permissao.includes('per_usuario_editar') ?
                
                <DialogActions className="container_acao">
                    <ButtonGroup
                    orientation="horizontal"
                    color="primary"
                    className="buttonGroup"
                    aria-label="vertical outlined primary button group"
                    >
                    <Button
                        className="botao edit"
                        variant="contained"
                        type="button"
                        onClick={(e) => handleClickOpenEdit(e, VerUsuarioPorUUID[0]?.uuid)}
                    >
                        Editar
                    </Button>
                    </ButtonGroup>
                </DialogActions>
                : null}

            </S.Container>
        </Dialog>
    )
}