import * as S from './styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { Box, Tab, Divider } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Loading } from '../Loading/index';
import { useEstatistica } from '../../hooks/estatistica';
import Skeleton from '@material-ui/lab/Skeleton';

moment.locale('pt-br');

export function Dashboard() {
  const { dados, isLoading } = useEstatistica();
  const [tipo, setTipo] = React.useState('1');

  const handleSelect = (event, newValue) => {
    setTipo(newValue);
  };
  const data = [
    {
      name: 'Auditorias',
      Pendentes: dados.pendentes,
      Atrasadas: dados.atrasadas,
      Concluídas: dados.concluidas
    }
  ];

  const dataRisco = [
    {
      name: 'Jan',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[0] : 0
    },
    {
      name: 'Fev',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[1] : 0
    },
    {
      name: 'Mar',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[2] : 0
    },
    {
      name: 'Abr',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[3] : 0
    },
    {
      name: 'Mai',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[4] : 0
    },
    {
      name: 'Jun',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[5] : 0
    },
    {
      name: 'Jul',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[6] : 0
    },
    {
      name: 'Ago',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[7] : 0
    },
    {
      name: 'Set',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[8] : 0
    },
    {
      name: 'Out',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[9] : 0
    },
    {
      name: 'Nov',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[10] : 0
    },
    {
      name: 'Dez',
      Auditoria: dados?.risco && dados.risco.length > 0 ? dados?.risco[11] : 0
    }
  ];

  const dataEstrutural = [
    {
      name: 'Jan',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[0]
          : 0
    },
    {
      name: 'Fev',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[1]
          : 0
    },
    {
      name: 'Mar',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[2]
          : 0
    },
    {
      name: 'Abr',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[3]
          : 0
    },
    {
      name: 'Mai',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[4]
          : 0
    },
    {
      name: 'Jun',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[5]
          : 0
    },
    {
      name: 'Jul',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[6]
          : 0
    },
    {
      name: 'Ago',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[7]
          : 0
    },
    {
      name: 'Set',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[8]
          : 0
    },
    {
      name: 'Out',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[9]
          : 0
    },
    {
      name: 'Nov',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[10]
          : 0
    },
    {
      name: 'Dez',
      Auditoria:
        dados?.estrutural && dados.estrutural.length > 0
          ? dados?.estrutural[11]
          : 0
    }
  ];

  const dataConformes = [
    {
      name: 'Jan',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[0] : 0
    },
    {
      name: 'Fev',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[1] : 0
    },
    {
      name: 'Mar',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[2] : 0
    },
    {
      name: 'Abr',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[3] : 0
    },
    {
      name: 'Mai',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[4] : 0
    },
    {
      name: 'Jun',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[5] : 0
    },
    {
      name: 'Jul',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[6] : 0
    },
    {
      name: 'Ago',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[7] : 0
    },
    {
      name: 'Set',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[8] : 0
    },
    {
      name: 'Out',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0 ? dados?.conformes[9] : 0
    },
    {
      name: 'Nov',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0
          ? dados?.conformes[10]
          : 0
    },
    {
      name: 'Dez',
      Auditoria:
        dados?.conformes && dados.conformes.length > 0
          ? dados?.conformes[11]
          : 0
    }
  ];

  const dataAdequacao = [
    {
      name: 'Jan',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[0] : 0
    },
    {
      name: 'Fev',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[1] : 0
    },
    {
      name: 'Mar',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[2] : 0
    },
    {
      name: 'Abr',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[3] : 0
    },
    {
      name: 'Mai',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[4] : 0
    },
    {
      name: 'Jun',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[5] : 0
    },
    {
      name: 'Jul',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[6] : 0
    },
    {
      name: 'Ago',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[7] : 0
    },
    {
      name: 'Set',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[8] : 0
    },
    {
      name: 'Out',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0 ? dados?.adequacao[9] : 0
    },
    {
      name: 'Nov',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0
          ? dados?.adequacao[10]
          : 0
    },
    {
      name: 'Dez',
      Auditoria:
        dados?.adequacao && dados.adequacao.length > 0
          ? dados?.adequacao[11]
          : 0
    }
  ];

  const COLORS = [
    '#FF8C00',
    '#33CC95',
    '#FFBB28',
    '#F55',
    '#8A2BE2',
    '#DC143C',
    '#B8860B',
    '#C71585',
    '#D3D3D3',
    '#FFFF00',
    '#808000'
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <S.ContainerColumn>
          <S.ContainerGrafico>
            <h1 className="topo">Auditorias</h1>
            <ResponsiveContainer>
              <ComposedChart
                width={300}
                height={300}
                data={data}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" scale="band" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Divider />
                <Bar dataKey="Pendentes" barSize={20} fill="#FFBB28" />
                <Bar dataKey="Atrasadas" barSize={20} fill="#F55" />
                <Bar dataKey="Concluídas" barSize={20} fill="#33CC95" />
              </ComposedChart>
            </ResponsiveContainer>
          </S.ContainerGrafico>
          <S.ContainerRow>
            <S.ContainerGrafico>
              <h1 className="topo">Classificação</h1>
              <ResponsiveContainer>
                <PieChart width={600} height={600}>
                  <Pie
                    data={dados?.respostas_pizza?.grafico}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    nameKey="name"
                  >
                    {dados?.respostas_pizza?.grafico.map((entry, index) => {
                      return (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      );
                    })}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </S.ContainerGrafico>
            <S.ContainerGrafico>
              <table>
                <tr>
                  <td className="title">Resposta</td>
                  <td className="title">%</td>
                  <td className="title"></td>
                </tr>
                {dados?.respostas_pizza?.grafico &&
                  dados?.respostas_pizza?.grafico.map((item, index) => (
                    <tr>
                      <td>{item.name}</td>
                      <td>
                        {(
                          item?.value &&
                          (item.value / dados?.respostas_pizza?.total) * 100
                        ).toFixed(0)}
                        %
                      </td>
                      <td
                        className="cor"
                        style={{
                          backgroundColor: COLORS[index]
                        }}
                      ></td>
                    </tr>
                  ))}
              </table>
            </S.ContainerGrafico>
          </S.ContainerRow>
          <S.ContainerRow>
            <S.ContainerGrafico>
              <h1 className="topo">Conformes</h1>
              <ResponsiveContainer>
                <ComposedChart data={dataConformes}>
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="name" scale="band" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="Auditoria" barSize={20} fill="#33CC95" />
                </ComposedChart>
              </ResponsiveContainer>
            </S.ContainerGrafico>
            <S.ContainerGrafico>
              <h1 className="topo">Adequação</h1>
              <ResponsiveContainer>
                <ComposedChart data={dataAdequacao}>
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="name" scale="band" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="Auditoria" barSize={20} fill="#FFBB28" />
                </ComposedChart>
              </ResponsiveContainer>
            </S.ContainerGrafico>
          </S.ContainerRow>
          <S.ContainerRow>
            <S.ContainerGrafico>
              <h1 className="topo">Risco</h1>
              <ResponsiveContainer>
                <ComposedChart data={dataRisco}>
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="name" scale="band" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="Auditoria" barSize={20} fill="#FF8C00" />
                </ComposedChart>
              </ResponsiveContainer>
            </S.ContainerGrafico>
            <S.ContainerGrafico>
              <h1 className="topo">Falha Estrutural</h1>
              <ResponsiveContainer>
                <ComposedChart data={dataEstrutural}>
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="name" scale="band" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="Auditoria" barSize={20} fill="#F55" />
                </ComposedChart>
              </ResponsiveContainer>
            </S.ContainerGrafico>
          </S.ContainerRow>
        </S.ContainerColumn>
      )}
      <S.TabelaContainer>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tipo}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleSelect}
                aria-label="Selecione qual tabela visualizar"
                centered
              >
                <Tab label="Responsável" value="1" />
                <Tab label="Pastas" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <table>
                <thead>
                  <tr>
                    <th>Responsável</th>
                    <th>Pendentes</th>
                    <th>Atrasadas</th>
                    <th>Concluídas</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td></td>
                    </tr>
                  ) : (
                    dados.itens &&
                    dados.itens.responsaveis.map((lista) => (
                      <tr key={lista.id}>
                        <td data-label="Nome">
                          {isLoading ? <Skeleton /> : lista.nome}
                        </td>
                        <td data-label="Pententes">
                          {isLoading ? <Skeleton /> : lista.pendentes}
                        </td>
                        <td data-label="Em atraso">
                          {isLoading ? <Skeleton /> : lista.atrasadas}
                        </td>
                        <td data-label="Concluídas">
                          {isLoading ? <Skeleton /> : lista.concluidas}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </TabPanel>
            <TabPanel value="2">
              <table>
                <thead>
                  <tr>
                    <th>Pasta</th>
                    <th>Pendentes</th>
                    <th>Atrasadas</th>
                    <th>Concluídas</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td></td>
                    </tr>
                  ) : (
                    dados.itens &&
                    dados.itens.clientes.map((lista) => (
                      <tr key={lista.id}>
                        <td data-label="Nome">
                          {isLoading ? <Skeleton /> : lista.nome}
                        </td>
                        <td data-label="Abertas">
                          {isLoading ? <Skeleton /> : lista.pendentes}
                        </td>
                        <td data-label="Em atraso">
                          {isLoading ? <Skeleton /> : lista.atrasadas}
                        </td>
                        <td data-label="Concluídas">
                          {isLoading ? <Skeleton /> : lista.concluidas}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </TabPanel>
          </TabContext>
        </Box>
      </S.TabelaContainer>
    </S.Container>
  );
}
