
import React, {useState} from "react";
import {Box} from '@material-ui/core'
import { CheckListTable } from "../../components/CheckListTable/index";

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { CriaNovoCheckListModal } from "../../components/CriaNovoCheckListModal/index";
import { CheckListProvider } from "../../hooks/checklist";
import { EmpresaProvider } from "../../hooks/empresa";

export function CheckList() {
    
  const [abreModalAdd, setAbreModalAdd] = useState(false);
  
  function handleAbreModalAdd(){
      setAbreModalAdd(true);
  }

  function handleFechaAddtoModal(){
    setAbreModalAdd(false);
  }
    
    
  return (
      <CheckListProvider>
          <div style={{display: 'flex'}}>
              <Sidebar activePage={'5'} />
              <div style={{width: '100%'}}>
                <Topbar pageTitle={'Questionários'} />
                <Box className="container_admin">
                    <CheckListTable onOpenAddModal={handleAbreModalAdd}  />
                </Box>
              </div>
            <EmpresaProvider>
              {abreModalAdd ? <CriaNovoCheckListModal
                abre={abreModalAdd}
                fecha={handleFechaAddtoModal}
              /> : null}
            </EmpresaProvider>
          </div>
      </CheckListProvider>
  );

};


