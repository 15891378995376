import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';

import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar/index';
import { DashboardProvider } from '../../hooks/dashboard';
import { EstatisticaProvider } from '../../hooks/estatistica';
import { TarefaProvider } from '../../hooks/tarefa';
import { EmpresaProvider } from '../../hooks/empresa';
import { Dashboard as DashboardTarefa } from '../../components/DashboardTarefa';
import { Dashboard as DashboardVisitas } from '../../components/DashboardVisitas';
import { Dashboard as DashboardAcao } from '../../components/DashboardAcao';
import { DashboardHeader } from '../../components/DashboardHeader';

import { Container } from './styles';

const DashboardGeral = () => {
  const [tab, setTab] = useState('1');

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar activePage={'6'} />
      <div style={{ width: '100%' }}>
        <Topbar pageTitle={'Dashboard'} />
        <Box className="container_admin">
          <TarefaProvider>
            <EstatisticaProvider>
              <DashboardProvider>
                <EmpresaProvider>
                  <DashboardHeader />
                  <Container>
                    <Tabs
                      value={tab}
                      onChange={handleChangeTab}
                      centered
                      style={{ backgroundColor: 'white' }}
                    >
                      <Tab id="auditoria" value="1" label="Auditorias" />
                      <Tab id="acao" value="2" label="Tarefas" />
                    </Tabs>
                    {tab === '1' ? (
                      <>
                        <DashboardTarefa />
                        <DashboardVisitas />
                      </>
                    ) : (
                      <>
                        <DashboardAcao />
                      </>
                    )}
                  </Container>
                </EmpresaProvider>
              </DashboardProvider>
            </EstatisticaProvider>
          </TarefaProvider>
        </Box>
      </div>
    </div>
  );
};

export default DashboardGeral;
