import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { toast } from 'react-toastify';

const ClienteContext = createContext({});

export const ClienteProvider = (props) => {
  const [clienteLista, setClienteLista] = useState([]);
  const [clientePorUUID, setClientePorUUID] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [pagination, setPagination] = useState([] || '');
  const [activePage, setPageActive] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [VerClientePorUUID, setVerClientePorUUID] = useState([]);
  const [clienteAtivo, setClienteAtivo] = useState([]);

  const handleCliente = useCallback(async () => {
    setIsLoading(false);
      try {
        setIsLoading(true);
        const response = await api.get('/cliente');

        const data = [
          {
            pagina_atual: response.data.pagina_atual,
            proxima_pagina: response.data.proxima_pagina,
            pagina_anterior: response.data.pagina_anterior,
            ultima_pagina: response.data.ultima_pagina,
            total: response.data.total
          }
        ];
        setIsLoading(false);
        setPagination(data);
        setClienteLista(response.data.itens);
      } catch (err) {
        setIsLoading(false);
      }
  }, []);

  useEffect(() => {
    handleCliente();
  }, [handleCliente]);

  // Paginação
  async function clientePage(page) {
    try {
      setIsLoading(true);

      const response = await api.get('/cliente?page=' + page);

      setClienteLista(response.data.itens);
      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setIsLoading(false);
      setPagination(data);
    } catch (err) {
      setIsLoading(false);
    }
  }

  // USUÁRIOS POR UUID

  async function getClientePorUuid(uuid, page) {
    try {
      setIsLoadingEdit(true);
      const responseedit = await api.get('/cliente/' + uuid);

      setClientePorUUID([responseedit.data]);
      setPageActive(page);
      setIsLoadingEdit(false);
      setTimeout(() => {
        window.location.pathname = '/cliente/' + uuid;
      }, 1000);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingEdit(false);
    }
  }

  async function visualizaListadeClienteAtiva(quantidade = null) {
    try {
      setIsLoading(true);
      let qnt = quantidade ? `&quantidade=${quantidade}` : '&quantidade=15';
      const response = await api.get(`/cliente?status=1${qnt}`);

      setClienteAtivo(response?.data?.itens);

      setIsLoading(false);
      return {
        erro: false
      };
    } catch (err) {
      setIsLoading(true);
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoading(false);
      return {
        erro: true
      };
    }
  }

  async function VisualizarClientePorUUID(uuid) {
    try {
      setIsLoading(true);
      const responseedit = await api.get('/cliente/' + uuid);

      let nome = responseedit?.data.nome;
      setVerClientePorUUID([responseedit.data, nome]);

      setIsLoading(false);
      return {
        erro: false
      };
    } catch (err) {
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoading(false);
      return {
        erro: true
      };
    }
  }

  // Criar OS USUÁRIOS
  async function createCliente(UsuarioInputEdit) {
    try {
      setIsLoading(true);

      const response = await api.post('/cliente', UsuarioInputEdit);

      let keysToRemove = ['buscaAtivaCliente', 'salvarBuscaCliente'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));

      setIsLoading(false);
      handleCliente();

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: false
      };
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: true
      };
    }
  }

  // EDITANDO OS USUÁRIOS
  async function editCliente(UsuarioInputEdit, uuid) {
    try {
      setIsLoadingEdit(true);

      const response = await api.put('/cliente/' + uuid, UsuarioInputEdit);

      const responsepage = await api.get('/cliente');
      setClienteLista(responsepage.data.itens);
      const datapage = [
        {
          quantidade_pagina: responsepage.data.quantidade_pagina,
          pagina_atual: responsepage.data.pagina_atual,
          proxima_pagina: responsepage.data.proxima_pagina,
          pagina_anterior: responsepage.data.pagina_anterior,
          ultima_pagina: responsepage.data.ultima_pagina,
          total: responsepage.data.total
        }
      ];
      setPagination(datapage);

      setIsLoadingEdit(false);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingEdit(false);
    }
  }

  // Pesquisar Catálogo

  async function searchCliente(search) {
    try {
      setIsLoadingSearch(true);
      if (
        search.nome === '' &&
        search.status === ''
      ) {
        handleCliente();
      } else {
        const response = await api.get(
          '/cliente?nome=' +
            search.nome +
            '&status=' +
            search.status
        );

        if (response.data.error === true) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
        } else {
          localStorage.setItem('buscaAtivaCliente', 'ativa');
          localStorage.setItem('salvarBuscaCliente', JSON.stringify(search));

          setClienteLista(response.data.itens);
          const data = [
            {
              quantidade_pagina: response.data.quantidade_pagina,
              pagina_atual: response.data.pagina_atual,
              proxima_pagina: response.data.proxima_pagina,
              pagina_anterior: response.data.pagina_anterior,
              ultima_pagina: response.data.ultima_pagina,
              total: response.data.total
            }
          ];
          setPagination(data);
        }
      }

      setIsLoadingSearch(false);
    } catch (err) {
      toast.error('Nenhuma pasta encontrada!', {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }
  async function limpaCliente() {
    try {
      setIsLoadingSearch(true);

      handleCliente();
      let keysToRemove = ['buscaAtivaCliente', 'salvarBuscaCliente'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));
      window.location.reload();

      setIsLoadingSearch(false);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  async function deletarCliente(uuid) {
    try {
      setIsLoading(true);
      let response = await api.delete('/cliente/' + uuid);

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      window.location.reload();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);

      toast.success(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      handleCliente();

      window.location.reload();
      setIsLoading(false);
    }
  }

  return (
    <ClienteContext.Provider
      value={{
        isLoading,
        clienteLista,
        getClientePorUuid,
        clientePorUUID,
        editCliente,
        createCliente,
        clientePage,
        pagination,
        activePage,
        isLoadingEdit,
        searchCliente,
        limpaCliente,
        isLoadingSearch,
        VerClientePorUUID,
        VisualizarClientePorUUID,
        deletarCliente,
        visualizaListadeClienteAtiva,
        clienteAtivo
      }}
    >
      {props.children}
    </ClienteContext.Provider>
  );
};

export function useCliente() {
  const context = useContext(ClienteContext);

  return context;
}
