import React from "react";
import {Box} from '@material-ui/core'
import { FormEditaChecklist } from "../../components/FormEditaChecklist/";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { CheckListProvider } from "../../hooks/checklist";
import { ItemRespostasProvider } from "../../hooks/itemRespostas";
import { GrupoProvider } from "../../hooks/grupo";

import { EmpresaProvider } from "../../hooks/empresa";

export function EditChecklist() {
    
  return (
    <CheckListProvider>
      <ItemRespostasProvider>
        <GrupoProvider>
          <EmpresaProvider>
            <div style={{display: 'flex'}}>
                <Sidebar activePage={'5'} />
                <div style={{width: '100%'}}>
                    <Topbar pageTitle={'Editar questionário'} />
                    <Box  className="container_admin">
                        <FormEditaChecklist />
                    </Box>
                </div>
            </div>
          </EmpresaProvider>
        </GrupoProvider>
      </ItemRespostasProvider>
    </CheckListProvider>
  );
};


