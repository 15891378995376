import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { toast } from 'react-toastify';

const EstatisticaContext = createContext({});

export const EstatisticaProvider = (props) => {
  const [dados, setDados] = useState([]);
  const [dadosAcao, setDadosAcao] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDashboardTarefa = useCallback(async () => {
    const buscaAtiva = localStorage.getItem('salvarBuscaDashboardTarefa');

    if (buscaAtiva && buscaAtiva !== '') {
      let search = JSON.parse(buscaAtiva);
      try {
        const response = await api.get(
          '/tarefa/estatistica?data_inicio=' +
            search.dataInicial +
            '&data_final=' +
            search.dataFinal
        );
        setDados(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      try {
        const response = await api.get('/tarefa/estatistica');
        setDados(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
  }, []);

  const handleDashboardAcao = useCallback(async () => {
    const buscaAtiva = localStorage.getItem('salvarBuscaDashboardAcao');

    if (buscaAtiva && buscaAtiva !== '') {
      try {
        let search = JSON.parse(buscaAtiva);
        const response = await api.get(
          '/acao/estatistica?data_inicio=' +
            search.dataInicial +
            '&data_final=' +
            search.dataFinal
        );
        setDadosAcao(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      try {
        const response = await api.get('/acao/estatistica');
        setDadosAcao(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    handleDashboardTarefa();
    handleDashboardAcao();
  }, [handleDashboardTarefa]);

  async function searchEstatistica(search, fecha) {
    try {
      setIsLoading(true);

      const response = await api.get(
        '/tarefa/estatistica?data_inicio=' +
          search.dataInicial +
          '&data_final=' +
          search.dataFinal +
          '&empresa=' +
          search.empresa
      );
      setDados(response.data);

      localStorage.setItem('buscaAtivaTarefaDashboard', 'ativa');
      localStorage.setItem(
        'salvarBuscaDashboardTarefa',
        JSON.stringify(search)
      );
      setIsLoading(false);
      fecha();
      return {
        erro: false
      };
    } catch (err) {
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoading(false);
      return {
        erro: true
      };
    }
  }

  async function searchEstatisticaAcao(search, fecha) {
    try {
      setIsLoading(true);

      const response = await api.get(
        '/acao/estatistica?data_inicio=' +
          search.dataInicial +
          '&data_final=' +
          search.dataFinal +
          '&empresa=' +
          search.empresa
      );
      setDadosAcao(response.data);

      localStorage.setItem('buscaAtivaTarefaDashboard', 'ativa');
      localStorage.setItem('salvarBuscaDashboardAcao', JSON.stringify(search));
      setIsLoading(false);
      fecha();
      return {
        erro: false
      };
    } catch (err) {
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoading(false);
      return {
        erro: true
      };
    }
  }

  async function limpaTarefaDashboard() {
    try {
      setIsLoading(true);

      handleDashboardTarefa();
      let keysToRemove = [
        'buscaAtivaTarefaDashboard',
        'salvarBuscaDashboardTarefa',
        'salvarBuscaDashboardAcao'
      ];

      keysToRemove.forEach((k) => localStorage.removeItem(k));
      window.location.reload();

      setIsLoading(false);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      setIsLoading(false); //pegando o response da requisição da api
    }
  }

  return (
    <EstatisticaContext.Provider
      value={{
        isLoading,
        dados,
        searchEstatistica,
        limpaTarefaDashboard,
        dadosAcao,
        searchEstatisticaAcao
      }}
    >
      {props.children}
    </EstatisticaContext.Provider>
  );
};

export function useEstatistica() {
  const context = useContext(EstatisticaContext);

  return context;
}
