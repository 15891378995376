import React  from 'react';
import * as S from "./styles";
import { DialogTitle,  Dialog, ListItem, List, ListItemText, IconButton, ListItemAvatar, Avatar, Typography, Divider } from "@material-ui/core";
import { useHistorico } from "../../hooks/historico"
import CloseIcon from "@material-ui/icons/Close";

export function ListaHistoricoComponent({fecha, abre}){
    const { historicoPorUUID } = useHistorico();

    return (

        <Dialog open={abre} close={fecha} fullWidth sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }} maxWidth="xs">
            <S.Container>
                <DialogTitle className="titulo">
                    Históricos
                    <IconButton className="fecha" onClick={fecha}>
                    <CloseIcon />
                    </IconButton>
                </DialogTitle> 
                <List dense={true} style={{width: '90%'}}>
                    {historicoPorUUID ? 
                    historicoPorUUID.map(lista => (
                        <>
                        <ListItem alignItems="flex-start" key={lista.uuid}>
                            <ListItemAvatar>
                            <Avatar alt={lista.usuario.nome} src={lista.usuario.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                            primary={lista.texto}
                            secondary={
                                <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                {" por "+lista.usuario.nome}
                                </Typography>
                                </React.Fragment>
                            }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </>
                    ))  : null}
                    
                </List>
            </S.Container>
        </Dialog>
    )


}