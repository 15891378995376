import React from 'react';

import { isAuthenticated } from '../services/auth';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';

import Login from '../pages/Login';
import Home from '../pages/Home';
import NovaSenha from '../pages/NovaSenha';
import DashboardTarefa from '../pages/DashboardTarefa';
import DashboardGeral from '../pages/DashboardGeral';
import DashboardVisitas from '../pages/DashboardVisitas';
import { Perfil } from '../pages/Perfil/index';
import { Usuario } from '../pages/Usuario/index';
import { NewUser } from '../pages/NewUser/index';
import { EditUser } from '../pages/EditUser/index';
import { Cliente } from '../pages/Cliente/index';
import { NewCliente } from '../pages/NewCliente/index';
import { EditCliente } from '../pages/EditCliente/index';
import { VisualizarCliente } from '../pages/VisualizarCliente/index';
import { Tarefa } from '../pages/Tarefa/index';
import { Acao } from '../pages/Acao';
import { EditAcao } from '../pages/EditAcao';
import { NewAcao } from '../pages/NewAcao';
import { MinhasTarefas } from '../pages/MinhasTarefas/index';
import { NewTarefa } from '../pages/NewTarefa/index';
import { EditTarefa } from '../pages/EditTarefa/index';
import { VisualizarTarefa } from '../pages/VisualizarTarefa/index';
import { CheckList } from '../pages/CheckList/index';
import { EditChecklist } from '../pages/EditChecklist/index';
import { CheckListTarefa } from '../pages/CheckListTarefa/index';
import { DetalheResposta } from '../pages/DetalheResposta/index';
import { DetalheGrupo } from '../pages/DetalheGrupo/index';
import { Agenda } from '../pages/Agenda/index';
import { NotFound } from '../pages/NotFound/index';
import { SemPermissao } from '../pages/SemPermissao/index';
import { Origem } from '../pages/Origem/index';
import { ItemRespostas } from '../pages/ItemRespostas/index';
import { Empresa } from '../pages/Empresa/index';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

const master = Number(localStorage.getItem('MMPROWORK'));
const permissao = JSON.parse(localStorage.getItem('PERMISSAO_USER_EPROWORK')) ?? [];

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/nova-senha/:token" component={NovaSenha} />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/perfil" component={Perfil} />

      {/* Dashboard */}
      {permissao.includes('per_dashboard_visualizar') && <PrivateRoute exact path="/dashboard" component={() => <DashboardGeral />} />}      
      {permissao.includes('per_dashboard_visualizar') && <PrivateRoute exact path="/dashboard/visitas" component={() => <DashboardGeral />} />}      
      {permissao.includes('per_dashboard_visualizar') && <PrivateRoute exact path="/dashboard-checklist" component={() => <DashboardGeral />} />}

      {/* Usuario */}
      {permissao.includes('per_usuario_visualizar') && <PrivateRoute exact path="/usuario" component={() => <Usuario />} />}
      {permissao.includes('per_usuario_add') && <PrivateRoute exact path="/usuario/novo" component={() => <NewUser />} />}
      {permissao.includes('per_usuario_editar') && <PrivateRoute exact path="/usuario/:id" component={() => <EditUser />} />}

      {/* Pasta */}
      {permissao.includes('per_pasta_visualizar') && <PrivateRoute exact path="/pasta" component={() => <Cliente />} />}
      {permissao.includes('per_pasta_add') && <PrivateRoute exact path="/pasta/novo" component={() => <NewCliente />} />}
      {permissao.includes('per_pasta_visualizar') && <PrivateRoute exact path="/pasta/:id" component={() => <EditCliente />} />}
      {permissao.includes('per_pasta_visualizar') && <PrivateRoute exact path="/pasta/visualiza/:id" component={() => <VisualizarCliente />} />}

      {/* Auditorias */}
      {permissao.includes('per_auditoria_visualizar') && <PrivateRoute exact path="/checklist" component={() => <Tarefa />} />}
      {permissao.includes('per_auditoria_visualizar') && <PrivateRoute exact path="/checklist/:id" component={() => <EditTarefa />} />}
      {permissao.includes('per_auditoria_visualizar') && <PrivateRoute exact path="/checklist/visualiza/:id" component={() => <VisualizarTarefa />} />}
      {(permissao.includes('per_auditoria_add')) &&  <PrivateRoute exact path="/checklist/novo/:id" component={() => <NewTarefa />} />}

      {/* Tarefa */}
      {permissao.includes('per_tarefa_visualizar') && <PrivateRoute exact path="/tarefa" component={() => <Acao />} />}
      {permissao.includes('per_tarefa_add') && <PrivateRoute exact path="/tarefa/nova" component={() => <NewAcao />} />}
      {permissao.includes('per_tarefa_visualizar') && <PrivateRoute exact path="/tarefa/:id" component={() => <EditAcao />} />}

      {/* Agenda */}
      {(permissao.includes('per_agenda_visualizar')) && <PrivateRoute exact path="/agenda" component={() => <Agenda />} />}

      {/* Origem */}
      {permissao.includes('per_origem_visualizar') && <PrivateRoute exact path="/origem" component={() => <Origem />} />}     

      {/* Gerenciar questionarios */}
      {permissao.includes('per_gerenciar_questionario_visualizar') && <PrivateRoute exact path="/questionario" component={() => <CheckList />} />}
      {permissao.includes('per_gerenciar_questionario_visualizar') && <PrivateRoute exact path="/questionario/:id" component={() => <EditChecklist />} />}
      {permissao.includes('per_gerenciar_questionario_add') && <PrivateRoute exact path="/questionario-respostas/:id" component={() => <DetalheResposta />} />}
      {permissao.includes('per_gerenciar_questionario_add') && <PrivateRoute exact path="/grupo/:id/:checklist" component={() => <DetalheGrupo />} />}

      {/* Cadastrar Respostas */}
      {permissao.includes('per_cadastrar_respostas_visualizar') && <PrivateRoute exact path="/item-respostas" component={() => <ItemRespostas />} />}



      {permissao.includes('per_gerenciar_questionario_visualizar') && <PrivateRoute exact path="/minhas-checklists" component={() => <MinhasTarefas />} />}
      <PrivateRoute exact path="/minhas-checklists/:id" component={() => <EditTarefa />} />
      <PrivateRoute exact path="/checklist/questionario/:id" component={() => <CheckListTarefa />} />

      {/* Empresas */}
      {master === 1 && <PrivateRoute exact path="/empresas" component={() => <Empresa />} />}

      {/* Sem permissao */}
      <PrivateRoute exact path="/sem-permissao" component={SemPermissao} />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
