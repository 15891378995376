import * as S from "./styles";
import { useCallback } from 'react';
import { useTag } from "../../hooks/tag"
import { Dialog, DialogContent, DialogTitle, DialogActions, Button,Paper, FormLabel } from "@material-ui/core";



import Draggable from 'react-draggable';
function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}


export function DeletaTagModal({ abre, fecha, uuid, uuidAuditoria}) {

    const { DeletaTagPorUUID, VisualizarTagPorUUID } = useTag();


    const handleListaTag = useCallback(async () => {
        await VisualizarTagPorUUID(uuidAuditoria);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuidAuditoria]);

    
    const handleDeletaTag = async (e) => {
        e.preventDefault();

        let result = await DeletaTagPorUUID(uuid);

         if(result.erro === false) {
            
            fecha();
            // eslint-disable-next-line react-hooks/rules-of-hooks
            handleListaTag();
        }
    };
 
    return (
         <Dialog
            open={abre}
            onClose={fecha}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            className="modal_dialog"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
             <p> Deletar Tag</p>
            </DialogTitle>
            <DialogContent>
                <S.FormQrCode onSubmit={handleDeletaTag}> 
                    <S.Column>

                    <FormLabel id="demo-row-radio-buttons-group-label">Tem certeza que deseja deletar essa tag?</FormLabel>
                    </S.Column>

                    <DialogActions>
                        <Button autoFocus onClick={fecha} color="primary">
                            Cancelar
                        </Button>
                        <Button
                            className="botao deleta"
                            variant="contained"
                            type="submit"
                        >
                        Deletar
                        </Button>
                    </DialogActions>
                </S.FormQrCode>
            </DialogContent>
        </Dialog>

    )
}
