import React from "react";
import {Box} from '@material-ui/core'

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import {Dashboard} from "../../components/DashboardTarefa";
import { EstatisticaProvider } from "../../hooks/estatistica";

const DashboardTarefa = () => {
  return (
      <div style={{display: 'flex'}}>
          <Sidebar activePage={'4'} />
          <div  style={{width: '100%'}}>
            <EstatisticaProvider>
              <Topbar pageTitle={'Dashboard - Checklist'} />
              <Box className="container_admin">
                <Dashboard />
              </Box>
            </EstatisticaProvider>
          </div>
      </div>
  );
};

export default DashboardTarefa;

