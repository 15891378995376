import api from "../services/api";
import {createContext, useState, useContext, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

const EmpresaContext = createContext({});

export const EmpresaProvider = (props) =>  {
    const [empresaLista, setEmpresaLista] = useState([]);
    const [empresaListaAtiva, setEmpresaListaAtiva] = useState([]);
    const [empresaPorUUID, setEmpresaPorUUID] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [pagination, setPagination] = useState([] || '');
    const [activePage, setPageActive] = useState('');
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);


    const handleEmpresa = useCallback(async () => {
        
        const buscaAtiva = localStorage.getItem('salvarBuscaEmpresa');

        if(buscaAtiva && buscaAtiva !== ''){

            try {
                setIsLoading(true); 
                let search = JSON.parse(buscaAtiva);
                const response = await api.get('/empresa?respostas='+search.respostas+'&status='+search.status); 
                setIsLoading(true);
                
                const data = [{
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                    }
                ];
                setPagination(data);
                setEmpresaLista(response.data.itens);
                setIsLoading(false); 

            } catch (err) {
                setIsLoading(false); 
            }
        }else{

            try {
                setIsLoading(true);
                const response = await api.get('/empresa');
                
                const data = [{
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                    }
                ];
                setPagination(data);
                setEmpresaLista(response.data.itens);
                setIsLoading(false); 

            } catch (err) {
                setIsLoading(false); 
            }
        }
      }, []);

    useEffect(() => {
        if(window.location.pathname === '/empresas'){
            handleEmpresa();
        }
    }, [handleEmpresa]);


    const handleEmpresaAtiva = useCallback(async () => {
        
        try {
            setIsLoading(true);
            const response = await api.get('/empresa?status=1&quantidade=1000');
            
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setPagination(data);
            setEmpresaListaAtiva(response.data.itens);
            setIsLoading(false); 

        } catch (err) {
            setIsLoading(false); 
        }
      }, []);

    useEffect(() => {
        if(window.location.pathname !== '/empresas'){
            handleEmpresaAtiva();
        }
    }, [handleEmpresaAtiva]);

         
    // Paginação
    async function empresaPage(page) {
        try {
            setIsLoading(true);
            
            const response = await api.get('/empresa?page='+page);             

            setEmpresaLista(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setIsLoading(false);
            setPagination(data);

        } catch (err) {
            setIsLoading(false); 
        }
    }

    // USUÁRIOS POR UUID

    async function getEmpresaPorUuid(uuid,page) {
        try {
            setIsLoadingEdit(true);
            const responseedit = await api.get('/empresa/'+uuid); 

            setEmpresaPorUUID([responseedit.data]);
            setPageActive(page);
            setIsLoadingEdit(false);
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false);
        }
    }

    // Criar OS USUÁRIOS
    async function criaEmpresa(UsuarioInputEdit, modal) {
        try {
            setIsLoading(true);

            const response = await api.post('/empresa', UsuarioInputEdit); 

            let keysToRemove = ["buscaAtivaEmpresa", "salvarBuscaEmpresa"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));

            setIsLoading(false);
            handleEmpresa();
            modal();

            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            return {
                erro: false
            };
        } catch (err) {
            setIsLoading(false); 
             toast.error(err.response.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
            return {
                erro: true
            };
        }
    } 

    
    // EDITANDO OS USUÁRIOS
    async function editaEmpresa(UsuarioInputEdit, uuid, fecha) {
        try {

            setIsLoadingEdit(true);
            const response = await api.put('/empresa/'+uuid, UsuarioInputEdit); 

            const responsepage = await api.get('/empresa');    
            
            setEmpresaLista(responsepage.data.itens);
            const datapage = [{
                    'quantidade_pagina' : responsepage.data.quantidade_pagina,
                    'pagina_atual' : responsepage.data.pagina_atual,
                    'proxima_pagina' : responsepage.data.proxima_pagina,
                    'pagina_anterior' : responsepage.data.pagina_anterior,
                    'ultima_pagina' : responsepage.data.ultima_pagina,
                    'total' : responsepage.data.total
                }
            ];
            setPagination(datapage);
            fecha();

            setIsLoadingEdit(false);
            toast.success(response?.data?.message, {
                position: toast.POSITION.TOP_CENTER
            });

        } catch (err) {
            toast.error(err.response?.data?.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false); 
        }
    } 

    
    // Pesquisar Catálogo
    async function searchEmpresa(search, Modal) {
        try {
            setIsLoadingSearch(true);
            
            if(search.nome === '' && search.status === ''){
                handleEmpresa();
            } else {
                 const response = await api.get('/empresa?nome='+search.nome+'&status='+search.status); 

                 if(response.data.error === true ){
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                 }else {
                    localStorage.setItem('buscaAtivaEmpresa', 'ativa');
                    localStorage.setItem('salvarBuscaEmpresa', JSON.stringify(search));
                    
                    setEmpresaLista(response.data.itens);
                    const data = [{
                        'quantidade_pagina' : response.data.quantidade_pagina,
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                        }
                    ];
                    setPagination(data);
                 }
            }
            
            setIsLoadingSearch(false);
            Modal();
            

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }
    async function limpaEmpresa() {
        try {
            setIsLoadingSearch(true);
            
            handleEmpresa();
            let keysToRemove = ["buscaAtivaEmpresa", "salvarBuscaEmpresa"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                handleEmpresa();

            setIsLoadingSearch(false);

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }

    
    
    async function deletarEmpresa(uuid) {
        try {
            setIsLoading(true);
            let response = await api.delete('/empresa/'+uuid);     
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            
            handleEmpresa();

            setIsLoading(false); 

            setTimeout(function() {
                window.location.reload();
            },1000);

        } catch (err) {
            setIsLoading(true);

            toast.success(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });

            handleEmpresa();
            setIsLoading(false); 
        }
    }
 

    return (
        <EmpresaContext.Provider value={{isLoading, empresaLista, getEmpresaPorUuid, empresaPorUUID, editaEmpresa, criaEmpresa, empresaPage, pagination, activePage, isLoadingEdit, searchEmpresa, limpaEmpresa, isLoadingSearch, deletarEmpresa, empresaListaAtiva}}>  
            {props.children}
        </EmpresaContext.Provider>
    )

}



export function useEmpresa() {
    const context = useContext(EmpresaContext);

    return context;
}
