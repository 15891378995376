import styled from 'styled-components'

export const Container = styled.div `
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width:890px) {
        flex-direction:column-reverse;
    }
`