import api from "../services/api";
import moment from 'moment';
import 'moment/locale/pt-br';
import { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

const DashboardContext = createContext({});
moment.locale('pt-br');

export const DashboardProvider = (props) => {

    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();

    const [dados, setDados] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dataInicial, setDataInicio] = useState('01/' + mes + '/' + ano);
    const [dataFinal, setDataFinal] = useState(dia + '/' + mes + '/' + ano);



    const handleGeral = useCallback(async () => {
        try {
            setIsLoading(true);
            let data_inicial = moment(dataInicial, "DD-MM-YYYY").format('YYYY-M-DD');
            let data_final = moment(dataFinal, "DD-MM-YYYY").format('YYYY-M-DD');

            const response = await api.get('/dashboard?data_inicio=' + data_inicial + '&data_final=' + data_final);
        

            setDados(response.data);
            setIsLoading(false);

        } catch (err) {
            setIsLoading(false); 
        }
    }, []);

    useEffect(() => {
        handleGeral();
    }, [handleGeral]);

    
    
    async function filtraLista(dadosForm) {
        try {
            setIsLoading(true);

            const response = await api.get('/dashboard?data_inicio='+dadosForm.dataInicial+'&data_final='+dadosForm.dataFinal);
            setDados(response.data);

            setIsLoading(false);
            
            return {
                erro: false
            }
        } catch (err) {
            setIsLoading(true);
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoading(false);
            return {
                erro: true,
            }
        }
    }
    
    async function downloadLista(dadosForm, modal){

        try {
            setIsLoading(true);
            
            let response = await api.get('/dashboard/estatistica?data_inicio='+dadosForm.dataInicial+'&data_final='+dadosForm.dataFinal, {responseType: 'blob'});   
            var date = new Date();
            var formattedDate = moment(date).format('YYYYMMDD');
            let filename = 'relatorio_download_'+formattedDate;
        
            const url = window.URL.createObjectURL(new Blob([response.data],
                { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            setIsLoading(false); 
           

        } catch (err) {
            setIsLoading(true);   
            toast.error('Erro!', {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoading(false); 
        }

    }


    return (
        <DashboardContext.Provider value={{ isLoading, dados, filtraLista, downloadLista }}>
            {props.children}
        </DashboardContext.Provider>
    )

}



export function useDashboard() {
    const context = useContext(DashboardContext);

    return context;
}
