import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import * as S from "./styles";
import { useTarefa } from "../../hooks/tarefa"
import { useCliente } from "../../hooks/cliente"
import { useHistorico } from "../../hooks/historico"
import {  Card, CardContent, Paper,  Box, Typography, Tabs, Tab, ListItem, List, ListItemText,ListItemAvatar, Avatar, Divider,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from "@material-ui/core";
import api from "../../services/api";
import { toast } from 'react-toastify';
import { Loading } from "../Loading/index";
import Pagination from '@material-ui/lab/Pagination';



import {
    useParams,
    useHistory
} from "react-router-dom";


import Draggable from 'react-draggable';
// import { TrendingUpRounded } from "@material-ui/icons";

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}


export function VisualizaCliente() {
    
    let history = useHistory();
    const { editCliente, isLoading, VerClientePorUUID } = useCliente();
    const { VerTarefaPorCliente, VisualizarTarefaPorCliente,  verImagemTarefa, deletarTarefa,  paginationClienteChecklist, tarefaPageCliente } = useTarefa();

    const { id } = useParams();

    const { visualizarHistoricoPorUUID, historicoCliente, visualizarHistoricoPorCliente } = useHistorico();
    const [openConfirm, setOpenConfirmarDeletar] = useState(false);
    


    const handleTarefa = useCallback(async () => { 
        if(id !== ''){
            await VisualizarTarefaPorCliente(id);
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleTarefa();
    }, [handleTarefa]);

    const handleHistoricoCliente = useCallback(async () => {
        if(id !== ''){
         await visualizarHistoricoPorCliente(id);
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleHistoricoCliente();
    }, [handleHistoricoCliente]);

    const handleImagem = useCallback(async () => {

        if(id !== ''){
         await verImagemTarefa(id);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleImagem();
    }, [handleImagem]);

    const handleHistorico = useCallback(async () => {
        await visualizarHistoricoPorUUID('');

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleHistorico();
    }, [handleHistorico]);




    const [nome, setNome] = useState(VerClientePorUUID[0]?.nome || '');
    const [status, setStatus] = useState(VerClientePorUUID[0]?.status || '');
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        api.get("/cliente/"+id)
            .then(({ data }) => {
                setLoading(false);
                setNome(data.nome || "");
                setStatus(data?.status || "");
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response?.data.message, {
                    position: toast.POSITION.TOP_CENTER
                }); 
    
            })
            .finally((response) => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);





    const handlEditCliente = async event => {
        event.preventDefault();

        let data = {
            nome,
            status,
        }

        await editCliente(data, id);
        
        
    }
    
    const permissao = JSON.parse(localStorage.getItem('PERMISSAO_USER_EPROWORK')) ?? []; 

    const [tab, setTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    }; 



        /* Actions */
    const [nomeTarefa, setNomeTarefa] = useState('');
    const [uuidTarefa, setUuidTarefa] = useState('');
    const [recorrenteTarefa, setRecorrente] = useState('');

    const handleClickOpenConfirm = async (nome, uuid, recorrente) => {
        setOpenConfirmarDeletar(true);
        setNomeTarefa(nome);
        setUuidTarefa(uuid);
        setRecorrente(recorrente);
    };

    const handleCloseConfirm = async () => {
        setOpenConfirmarDeletar(false);
        setNomeTarefa('');
        setUuidTarefa('');
        setRecorrente('');
    };
    

    const handleDeletaConfirm = async (uuid, recorrente) => {
        setOpenConfirmarDeletar(false);
    
       let result =  await deletarTarefa(uuid, recorrente);
        
        if(result.erro === false){
            setNomeTarefa('');
            setUuidTarefa('');
            setRecorrente('');

        }
    };

    const handleSelect = async (event, value) => {
        await tarefaPageCliente(value);
    }
    
    const handleClickOpenEdit = async (uuid) => {
        if(permissao.includes('per_auditoria_editar')) {
            history.push("/checklist/"+uuid);
        } else if(permissao.includes('per_auditoria_visualizar')) {
            history.push("/checklist/visualiza/"+uuid);
        }
    };
  
    return (
        <Box sx={{ width: '100%'}}>
            <Tabs value={tab} onChange={handleChangeTab} centered style={{ backgroundColor: 'white' }}>
                {permissao.includes('per_pasta_visualizar') && <Tab label="Dados da pasta" />}
                {permissao.includes('per_auditoria_visualizar') && <Tab label="Auditoria" />}
                {permissao.includes('per_historico_visualizar') && <Tab label="Históricos" />}
            </Tabs>

        {tab === 0 && permissao.includes('per_pasta_visualizar') ?
            /* Dados da pasta */
            <S.Container onSubmit={handlEditCliente}>
                {loading ? 
                    <Loading />
                :
                <>
                    <S.ContainerCardForm>
                        <div className="card-form">
                            <Card className="item">
                                <CardContent>
                                    <S.TituloTopo>Dados</S.TituloTopo>
                                    <S.Column>
                                        <div className="titulo">
                                            <p>Nome: <span>{nome}</span></p>
                                        </div>
                                    </S.Column>
                                    
                                    <S.TituloTopo style={{marginTop: '10px'}}>Acesso</S.TituloTopo>
                                    <S.Column>
                                        <div className="titulo">
                                            <p>Status: <span>{status === '1' ? 'Ativo' : 'Desativado'}</span></p>
                                        </div>
                                    </S.Column>
                                </CardContent>
                            </Card>
                        
                        </div>
                    </S.ContainerCardForm>
                </>
                }

            </S.Container>
        : (tab === 1 && permissao.includes('per_auditoria_visualizar'))
        ?
            /* Checklists */
            <S.ContainerChecklist>
                {isLoading ? 
                    <Loading />
                : 
                    <>
                        <table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Status</th>
                                    {permissao.includes('per_auditoria_deletar') && <th className="acao">Ações</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {VerTarefaPorCliente[0] && VerTarefaPorCliente[0].map(listaCliente =>  (
                                    <tr
                                        key={listaCliente.uuid}
                                    >
                                        <td 
                                        onClick={() => handleClickOpenEdit(listaCliente.uuid,paginationClienteChecklist[0].pagina_atual)}>{listaCliente.acao}</td>
                                        <td 
                                        onClick={() => handleClickOpenEdit(listaCliente.uuid,paginationClienteChecklist[0].pagina_atual)}>
                                            {listaCliente.status_texto}
                                        </td>
                                        {permissao.includes('per_auditoria_deletar') && 
                                            <td className="deletar"  onClick={() => handleClickOpenConfirm(listaCliente.acao, listaCliente.uuid, listaCliente?.recorrente?.uuid)} >
                                                <p
                                                >
                                                    Deletar
                                                </p>
                                            </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <S.ContainerPagination>
                            {paginationClienteChecklist.length ?
                            <Pagination
                                showLastButton={true}
                                showFirstButton={true}
                                color="primary"
                                count={paginationClienteChecklist[0].ultima_pagina}
                                page={Number(paginationClienteChecklist[0].pagina_atual)}
                                onChange={handleSelect}
                            />
                            : null}
                        </S.ContainerPagination>
                    </>
                }
            
                    <Dialog
                    open={openConfirm}
                    onClose={handleCloseConfirm}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    className="modal_dialog"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Deletar
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                            Tem certeza que deseja deletar a tarefa {nomeTarefa || ''}, essa ação não poderá ser desfeita
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button autoFocus onClick={() => handleCloseConfirm()} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={() => handleDeletaConfirm(uuidTarefa, recorrenteTarefa)} color="secondary">
                            Deletar
                        </Button>
                        </DialogActions>
                    </Dialog> 
            </S.ContainerChecklist>

        : (tab === 2 && permissao.includes('per_historico_visualizar'))
        ?
            /* HISTÓRICO */
            <>
            <S.Historico>
                <div>
                    <h2>Históricos</h2>
                </div>
                <div className="card_historico">
                {historicoCliente.length > 0 ? 
                    <List dense={true} style={{width: '90%'}}>
                        {historicoCliente && historicoCliente.map(lista => (
                        <>
                        <ListItem alignItems="flex-start" key={lista.uuid}>
                            <ListItemAvatar>
                            <Avatar alt={lista.usuario.nome} src={lista.usuario.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                            primary={lista.texto}
                            secondary={
                                <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                {" por "+lista.usuario.nome}
                                </Typography>
                                </React.Fragment>
                            }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </>
                        ))}
                    </List>
                : 
                    <div className="zero">
                        <p>Sem históricos no momento</p>
                    </div>
                }
                </div>
          
            </S.Historico>
            </>
        : null }
        </Box>
       
    );
}







