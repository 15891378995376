import { useState } from 'react';
import * as S from "./styles";
import Skeleton from "react-loading-skeleton";
import { useCliente } from "../../hooks/cliente";
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Paper, Divider } from '@material-ui/core';
import Draggable from 'react-draggable';
import { Loading } from "../Loading/index";
import { SearchClienteModal } from './search';

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export function ClienteTable() {
    let history = useHistory();
    const { clienteLista, clientePage, isLoading, pagination, deletarCliente } = useCliente();
    const permissao = JSON.parse(localStorage.getItem('PERMISSAO_USER_EPROWORK')) ?? [];

    const [openConfirm, setOpenConfirmarDeletar] = useState(false);
    const [nomeCliente, setNomeCliente] = useState('');
    const [uuidCliente, setUuidCliente] = useState('');
    const [busca, setBusca] = useState(false);
  
    const handleSelect = async (event, value) => {
        await clientePage(value);
    }
    
    const handleClickOpenEdit = async (uuid) => {
        if(permissao.includes('per_pasta_editar')) {
            history.push("/pasta/"+uuid);
        }else if(permissao.includes('per_pasta_visualizar')) {
            history.push("/pasta/visualiza/"+uuid);
        }
    };


    function onOpenNewUserModal() {
        history.push("/pasta/novo");
    }
  
   

    const handleClickOpenConfirm = async (nome, uuid) => {
        setOpenConfirmarDeletar(true);
        setNomeCliente(nome);
        setUuidCliente(uuid);
    };

    const handleCloseConfirm = async () => {
        setOpenConfirmarDeletar(false);
        setNomeCliente('');
        setUuidCliente('');
    };
    

    const handleDeletaConfirm = async (uuid) => {
        setOpenConfirmarDeletar(false);
        await deletarCliente(uuid);
        setNomeCliente('');
        setUuidCliente('');
    };
    

    return (
        <>
        {isLoading ? 
            <Loading />
        :
        <S.Container>
            {permissao.includes('per_pasta_add')  ?
            <button type="button" onClick={onOpenNewUserModal}>
            Nova Pasta
            </button>
            : null}
            <button style={{marginLeft: '10px'}} type="button" className='busca' onClick={() => setBusca(true)}>
                Buscar Pasta
            </button>
            <div className="totalItens"><h1>Total de registros: {pagination[0]?.total}</h1></div>
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Data de criação</th>
                        <th>Empresa</th>
                        <th className="status">Status</th>
                        {permissao.includes('per_pasta_deletar') && <th className="acao">Ações</th>}
                    </tr>
                </thead>
                <tbody>
                    { clienteLista ? 
                     clienteLista.map(lista =>  (
                        <tr
                            key={lista.uuid}
                        >
                            <td
                            onClick={() => handleClickOpenEdit(lista.uuid,pagination[0].pagina_atual)} data-label="Nome">{isLoading ? <Skeleton /> : lista.nome}</td>
                            <td
                            onClick={() => handleClickOpenEdit(lista.uuid,pagination[0].pagina_atual)} data-label="Data de criação">
                                {isLoading ? <Skeleton /> : lista.data_criacao?.br}
                            </td>
                            <td
                            onClick={() => handleClickOpenEdit(lista.uuid,pagination[0].pagina_atual)} data-label="Empresa">
                                {isLoading ? <Skeleton /> : lista.empresa_nome}
                            </td>
                            <td className={lista.status === 1 ? 'ativo' : 'inativo'} 
                            onClick={() => handleClickOpenEdit(lista.uuid,pagination[0].pagina_atual)} data-label="Status">
                                {isLoading ? <Skeleton /> : <div></div>}
                            </td>
                            {permissao.includes('per_pasta_deletar')  ?
                            <td className="deletar" 
                            onClick={() => handleClickOpenConfirm(lista.nome, lista.uuid)} data-label="Ações">
                                <p
                                >
                                    Deletar
                                </p>
                            </td>
                            : null}
                        </tr>
                    ))
                    : 
                        <tr>
                            <td></td>
                        </tr>
                    }
                </tbody>
            </table>
            <S.ContainerPagination>
                {pagination.length ?
                <Pagination
                    showLastButton={true}
                    showFirstButton={true}
                    color="primary"
                    count={pagination[0].ultima_pagina}
                    page={Number(pagination[0].pagina_atual)}
                    onChange={handleSelect}
                />
                : null}
            </S.ContainerPagination>
            
            <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Deletar
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Tem certeza que deseja deletar o cliente {nomeCliente || ''}, essa ação não poderá ser desfeita
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={() => handleCloseConfirm()} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => handleDeletaConfirm(uuidCliente || '')} color="secondary">
                    Deletar
                </Button>
                </DialogActions>
            </Dialog> 
        </S.Container>
        }
        <SearchClienteModal setBusca={setBusca} busca={busca} uuid={uuidCliente} />
        </>
    );
}
