import React from 'react'
import Routes from './routes/routes';
import "./styles/style.css";
import "./styles/custom-theme.less";
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify';
import { logout } from "./services/auth";

window.addEventListener("storage", function () {
    // logout();
}, false);

export default function App() {
  return (
       <React.Fragment>
          <CssBaseline />
          <ToastContainer />
          <Routes/>
         {/* O resto da sua aplicação */}
       </React.Fragment>
  )
}