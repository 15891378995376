import api from "../services/api";
import { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

const PerfilContext = createContext({});

export const PerfilProvider = (props) => {
    const [tipoPerfil, setTipoPerfil] = useState("");
    const [dados, setDados] = useState();
    const [isLoading, setIsLoading] = useState(true);


    const handleUsuario = useCallback(async () => {
        setIsLoading(true);
        const response = await api.get('/auth/usuario');

        setDados(response.data);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        handleUsuario();
    }, [handleUsuario]);


    const handleUsuarioTipo = useCallback(async () => {
        setIsLoading(true);
        const response = await api.get('/auth/usuario');

        setTipoPerfil(response.data.tipo);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        handleUsuarioTipo();
    }, [handleUsuarioTipo]);


    // EDITANDO OS USUÁRIOS
    async function editPerfil(PerfilInputEdit, uuid) {
        try {
            setIsLoading(true);

            // localStorage.setItem('NPROWORK', PerfilInputEdit.nome);

            const response = await api.put('/usuario/' + uuid, PerfilInputEdit);

            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            
            await handleUsuario();

            setIsLoading(false);

            // window.location.reload();

        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoading(false);
        }
    }


    async function updateSenha(PerfilSenha) {
        try {
            setIsLoading(true);

            const response = await api.put('/usuario/senha', PerfilSenha);

            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
              setIsLoading(false);

        } catch (err) {
            setIsLoading(true);
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoading(false);
        }
    }

    async function updateAvatar(PerfilInputAvatar) {
        try {
            setIsLoading(true);
            
            const data = new FormData();
            data.append("avatar", PerfilInputAvatar.avatar);
    
            const response = await api.post('/usuario/avatar', data); 

            const responseUser = await api.get('/auth/usuario');

            setDados(responseUser.data);
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoading(false);

        } catch (err) {
            setIsLoading(true);
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoading(false);
        }
    }
    


    return (
        <PerfilContext.Provider value={{ isLoading, editPerfil, updateSenha, dados, tipoPerfil, updateAvatar }}>
            {props.children}
        </PerfilContext.Provider>
    )

}



export function usePerfil() {
    const context = useContext(PerfilContext);

    return context;
}
