
import { Loading } from "../Loading/index";
import * as S from "./styles";
import { createRef, useState, useEffect } from 'react';
import InputMask from "react-input-mask";
import { usePerfil } from "../../hooks/perfil"
import { TextField, FormControl, Avatar, Button as MuiButton, makeStyles, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { grey } from "@material-ui/core/colors";
import {
CloudUpload as MuiCloudUpload,
Delete as MuiDelete
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';


const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);
const DeleteIcon = styled(MuiDelete)(spacing);

const useStyles = makeStyles((theme) => ({
root: {
    display: "flex",
    "& > *": {
    margin: theme.spacing(1)
    }
},
large: {
    width: theme.spacing(24),
    height: theme.spacing(24)
}
}));

const BigAvatar = styled(Avatar)`
    width: 200px;
    height: 200px;
    margin: 10px 0px 20px 0px;
    border: 1px solid ${grey[500]};
    box-shadow: 0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]};
    justify-content: flex-start;
`;


export function PerfilForm() {
    const { editPerfil, isLoading,  updateSenha, dados, updateAvatar } = usePerfil();

    const [avatar, setAvatar] = useState(dados?.avatar || '');
    const [uuid, setUUID] = useState(dados?.uuid || '');
    const [nome, setNome] = useState(dados?.nome || '');
    const [cpf, setCPF] = useState(dados?.cpf?.br || '');
    const [email, setEmail] = useState(dados?.email || '');
    const [celular, setCelular] = useState(dados?.celular?.br || '');
    const [telefone, setTelefone] = useState(dados?.telefone?.br || '');
    const [data_nascimento, setDataNascimento] = useState(dados?.data_nascimento?.br || '');
    const [mostraSenha, setMostraSenha] = useState(false);


    const [senha_atual, setSenhaAtual] = useState('');
    const [nova_senha, setNovaSenha] = useState('');
    const [confirmar_senha, setConfirmarSenha] = useState('');
    // const [loading, setLoading] = useState(false);

    const handlUpdatePerfil = async event => {
        event.preventDefault();

        let data = {
            nome,
            cpf,
            email,
            celular,
            telefone,
            nascimento:data_nascimento,
            status: 1
        }

        await editPerfil(data, uuid );
    }

    
    const handleUpdatePerfilSenha = async event => {
        event.preventDefault();

        let data = {  
            senha_atual: senha_atual,
            nova_senha: nova_senha,
            confirmar_senha: confirmar_senha
        }
        
        await updateSenha(data);
    }

    useEffect(() => {
        setAvatar(dados?.avatar || "");
        setUUID(dados?.uuid || "");
        setNome(dados?.nome || "");
        setCelular(dados?.celular.br || "");
        setTelefone(dados?.telefone.br || "");
        setCPF(dados?.cpf.br || "");
        setDataNascimento(dados?.data_nascimento.br || "");
        setEmail(dados?.email || ""); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados]);

    const classes = useStyles();

    const inputFileRef = createRef(null);
  
    const setImage = (newImage) => {
      setAvatar(newImage);
      
    };
  
    const handleOnChange = async (event) => {
        const newImage = event.target?.files?.[0];

        let data = {
            avatar: newImage
        }

        if (newImage) {
            await updateAvatar(data);
            setImage(URL.createObjectURL(newImage));
        }
    };
  
    /**
     *
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
     */
    const handleClick = (event) => {
      if (avatar) {
        event.preventDefault();
        setImage(null);
      }
    };
  

    return (
        <S.wrapper>
        {isLoading ? 
            <Loading /> 
        :
        <>   
        <S.Container onSubmit={handlUpdatePerfil}>  
            <S.CenteredContent>
                <BigAvatar
                    className={classes.large}
                    $withBorder
                    alt="Avatar"
                    src={avatar || <AccountCircleIcon />}
                />
                <input
                    ref={inputFileRef}
                    accept="image/*"
                    hidden
                    id="avatar-image-upload"
                    type="file"
                    onChange={handleOnChange}
                />
                <label htmlFor="avatar-image-upload" className="avatar-image-upload">
                    <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    mb={2}
                    onClick={handleClick}
                    >
                        {avatar ? <DeleteIcon mr={2} /> : <UploadIcon mr={2} />}
                        {avatar ? "Trocar" : "Upload"}
                    </Button>
                </label>
            </S.CenteredContent>

            <S.TituloTopo>Altere seus dados</S.TituloTopo>
            <S.ContainerCardForm>
                <div className="card-form">
                    <FormControl>
                        <TextField
                            variant="outlined"
                            type="text"
                            placeholder="Nome"
                            label={'Nome'}
                            value={nome}
                            onChange={event => setNome(event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <InputMask
                            type="text"
                            mask="999.999.999-99"
                            placeholder="CPF"
                            onChange={event => setCPF(event.target.value)}
                            value={cpf}
                        >
                        {() => <TextField variant="outlined" label={'CPF'} />}
                        </InputMask>
                    </FormControl>
                    <FormControl>
                        <TextField
                            variant="outlined"
                            type="text"
                            placeholder="E-mail"
                            label={'E-mail'}
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <InputMask
                            type="text"
                            mask="(99) 9 9999-9999"
                            placeholder="Celular"
                            onChange={event => setCelular(event.target.value)}
                            value={celular}
                        >
                        {() => <TextField variant="outlined" label={'Celular'} />}
                        </InputMask>
                    </FormControl>
                    <FormControl>
                        <InputMask
                            type="text"
                            mask="(99) 9999-9999"
                            placeholder="Telefone"
                            onChange={event => setTelefone(event.target.value)}
                            value={telefone}
                        >
                        {() => <TextField variant="outlined" label={'Telefone'} />}
                        </InputMask>
                    </FormControl>
                    <FormControl>
                        <InputMask
                            type="text"
                            mask="99/99/9999"
                            placeholder="Data de nascimento"
                            value={data_nascimento}
                            onChange={event => setDataNascimento(event.target.value)}
                        >
                        {() => <TextField variant="outlined" label={'Data de nascimento'} />}
                        </InputMask>
                    </FormControl>
                 </div>
            </S.ContainerCardForm>
            <button type="submit">
                {isLoading ? 'Carregando...' : 'Atualizar'}
            </button>
        </S.Container>
         <S.ContainerSenha onSubmit={handleUpdatePerfilSenha} >
             <h2>Alterar Senha</h2>
             <S.ContainerCardForm >
                 <div className="card-form">
                     <FormControl>
                         <TextField
                            variant="outlined" 
                            type={mostraSenha ? "text" : "password"}
                            placeholder="Senha Atual"
                            autoComplete="off"
                            label={'Senha Atual'}
                            value={senha_atual} 
                            onChange={e => setSenhaAtual(e.target.value)}
                            InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setMostraSenha(!mostraSenha)}
                                >
                                    {mostraSenha ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                </InputAdornment>
                            )
                            }}
                         />
                     </FormControl>
                     <FormControl>
                         <TextField
                            variant="outlined" 
                            type={mostraSenha ? "text" : "password"}
                            placeholder="Nova senha"
                            label={'Nova senha'}
                            autoComplete="off"
                            value={nova_senha} 
                            onChange={e => setNovaSenha(e.target.value)}    
                         />
                     </FormControl>
                     <FormControl>
                         <TextField
                            variant="outlined" 
                            type={mostraSenha ? "text" : "password"}
                            placeholder="Confirmar senha"
                            label={'Confirmar senha'}
                            autoComplete="off"
                            value={confirmar_senha} 
                            onChange={e => setConfirmarSenha(e.target.value)}    
                         />
                     </FormControl>
                 </div>
             </S.ContainerCardForm>
             <button type="submit">
                 {isLoading ? "Carregando..." : "Atualizar Senha"}
             </button>
         </S.ContainerSenha>
         </>
        }
         </S.wrapper>
    );
}
