import * as S from './styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { Box, Tab, Divider } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Loading } from '../Loading/index';
import { useEstatistica } from '../../hooks/estatistica';
import Skeleton from '@material-ui/lab/Skeleton';

moment.locale('pt-br');

export function Dashboard() {
  const { isLoading, dadosAcao } = useEstatistica();
  const [tipo, setTipo] = React.useState('1');

  const handleSelect = (event, newValue) => {
    setTipo(newValue);
  };
  const data = [
    {
      name: 'Tarefas',
      Pendentes: dadosAcao.pendente,
      Aceitas: dadosAcao.aceita,
      Iniciadas: dadosAcao.iniciada,
      Concluídas: dadosAcao.concluida,
      Canceladas: dadosAcao.cancelada,
      'Não aceita': dadosAcao.nao_aceita
    }
  ];

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <S.ContainerColumn>
          <S.ContainerGrafico>
            <h1 className="topo">Tarefas</h1>
            <ResponsiveContainer>
              <ComposedChart
                width={300}
                height={300}
                data={data}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" scale="band" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Divider />
                <Bar dataKey="Pendentes" barSize={20} fill="#FFBB28" />
                <Bar dataKey="Aceitas" barSize={20} fill="#F55" />
                <Bar dataKey="Iniciadas" barSize={20} fill="#C71585" />
                <Bar dataKey="Concluídas" barSize={20} fill="#8A2BE2" />
                <Bar dataKey="Canceladas" barSize={20} fill="#808000" />
                <Bar dataKey="Não aceita" barSize={20} fill="#33CC95" />
              </ComposedChart>
            </ResponsiveContainer>
          </S.ContainerGrafico>
        </S.ContainerColumn>
      )}
      <S.TabelaContainer>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tipo}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleSelect}
                aria-label="Selecione qual tabela visualizar"
                centered
              >
                <Tab label="Responsável" value="1" />
                <Tab label="Pastas" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <table>
                <thead>
                  <tr>
                    <th>Responsável</th>
                    <th>Pendentes</th>
                    <th>Aceitas</th>
                    <th>Iniciadas</th>
                    <th>Concluídas</th>
                    <th>Canceladas</th>
                    <th>Não aceita</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td></td>
                    </tr>
                  ) : (
                    dadosAcao?.itens &&
                    dadosAcao?.itens?.responsaveis.map((lista) => (
                      <tr key={lista.id}>
                        <td data-label="Nome">
                          {isLoading ? <Skeleton /> : lista.nome}
                        </td>
                        <td data-label="Pententes">
                          {isLoading ? <Skeleton /> : lista.pendente}
                        </td>
                        <td data-label="Aceitas">
                          {isLoading ? <Skeleton /> : lista.aceita}
                        </td>
                        <td data-label="Iniciadas">
                          {isLoading ? <Skeleton /> : lista.iniciada}
                        </td>
                        <td data-label="Concluídas">
                          {isLoading ? <Skeleton /> : lista.concluida}
                        </td>
                        <td data-label="Canceladas">
                          {isLoading ? <Skeleton /> : lista.cancelada}
                        </td>
                        <td data-label="Não aceita">
                          {isLoading ? <Skeleton /> : lista.nao_aceita}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </TabPanel>
            <TabPanel value="2">
              <table>
                <thead>
                  <tr>
                    <th>Pasta</th>
                    <th>Pendentes</th>
                    <th>Aceitas</th>
                    <th>Iniciadas</th>
                    <th>Concluídas</th>
                    <th>Canceladas</th>
                    <th>Não aceita</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td></td>
                    </tr>
                  ) : (
                    dadosAcao?.itens &&
                    dadosAcao?.itens?.clientes.map((lista) => (
                      <tr key={lista.id}>
                        <td data-label="Nome">
                          {isLoading ? <Skeleton /> : lista.nome}
                        </td>
                        <td data-label="Pententes">
                          {isLoading ? <Skeleton /> : lista.pendente}
                        </td>
                        <td data-label="Aceitas">
                          {isLoading ? <Skeleton /> : lista.aceita}
                        </td>
                        <td data-label="Iniciadas">
                          {isLoading ? <Skeleton /> : lista.iniciada}
                        </td>
                        <td data-label="Concluídas">
                          {isLoading ? <Skeleton /> : lista.concluida}
                        </td>
                        <td data-label="Canceladas">
                          {isLoading ? <Skeleton /> : lista.cancelada}
                        </td>
                        <td data-label="Não aceita">
                          {isLoading ? <Skeleton /> : lista.nao_aceita}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </TabPanel>
          </TabContext>
        </Box>
      </S.TabelaContainer>
      <S.TabelaContainer>
        <table>
          <thead>
            <tr>
              <th>Pasta</th>
              <th>Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td></td>
              </tr>
            ) : (
              dadosAcao?.clientes &&
              Object.entries(dadosAcao?.clientes).map((lista) => (
                <tr>
                  <td data-label="Nome">
                    {isLoading ? <Skeleton /> : lista[0]}
                  </td>
                  <td data-label="Pententes">
                    {isLoading ? <Skeleton /> : lista[1]}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </S.TabelaContainer>
    </S.Container>
  );
}
