import React from 'react';
import * as S from "./styles";
import { useTarefa } from "../../hooks/tarefa";
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom"
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions, Typography, Divider} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Loading } from "../Loading/index";
import { useOrigem } from "../../hooks/origem";

export function TarefaTableUUID() {
    let history = useHistory();
    const { tarefaListaUUID, tarefaPage, isLoading, pagination } = useTarefa();
    const { origemLista } = useOrigem();

  
    const handleSelect = async (event, value) => {
        await tarefaPage(value);
    }
    
    const handleClickOpenEdit = async (uuid) => {
        history.push("/minhas-tarefas/"+uuid);
    };
  
    const diaDaSemana = ["Domingo","Segunda-Feira", "Terça-Feira","Quarta-Feira", "Quinta-Feira", "Sexta-Feira","Sábado"];

 
    
    const frequenciaTarefa = {
        1: "Semanal",
        2: "Mensal"
    }

    function getKeyByValueId(object, value) { 
        return Object.keys(object).find(key =>  object[key].id === value);
    }

    return (
        <S.Container>
            {isLoading ? 
                <Loading />
            : tarefaListaUUID.map(lista =>  (
                <Accordion key={lista.uuid}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    >
                    <S.Column>
                        <S.TituloGrande> <span>Ação</span>: {lista.acao}</S.TituloGrande>
                        <S.TituloPequeno>Problema: {lista.problema}</S.TituloPequeno>
                        <S.TituloPequeno>
                            <div className="responsavel">
                                <p>Responsável: {lista?.responsavel?.nome || '' }</p> -
                                <S.NavUsuario to={'/usuario/'+lista?.responsavel?.uuid}>
                                    ver responsável
                                </S.NavUsuario>
                            </div>
                        </S.TituloPequeno>
                    </S.Column>
                    </AccordionSummary>
                    <AccordionDetails>
                        <S.Column>
                            <Typography  variant="caption">
                                Origem: {origemLista[getKeyByValueId(origemLista,lista.origem)]?.titulo || ''}
                            </Typography>
                            <Typography  variant="caption">
                                Solicitante: {lista?.solicitante?.nome || ''} -
                            <S.NavUsuario to={'/usuario/'+lista?.solicitante?.uuid}>
                                ver solicitante
                            </S.NavUsuario>
                            </Typography>
                            {<Typography  variant="caption">
                                Cliente: {lista?.cliente?.nome || ''} -
                                <S.NavUsuario to={'/cliente/'+lista?.cliente?.uuid}>
                                    ver cliente
                                </S.NavUsuario>
                            </Typography>}
                            {lista.recorrente !== null ?
                            <>
                                <Typography variant="caption">
                                    Frequência: {frequenciaTarefa[lista.recorrente.tipo]}
                                </Typography>
                                {lista.recorrente.tipo === 2 ?
                                <Typography variant="caption">
                                    Dia: {lista.recorrente.dia}
                                </Typography>
                                :
                                <Typography variant="caption">
                                    Dia da semana: {JSON.parse(lista.recorrente.dias_semana.replace(/"/g, '')).map(i =>  (
                                              
                                              (i ? ', ' : '') + diaDaSemana[i]
                                            ))}
                                </Typography>
                                }
                            </>
                             : null}
                            <Typography variant="caption">
                                Início previsto: {lista.inicio_previsto.br} 
                            </Typography>
                            <Typography variant="caption">
                                Conclusão prevista: {lista.conclusao_prevista.data}
                            </Typography>
                            <Typography variant="caption">
                                Início: {lista.inicio.data} 
                            </Typography>
                            <Typography variant="caption">
                                Conclusão: {lista.conclusao.data}
                            </Typography>
                            <Typography variant="caption">
                                Data de criação: {lista.data_criacao.br}
                            </Typography>
                        </S.Column>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <S.ButttonEditar color="primary" onClick={() => handleClickOpenEdit(lista.uuid,pagination[0].pagina_atual)} >
                            Editar
                        </S.ButttonEditar>
                    </AccordionActions>
                </Accordion>
            ))}
            <S.ContainerPagination>
                {pagination.length ?
                <Pagination
                    showLastButton={true}
                    showFirstButton={true}
                    color="primary"
                    count={pagination[0].ultima_pagina}
                    page={Number(pagination[0].pagina_atual)}
                    onChange={handleSelect}
                />
                : null}
            </S.ContainerPagination>
             
        </S.Container>
    );
}
