
import React from "react";
import {Box} from '@material-ui/core'
import { EditarGrupo } from "../../components/EditarGrupo/index";

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { CheckListProvider } from "../../hooks/checklist";
import { GrupoProvider } from "../../hooks/grupo";
import { ItemRespostasProvider } from "../../hooks/itemRespostas";

export function DetalheGrupo() {
    
  return (
      <CheckListProvider>
        <ItemRespostasProvider>
          <GrupoProvider>
              <div style={{display: 'flex'}}>
                  <Sidebar activePage={'8'} />
                  <div style={{width: '100%'}}>
                    <Topbar pageTitle={'Visualizar grupo'} />
                    <Box  className="container_admin">
                        <EditarGrupo />
                    </Box>
                  </div>
              </div>
          </GrupoProvider>
        </ItemRespostasProvider>
      </CheckListProvider>
  );
};


