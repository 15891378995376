import api from "../services/api";
import {createContext, useState, useContext, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

const ItemRespostasContext = createContext({});

export const ItemRespostasProvider = (props) =>  {
    const [itemRespostasLista, setItemRespostasLista] = useState([]);
    const [itemRespostasListaAtiva, setItemRespostasListaAtiva] = useState([]);
    const [itemRespostasPorUUID, setItemRespostasPorUUID] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [pagination, setPagination] = useState([] || '');
    const [activePage, setPageActive] = useState('');
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);


    const handleItemRespostas = useCallback(async () => {
        
        const buscaAtiva = localStorage.getItem('salvarBuscaItemRespostas');

        if(buscaAtiva && buscaAtiva !== ''){

            try {
                setIsLoading(true); 
                let search = JSON.parse(buscaAtiva);
                const response = await api.get('/item-resposta?respostas='+search.respostas+'&status='+search.status); 
                setIsLoading(true);
                
                const data = [{
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                    }
                ];
                setPagination(data);
                setItemRespostasLista(response.data.itens);
                setIsLoading(false); 

            } catch (err) {
                setIsLoading(false); 
            }
        }else{

            try {
                    setIsLoading(true);
                const response = await api.get('/item-resposta');
                
                const data = [{
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                    }
                ];
                setPagination(data);
                setItemRespostasLista(response.data.itens);
                setIsLoading(false); 

            } catch (err) {
                setIsLoading(false); 
            }
        }
      }, []);

    useEffect(() => {
        handleItemRespostas();
    }, [handleItemRespostas]);


    const handleItemRespostasAtiva = useCallback(async () => {
        

        try {
                setIsLoading(true);
            const response = await api.get('/item-resposta?status=1');
            
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setPagination(data);
            setItemRespostasListaAtiva(response.data.itens);
            setIsLoading(false); 

        } catch (err) {
            setIsLoading(false); 
        }
      }, []);

    useEffect(() => {
        handleItemRespostasAtiva();
    }, [handleItemRespostasAtiva]);

         
    // Paginação
    async function itemRespostasPage(page) {
        try {
            setIsLoading(true);
            
            const response = await api.get('/item-resposta?page='+page);             

            setItemRespostasLista(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setIsLoading(false);
            setPagination(data);

        } catch (err) {
            setIsLoading(false); 
        }
    }

    // USUÁRIOS POR UUID

    async function getItemRespostasPorUuid(uuid,page) {
        try {
            setIsLoadingEdit(true);
            const responseedit = await api.get('/item-resposta/'+uuid); 

            setItemRespostasPorUUID([responseedit.data]);
            setPageActive(page);
            setIsLoadingEdit(false);
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false);
        }
    }

    // Criar OS USUÁRIOS
    async function criaItemRespostas(UsuarioInputEdit, modal) {
        try {
            setIsLoading(true);

            const response = await api.post('/item-resposta', UsuarioInputEdit); 

            let keysToRemove = ["buscaAtivaItemRespostas", "salvarBuscaItemRespostas"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));

            setIsLoading(false);
            handleItemRespostas();
            modal();

            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            return {
                erro: false
            };
        } catch (err) {
            setIsLoading(false); 
             toast.error(err.response.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
            return {
                erro: true
            };
        }
    } 

    
    // EDITANDO OS USUÁRIOS
    async function editaItemRespostas(UsuarioInputEdit, uuid, fecha) {
        try {

            setIsLoadingEdit(true);
            const response = await api.put('/item-resposta/'+uuid, UsuarioInputEdit); 

            const responsepage = await api.get('/item-resposta');    
            
            setItemRespostasLista(responsepage.data.itens);
            const datapage = [{
                    'quantidade_pagina' : responsepage.data.quantidade_pagina,
                    'pagina_atual' : responsepage.data.pagina_atual,
                    'proxima_pagina' : responsepage.data.proxima_pagina,
                    'pagina_anterior' : responsepage.data.pagina_anterior,
                    'ultima_pagina' : responsepage.data.ultima_pagina,
                    'total' : responsepage.data.total
                }
            ];
            setPagination(datapage);
            fecha();

            handleItemRespostas();

            setIsLoadingEdit(false);
            toast.success(response?.data?.message, {
                position: toast.POSITION.TOP_CENTER
            });

        } catch (err) {
            toast.error(err.response?.data?.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false); 
        }
    } 

    
    // Pesquisar Catálogo
    
    async function searchItemRespostas(search, Modal) {
        try {
            setIsLoadingSearch(true);
            
            if(search.titulo === '' && search.cpf === '' && search.tipo === '' && search.status === ''){
                handleItemRespostas();
            } else {
                 const response = await api.get('/item-resposta?respostas='+search.respostas+'&status='+search.status); 

                 if(response.data.error === true ){
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                 }else {
                    localStorage.setItem('buscaAtivaItemRespostas', 'ativa');
                    localStorage.setItem('salvarBuscaItemRespostas', JSON.stringify(search));
                    
                    setItemRespostasLista(response.data.itens);
                    const data = [{
                        'quantidade_pagina' : response.data.quantidade_pagina,
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                        }
                    ];
                    setPagination(data);
                 }
            }
            
            setIsLoadingSearch(false);
            Modal();
            

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }
    async function limpaUser() {
        try {
            setIsLoadingSearch(true);
            
            handleItemRespostas();
            let keysToRemove = ["buscaAtivaItemRespostas", "salvarBuscaItemRespostas"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                handleItemRespostas();

            setIsLoadingSearch(false);

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }

    
    
    async function deletarItemRespostas(uuid) {
        try {
            setIsLoading(true);
            let response = await api.delete('/item-resposta/'+uuid);     
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            handleItemRespostas();
            setIsLoading(false); 

        } catch (err) {
            setIsLoading(true);

            toast.success(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });

            handleItemRespostas();
            setIsLoading(false); 
        }
    }
 

    return (
        <ItemRespostasContext.Provider value={{isLoading, itemRespostasLista, itemRespostasListaAtiva, getItemRespostasPorUuid, itemRespostasPorUUID, editaItemRespostas, criaItemRespostas, itemRespostasPage, pagination, activePage, isLoadingEdit, searchItemRespostas, limpaUser, isLoadingSearch, deletarItemRespostas}}>  
            {props.children}
        </ItemRespostasContext.Provider>
    )

}



export function useItemRespostas() {
    const context = useContext(ItemRespostasContext);

    return context;
}
