import React, {useState} from "react";
import {Box} from '@material-ui/core'
import { FormEditaCliente } from "../../components/FormEditaCliente/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import {ListaHistoricoComponent} from "../../components/ListaHistoricoComponent/index";
import { PastaProvider } from "../../hooks/pasta";
import { HistoricoProvider } from "../../hooks/historico";
import { TarefaProvider } from "../../hooks/tarefa";


export function EditCliente() {
  const [abreModal, setAbreModal] = useState(false);
    
    function handleAbreModalHistorico(){
        setAbreModal(true);
    }

    function handleFechaHistoricoModal(){
        setAbreModal(false);
    }
    
    const permissao = JSON.parse(localStorage.getItem('PERMISSAO_USER_EPROWORK')) ?? [];

    let titulo = !permissao.includes('per_pasta_editar') ? 'Gerenciar' : 'Editar pasta';

  return (
    <PastaProvider>
      <TarefaProvider>
        <HistoricoProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'3'} />
            <div style={{width: '100%'}}>
                <Topbar pageTitle={titulo} />
                <Box className="container_admin">
                      <FormEditaCliente verModalHistorico={handleAbreModalHistorico}  />
                </Box>
            </div>
        </div>
        {abreModal ? 
        <ListaHistoricoComponent
            fecha={handleFechaHistoricoModal} 
            abre={abreModal}
        /> 
        : null }
        </HistoricoProvider>
      </TarefaProvider>
    </PastaProvider>
  );
};



