import api from "../services/api";
import {createContext, useState, useContext, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import 'moment/locale/pt-br';

moment.locale('pt-br');

const TarefaContext = createContext({});

export const TarefaProvider = (props) =>  {
    const [tarefaLista, setTarefaLista] = useState([]);
    const [tarefaListaAgenda, setTarefaListaAgenda] = useState([]);
    const [paginationAgenda, setPaginationAgenda] = useState([] || '');
    const [tarefaImagem, setTarefaImagem] = useState([]);
    const [tarefaPorUUID, setTarefaPorUUID] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [activePage, setPageActive] = useState('');
    const [pagination, setPagination] = useState([] || '');
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [isLoadingAgenda, setIsLoadingAgenda] = useState(true);
    const [isLoadingUpload, setIsLoadingUpload] = useState(true);
    const [VerTarefaPorUUID, setVerTarefaPorUUID] = useState([]);
    const [VerTarefaPorCliente, setVerTarefaPorCliente] = useState([]);
    const [isLoadingEditID, setIsLoadingEditID] = useState(true);
    const [checklistLista, setCheckListLista] = useState([]);
    const [paginationChecklist, setPaginationChecklist] = useState([] || '');
    const [paginationClienteChecklist, setPaginationClienteChecklist] = useState([] || '');
    const [VerRespostaPorUUID, setVerRespostaPorUUID] = useState([]);
    const [agenda, setAgenda] = useState([]);
    


    const handleTarefa = useCallback(async () => {
        setIsLoadingAgenda(false);
        
        const buscaAtiva = localStorage.getItem('salvarBuscaTarefa');

        if(buscaAtiva && buscaAtiva !== ''){
            let search = JSON.parse(buscaAtiva);
            const response = await api.get('/tarefa?recorrente='+search.recorrente+'&acao='+search.acao+'&cliente='+search.cliente+'&status='+search.status+'&inicio_previsto_inicio='+search.inicio_previsto_inicio+'&inicio_previsto_final='+search.inicio_previsto_final+'&origem='+search.origem); 
            setIsLoading(true);
            
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setPagination(data);
            setTarefaLista(response.data.itens);
            setIsLoading(false); 
        }else{
            setIsLoading(true);
            const response = await api.get('/tarefa');
            
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setPagination(data);
            setTarefaLista(response.data.itens);
            setIsLoading(false);
        }
      }, []);

    useEffect(() => {
        if(window.location.pathname === '/checklist'){
            handleTarefa();
        }
    }, [handleTarefa]);

   
    // TAREFA AGENDA
    const tarefaAgenda = useCallback(async () => {

        const buscaAtivaAgenda = localStorage.getItem('salvarBuscaTarefaAgenda');

        if(buscaAtivaAgenda && buscaAtivaAgenda !== ''){
            setIsLoadingAgenda(false);
            let search = JSON.parse(buscaAtivaAgenda);
            let response = await api.get('/tarefa?inicio_previsto_inicio='+search.inicio_previsto_inicio+'&inicio_previsto_final='+search.inicio_previsto_inicio);
     
            const data = [{
                'quantidade_pagina' : response.data.quantidade_pagina,
                'pagina_atual' : response.data.pagina_atual,
                'proxima_pagina' : response.data.proxima_pagina,
                'pagina_anterior' : response.data.pagina_anterior,
                'ultima_pagina' : response.data.ultima_pagina,
                'total' : response.data.total
                }
            ];
            setPaginationAgenda(data);
            setTarefaListaAgenda(response.data.itens);
        }else{

            setIsLoadingAgenda(false);
            let date = new Date();
            let formattedDate = moment(date).format('DD/MM/YYYY');
            let response = await api.get('/tarefa?inicio_previsto_inicio='+formattedDate+'&inicio_previsto_final='+formattedDate);
            const data = [{
                'quantidade_pagina' : response.data.quantidade_pagina,
                'pagina_atual' : response.data.pagina_atual,
                'proxima_pagina' : response.data.proxima_pagina,
                'pagina_anterior' : response.data.pagina_anterior,
                'ultima_pagina' : response.data.ultima_pagina,
                'total' : response.data.total
                }
            ];
            setPaginationAgenda(data);
            setTarefaListaAgenda(response.data.itens);
        }
    }, []);

    useEffect(() => {
        if(window.location.pathname === 'agenda'){
            tarefaAgenda();
        }
    }, [tarefaAgenda]);


    async function searchTarefaAgenda(search) {
        try {
            setIsLoadingAgenda(true);
            
            if(search.inicio_previsto_inicio === ''){
                tarefaAgenda();
            } else {
                    const response = await api.get('/tarefa?inicio_previsto_inicio='+search.inicio_previsto_inicio+'&inicio_previsto_final='+search.inicio_previsto_inicio); 
                    if(response.data.error === true ){
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });       
                    } else {
                        localStorage.setItem('salvarBuscaTarefaAgenda', JSON.stringify(search));
                        
                        setTarefaListaAgenda(response.data.itens);
                        const data = [{
                            'quantidade_pagina' : response.data.quantidade_pagina,
                            'pagina_atual' : response.data.pagina_atual,
                            'proxima_pagina' : response.data.proxima_pagina,
                            'pagina_anterior' : response.data.pagina_anterior,
                            'ultima_pagina' : response.data.ultima_pagina,
                            'total' : response.data.total
                            }
                    ];
                    setPaginationAgenda(data);
                }
            }
            
            setIsLoadingAgenda(false);

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setTarefaListaAgenda([]);
            setIsLoadingAgenda(false); 
        }
    }


    async function tarefaAgendaPage(page) {
        try {

            const buscaAtivaAgenda = localStorage.getItem('salvarBuscaTarefaAgenda');

            if(buscaAtivaAgenda && buscaAtivaAgenda !== ''){
                let search = JSON.parse(buscaAtivaAgenda);
                let response = await api.get('/tarefa?inicio_previsto_inicio='+search.inicio_previsto_inicio+'&inicio_previsto_final='+search.inicio_previsto_inicio+'&page='+page);
                let tarefa = response.data.itens;
            
                const data = [{
                    'quantidade_pagina' : response.data.quantidade_pagina,
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                    }
                ];
                setPaginationAgenda(data);
                setTarefaListaAgenda(tarefa);
                setIsLoadingAgenda(false);
            }else{

                let date = new Date();
                let formattedDate = moment(date).format('DD/MM/YYYY');
                let response = await api.get('/tarefa?inicio_previsto_inicio='+formattedDate+'&inicio_previsto_final='+formattedDate+'&page='+page);
                let tarefa = response.data.itens;
            
                setTarefaListaAgenda(tarefa);
                setIsLoadingAgenda(false);
            }

        } catch (err) {
            setIsLoadingAgenda(false); 
        }
    }

    // checklist tarefa
    
    async function getCheckListTarefaPorUuid(uuid) {
        try {
            setIsLoadingEdit(true);
            const responseedit = await api.get('/tarefa/checklist/'+uuid); 

            const data = [{
                    'pagina_atual' : responseedit.data.pagina_atual,
                    'proxima_pagina' : responseedit.data.proxima_pagina,
                    'pagina_anterior' : responseedit.data.pagina_anterior,
                    'ultima_pagina' : responseedit.data.ultima_pagina,
                    'total' : responseedit.data.total
                }
            ];
            setPaginationChecklist(data);
            setCheckListLista(responseedit.data.itens);
            setIsLoadingEdit(false);
          
        } catch (err) {
            setIsLoadingEdit(false);
        }
    }

         
    // Paginação
    async function tarefaPage(page) {
        try {
            setIsLoading(true);
            
            const response = await api.get('/tarefa?page='+page);             

            setTarefaLista(response.data.itens);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setIsLoading(false);
            setPagination(data);

        } catch (err) {
            setIsLoading(false); 
        }
    }

    // Agenda
    async function agendaStart(mesAno) {
        try {
            setIsLoading(true);
            
            const response = await api.get('/tarefa/agenda?mesAno='+mesAno);
                        
            setAgenda(response);
            setIsLoading(false);

        } catch (err) {
            setIsLoading(false); 
        }
    }

    // Por cliente
    async function tarefaPageCliente(uuid, page) {
        try {
            setIsLoading(true);
            
            const response = await api.get('/tarefa?cliente='+uuid+'&page='+page);             

            setVerTarefaPorCliente([response.data.itens]);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setPaginationClienteChecklist(data);
            setIsLoading(false);

        } catch (err) {
            setIsLoading(false); 
        }
    }

    // USUÁRIOS POR UUID

    async function getTarefaPorUuid(uuid,page) {
        try {
            setIsLoadingEdit(true);
            const responseedit = await api.get('/tarefa/'+uuid); 

            setTarefaPorUUID([responseedit.data]);
            setPageActive(page);
            setIsLoadingEdit(false);
            setTimeout(() => {
                window.location.pathname = '/tarefa/'+uuid;
            }, 1000);
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEdit(false);
        }
    }

    
    async function VisualizarTarefaPorUUID(uuid) {
        try {
            setIsLoadingEditID(true);
            const responseedit = await api.get('/tarefa/'+uuid); 

            setVerTarefaPorUUID([responseedit.data]);

            setIsLoadingEditID(false);
            return {
                erro: false
            }

        } catch (err) {
            setIsLoadingEditID(true);
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingEditID(false);
            return {
                erro: true,
            }
        }
    }
    
    async function VisualizarTarefaPorCliente(uuid) {
        try {
            setIsLoadingEditID(true);

            let uuidSolicitante = String(localStorage.getItem('LPROWORK'));
            
            const master = Number(localStorage.getItem('MMPROWORK'));
            const administrador = Number(localStorage.getItem('MPROWORK'));
            const gerente = Number(localStorage.getItem('GTPROWORK'));
            const gestor = Number(localStorage.getItem('GSPROWORK'));
            const analista = Number(localStorage.getItem('ANPROWORK'));
            
            if((gerente === 1 || gestor === 1) && administrador === 2 && analista === 2 && master === 2){
                let responseedit = await api.get('/tarefa?cliente='+uuid+'&solicitante='+uuidSolicitante);
                if(responseedit.data.itens.length > 0){
                    setVerTarefaPorCliente([responseedit.data.itens]);
                    const data = [{
                            'pagina_atual' : responseedit.data.pagina_atual,
                            'proxima_pagina' : responseedit.data.proxima_pagina,
                            'pagina_anterior' : responseedit.data.pagina_anterior,
                            'ultima_pagina' : responseedit.data.ultima_pagina,
                            'total' : responseedit.data.total
                        }
                    ];
                    setPaginationClienteChecklist(data);
                    setIsLoadingEditID(false);
                    return {
                        erro: false
                    }
                } 
            }else {

                let responseedit = await api.get('/tarefa?cliente='+uuid); 
                if(responseedit.data.itens.length > 0){
                    setVerTarefaPorCliente([responseedit.data.itens]);
                    const data = [{
                            'pagina_atual' : responseedit.data.pagina_atual,
                            'proxima_pagina' : responseedit.data.proxima_pagina,
                            'pagina_anterior' : responseedit.data.pagina_anterior,
                            'ultima_pagina' : responseedit.data.ultima_pagina,
                            'total' : responseedit.data.total
                        }
                    ];
                    setPaginationClienteChecklist(data);
                    setIsLoadingEditID(false);
                    return {
                        erro: false
                    }
                }
            }
             

        } catch (err) {
            setIsLoadingEditID(true);
            if(err){
                if(err.response?.data?.message !== "Nenhuma tarefa encontrada!"){
                    toast.error(err.response?.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    }); 
                }
            }
            setIsLoadingEditID(false);
            return {
                erro: true,
            }
        }
    }
    
    // Criar 
    async function createTarefa(UsuarioInputEdit, EmailInputCria, ChecklistInputCria) {
        try {
            setIsLoading(true);

            const response = await api.post('/tarefa', UsuarioInputEdit); 
  
            if(response.data.error === false){
                await api.post('/tarefa/checklist', ChecklistInputCria);
                
                if(EmailInputCria.emailExecuta === true){
                    await api.post('/email', EmailInputCria);
                }  
            }

            let keysToRemove = ["buscaAtivaTarefa", "salvarBuscaTarefa"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));

            setIsLoading(false);
            handleTarefa();

            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            
            setTimeout(() => {
                window.location.pathname = '/checklist/'+UsuarioInputEdit.uuid;
            }, 2000);

            return {
                erro: false
            };
        } catch (err) {
            setIsLoading(false); 
             toast.error(err.response.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
            return {
                erro: true
            };
        }
    } 

    
    // EDITANDO 
    async function editTarefa(EmpresaInputEdit, uuid, EmailInputEdita, id_email) {
        try {

            setIsLoadingEditID(true);
            
            const data = new FormData();
            data.append("cliente", EmpresaInputEdit.cliente);
            data.append("responsavel",  EmpresaInputEdit.responsavel);
            data.append("solicitante", EmpresaInputEdit.solicitante);
            data.append("inicio_previsto", EmpresaInputEdit.inicio_previsto);
            data.append("conclusao_prevista", EmpresaInputEdit.conclusao_prevista);
            data.append("acao", EmpresaInputEdit.acao);
            data.append("problema", EmpresaInputEdit.problema);
            data.append("empresa", EmpresaInputEdit.empresa);
            data.append("origem", EmpresaInputEdit.origem);
            data.append("inicio", EmpresaInputEdit.inicio);
            data.append("conclusao", EmpresaInputEdit.conclusao);
            data.append("status", EmpresaInputEdit.status);
            data.append("tipo", EmpresaInputEdit.tipo);
            data.append("dia", EmpresaInputEdit.dia);
            data.append("dias_semana", EmpresaInputEdit.dias_semana);
            data.append("recorrente", EmpresaInputEdit.recorrente);

            const response = await api.post('/tarefa/'+uuid, data); 

            if(response.data.error === false){
        
                
                if(EmailInputEdita.acao === 'edit' && EmailInputEdita.emailExecuta === true){
                    let dataEmail = {
                        destinatario: EmailInputEdita.destinatario,
                        assunto: EmailInputEdita.assunto,
                        corpo: EmailInputEdita.corpo,
                        status: 1,
                    }
                    await api.put('/email/'+id_email, dataEmail); 
                } else if(EmailInputEdita.acao === 'add' && EmailInputEdita.emailExecuta === true){
                    let dataEmail = {
                        vinculo: EmailInputEdita.vinculo,
                        destinatario: EmailInputEdita.destinatario,
                        assunto: EmailInputEdita.assunto,
                        corpo: EmailInputEdita.corpo,
                        status: 1,
                    }
                    await api.post('/email', dataEmail); 
                }
            }

            const responsepage = await api.get('/tarefa/'+uuid);    
            setVerTarefaPorUUID([responsepage.data]);

            setIsLoadingEditID(false);
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            return {
                erro: false
            }

        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEditID(false); 
            return {
                erro: true
            }
        }
    } 



    
    // Pesquisar Catálogo
    
    async function searchTarefa(search, Modal) {
        try {
            setIsLoadingSearch(true);
            
            if(search.acao === '' && search.cliente === '' && search.status === ''  && search.recorrente === '' && search.inicio_previsto_inicio === '' && search.inicio_previsto_final === ''  && search.origem === ''){
                handleTarefa();
            } else {
                 const response = await api.get('/tarefa?recorrente='+search.recorrente+'&acao='+search.acao+'&cliente='+search.cliente+'&status='+search.status+'&inicio_previsto_inicio='+search.inicio_previsto_inicio+'&inicio_previsto_final='+search.inicio_previsto_final+'&origem='+search.origem); 
                 if(response.data.error === true ){
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                 }else {
                    localStorage.setItem('buscaAtivaTarefa', 'ativa');
                    localStorage.setItem('salvarBuscaTarefa', JSON.stringify(search));
                    
                    setTarefaLista(response.data.itens);
                    const data = [{
                        'quantidade_pagina' : response.data.quantidade_pagina,
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                        }
                    ];
                    setPagination(data);
                 }
            }
            
            setIsLoadingSearch(false);
            Modal();
            

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }

    async function limpaTarefa() {
        try {
            setIsLoadingSearch(true);
            
            handleTarefa();
            let keysToRemove = ["buscaAtivaTarefa", "salvarBuscaTarefa"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingSearch(false);

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingSearch(false); //pegando o response da requisição da api
        }
    }

    
    
    async function deletarTarefa(uuid, recorrente) {
        try {
            if(recorrente !== undefined){
                setIsLoading(true);
                let responseRecorrente =  await api.delete('/recorrente/'+recorrente);  
                                
                if(responseRecorrente.data.message){
                        let response = await api.delete('/tarefa/'+uuid);   
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        }); 
                }  
                
                setIsLoading(false); 
                window.location.reload();
                return {
                    erro: false
                }
            }else{
                setIsLoading(true);
                    let response = await api.delete('/tarefa/'+uuid);   
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                
                setIsLoading(false); 
                window.location.reload();
                return {
                    erro: false
                }
            }
  

        } catch (err) {
            if(err.response.data.message === 'Tarefa recorrente encontrada!'){
                let responseRecorrente =  await api.delete('/recorrente/'+recorrente);  
                             
                if(responseRecorrente.data.message){
                    let response = await api.delete('/tarefa/'+uuid);   
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    }); 
                }  
                
                window.location.reload();
                return {
                    erro: false
                }
            }else{
                toast.success(err.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

                setIsLoading(false); 
                return {
                    erro: true
                }
            }


            
        }
    }

 
    async function verImagemTarefa(id) {
        try {
            setIsLoadingUpload(true);

            let response = await api.get('/imagem/'+id+'/tarefas');     
            setTarefaImagem([response.data]);
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoadingUpload(false); 
            return {
                erro: true
            };

        } catch (err) {
            setIsLoadingUpload(true);

            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoadingUpload(false); 
            return {
                erro: true
            };
        }
    }

    async function uploadArquivo(dados) {
        try {
            setIsLoadingUpload(true);

            const data = new FormData();
            data.append("tabela", dados.tabela);
            data.append("imagem",  dados.imagem);
            data.append("vinculo", dados.vinculo);

            let response = await api.post('/imagem', data);     
            
            let responseImagem = await api.get('/imagem/'+dados.vinculo+'/tarefas');     
            setTarefaImagem([responseImagem.data]);
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoadingUpload(false); 
            return {
                erro: true
            };

        } catch (err) {
            setIsLoadingUpload(true);

            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoadingUpload(false); 
            return {
                erro: true
            };
        }
    }

    
    async function deletarArquivo(dados) {
        try {
            setIsLoading(true);

            let response = await api.delete('/imagem/'+dados);     
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            window.location.reload();
            setIsLoading(false); 

        } catch (err) {
            setIsLoading(true);

            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoading(false); 
        }
    }

    
    async function vinculaChecklist(dados, modal) {
        try {
            setIsLoading(true);

            let response = await api.post('/tarefa/checklist', dados);     
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoadingEdit(true);
            const responseedit = await api.get('/tarefa/checklist/'+dados.tarefa); 

            const data = [{
                    'pagina_atual' : responseedit.data.pagina_atual,
                    'proxima_pagina' : responseedit.data.proxima_pagina,
                    'pagina_anterior' : responseedit.data.pagina_anterior,
                    'ultima_pagina' : responseedit.data.ultima_pagina,
                    'total' : responseedit.data.total
                }
            ];
            setPaginationChecklist(data);
            setCheckListLista(responseedit.data.itens);


            modal();
            setIsLoadingEdit(false);
            // window.location.reload();
            setIsLoading(false); 

        } catch (err) {
            setIsLoading(true);

            toast.success(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });

            // window.location.reload();
            setIsLoading(false); 
        }
    }

    
    async function deletarCheckListTarefa(dados, modal) {
        try {
            setIsLoading(true);

            let response = await api.delete('/tarefa/checklist', {data:dados});    
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });


            modal();
            window.location.reload();
            setIsLoading(false); 

        } catch (err) {
            setIsLoading(true);

            toast.warning(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });


            window.location.reload();
            modal();
            setIsLoading(false); 
        }
    }



    async function downloadRelatorioTarefa(params, modal){
        try {
            setIsLoading(true);
            
            let response = await api.get('/tarefa/relatorio'+params, {responseType: 'blob'});   
            var date = new Date();
            var formattedDate = moment(date).format('YYYYMMDD');
            let filename = 'relatorio_auditoria_'+formattedDate;
        
            const url = window.URL.createObjectURL(new Blob([response.data],
                { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            setIsLoading(false); 
           

        } catch (err) {
            setIsLoading(true);   
            toast.error('Erro!', {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoading(false); 
        }

    }


    async function deletarTarefaRecorrente(uuid) {
        try {
            setIsLoading(true);
          
            let response = await api.delete('/recorrente/'+uuid);     
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

            setIsLoading(false); 
            return {
                erro: false
            }

        } catch (err) {
            setIsLoading(true);

            toast.success(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });

            setIsLoading(false); 
            return {
                erro: true
            }

            
        }
    }
 
    
    async function createTarefaRecorrente(UsuarioInputEdit, modal) {
        try {
            setIsLoading(true);

            const response = await api.post('/recorrente', UsuarioInputEdit); 
      
            let keysToRemove = ["buscaAtivaTarefaRecorrente", "salvarBuscaTarefaRecorrente"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));

            setIsLoading(false);

            modal();
            window.location.reload();

            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            return {
                erro: false
            };
        } catch (err) {
            setIsLoading(false); 
             toast.error(err.response.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
            return {
                erro: true
            };
        }
    } 

    async function VisualizarRespostaPorUUID(uuid) {
        try {
            setIsLoadingEditID(true);
            const responseedit = await api.get('/resposta/tarefa/'+uuid); 

            setVerRespostaPorUUID(responseedit.data);

            setIsLoadingEditID(false);
            setIsLoading(false); 
            return {
                erro: false
            }

        } catch (err) {
            return {
                erro: true,
            }
        }
    }

    // Gerar tarefas pela tag
    
    async function addTaskTag(tarefa) {
        try {
            setIsLoadingEdit(true);
            await api.post('/tarefa/tag', {tarefa});
            setIsLoadingEdit(false);
            return {
                erro: false
            }          
        } catch (err) {
            setIsLoadingEdit(false);
        }
    }
    

    return (
        <TarefaContext.Provider value={{isLoading, tarefaLista, getTarefaPorUuid, tarefaPorUUID, editTarefa, createTarefa, tarefaPage, pagination, activePage, isLoadingEdit, searchTarefa, limpaTarefa, isLoadingSearch, VerTarefaPorUUID, VisualizarTarefaPorUUID, deletarTarefa, deletarArquivo, isLoadingEditID, checklistLista, paginationChecklist, getCheckListTarefaPorUuid, deletarCheckListTarefa, vinculaChecklist, tarefaListaAgenda, isLoadingAgenda,downloadRelatorioTarefa, deletarTarefaRecorrente, createTarefaRecorrente, uploadArquivo,isLoadingUpload, verImagemTarefa, tarefaImagem, VisualizarTarefaPorCliente, VerTarefaPorCliente, paginationClienteChecklist, tarefaPageCliente, searchTarefaAgenda, paginationAgenda, tarefaAgendaPage, VerRespostaPorUUID, VisualizarRespostaPorUUID, addTaskTag, agenda, agendaStart, setTarefaListaAgenda}}>  
            {props.children}
        </TarefaContext.Provider>
    )

}



export function useTarefa() {
    const context = useContext(TarefaContext);

    return context;
}
