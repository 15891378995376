import * as S from './styles';
import { useState } from 'react';
import { useCheckList } from '../../hooks/checklist';
import CloseIcon from '@material-ui/icons/Close';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ButtonGroup,
  Button,
  IconButton,
  Grid,
  FormControlLabel,
  Switch
} from '@material-ui/core';

export function CriaNovoItemCheckListModal({
  abre,
  fecha,
  uuid,
  checklist,
  itens
}) {
  const { criaItemCheckList } = useCheckList();

  const [texto, setTitulo] = useState('');
  const [status, setStatus] = useState('');
  const [tipo, setTipo] = useState('');
  const [peso, setPeso] = useState('');
  const [ordem, setOrdem] = useState('');
  const [parametro, setParametro] = useState('');

  const handleCriaChecklist = async (event) => {
    event.preventDefault();

    let data = {
      texto,
      status,
      tipo,
      peso,
      foto_obrigatoria: foto_obrigatoria === true ? 1 : 2,
      parametro,
      grupo: uuid,
      ordem,
      checklist: checklist
    };

    await criaItemCheckList(data, fecha);
  };

  const [foto_obrigatoria, setFotoObrigatoria] = useState(false);

  const handleFotoObrigatoria = (event) => {
    setFotoObrigatoria(event.target.checked);
  };

  return (
    <Dialog open={abre} onClose={fecha} className="modal_dialog">
      <S.Container onSubmit={handleCriaChecklist}>
        <DialogTitle className="titulo">
          Cadastre um item
          <IconButton className="fecha" onClick={fecha}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className="grid_container">
          <Grid container>
            <Grid item xs={12}>
              <S.Column>
                <FormControl className="form_control">
                  <TextField
                    variant="outlined"
                    label={'Questão'}
                    value={texto}
                    placeholder="Questão"
                    onChange={(event) => setTitulo(event.target.value)}
                  />
                </FormControl>
                <FormControl className="form_control">
                  <TextField
                    variant="outlined"
                    label={'Parâmetro'}
                    value={parametro}
                    placeholder="Parâmetro"
                    onChange={(event) => setParametro(event.target.value)}
                  />
                </FormControl>
                <FormControl className="form_control" variant="outlined">
                  <InputLabel id="demo-simple-select-required-label">
                    Tipo
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setTipo(event.target.value)}
                    value={tipo}
                    label={'Tipo'}
                    placeholder="Tipo"
                  >
                    <MenuItem value="">
                      <em>Selecione um tipo</em>
                    </MenuItem>
                    <MenuItem value="9999">
                      <em>Texto</em>
                    </MenuItem>
                    {itens.map((item) => (
                      <MenuItem key={item.uuid} value={item.id}>
                        {item.respostas}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="form_control">
                    <TextField
                    variant="outlined"
                    label={'Ordem'}
                    value={ordem}
                    placeholder="Ordem"
                    type="number"
                    onChange={event => setOrdem(event.target.value)}
                    />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={foto_obrigatoria}
                        onChange={handleFotoObrigatoria}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Foto obrigatória?"
                  />
                </FormControl>
                <FormControl className="form_control" variant="outlined">
                  <InputLabel id="demo-simple-select-required-label">
                    Status
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setStatus(event.target.value)}
                    value={status}
                    label={'Status'}
                    placeholder="Status"
                  >
                    <MenuItem value="">
                      <em>Selecione um status</em>
                    </MenuItem>
                    <MenuItem value={1}>Ativo</MenuItem>
                    <MenuItem value={2}>Inativo</MenuItem>
                  </Select>
                </FormControl>
              </S.Column>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="container_acao">
          <ButtonGroup
            orientation="horizontal"
            color="primary"
            className="buttonGroup"
            aria-label="vertical outlined primary button group"
          >
            <Button className="botao add" variant="contained" type="submit">
              Cadastrar
            </Button>
          </ButtonGroup>
        </DialogActions>
      </S.Container>
    </Dialog>
  );
}
