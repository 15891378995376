import * as S from "./styles";
import { useState } from 'react';
import { useOrigem } from "../../hooks/origem"
import CloseIcon from "@material-ui/icons/Close";
import { TextField, FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle, DialogActions, ButtonGroup, Button, IconButton, Grid } from "@material-ui/core";
import { useEmpresa } from "../../hooks/empresa";


export function CriaNovaOrigemModal({ abre, fecha }) {
    const { criaOrigem } = useOrigem();
    const { empresaListaAtiva } = useEmpresa();

    const [titulo, setTitulo] = useState("");
    const [status, setStatus] = useState("");
    const [empresa, setEmpresa] = useState("");

    const handleCriaChecklist = async event => {
        event.preventDefault();
        
        let data = {
            titulo,
            empresa,
            status
        }

        await criaOrigem(data, fecha);
 
    }  
    const usuarioPermissao = Number(localStorage.getItem('MMPROWORK'));


    return (

        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container onSubmit={handleCriaChecklist}>
            <DialogTitle className="titulo">
                Cadastre origem
                <IconButton className="fecha" onClick={fecha}>
                <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent  className="grid_container">
                <Grid container>
                    <Grid item xs={12}>
                        <S.Column>
                            <FormControl className="form_control">
                                <TextField
                                variant="outlined"
                                label={'Título'}
                                value={titulo}
                                placeholder="Título"
                                onChange={event => setTitulo(event.target.value)}
                                />
                            </FormControl>
                            {usuarioPermissao === 1 ?
                                <FormControl className="form_control" variant="outlined" >
                                    <InputLabel id="demo-simple-select-required-label">Empresa</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={event => setEmpresa(event.target.value)}
                                        value={empresa}
                                        label={'Empresa'}
                                        placeholder="Empresa"
                                    >
                                        <MenuItem value="">
                                            <em>Selecione um empresa</em>
                                        </MenuItem>
                                        {empresaListaAtiva.length > 0 ? 
                                            empresaListaAtiva.map(lista =>  (
                                                <MenuItem key={lista.uuid} value={lista.id}>{lista.nome}</MenuItem>
                                            ))
                                        :null}
                                    </Select>
                                </FormControl>
                            : null}
                            
                            <FormControl className="form_control" variant="outlined" >
                                <InputLabel id="demo-simple-select-required-label">Status</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={event => setStatus(event.target.value)}
                                    value={status}
                                    label={'Status'}
                                    placeholder="Status"
                                >
                                    <MenuItem value="">
                                        <em>Selecione um status</em>
                                    </MenuItem>
                                    <MenuItem value={1}>Ativo</MenuItem>
                                    <MenuItem value={2}>Inativo</MenuItem>
                                </Select>
                            </FormControl>

                        </S.Column>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions className="container_acao">
                <ButtonGroup
                orientation="horizontal"
                color="primary"
                className="buttonGroup"
                aria-label="vertical outlined primary button group"
                >
                <Button
                    className="botao add"
                    variant="contained"
                    type="submit"
                >
                    Cadastrar
                </Button>
                </ButtonGroup>
            </DialogActions>
            </S.Container>
        </Dialog>
    )
}