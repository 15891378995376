import React from "react";
import {Box} from '@material-ui/core'
import { FormEditaUsuario } from "../../components/FormEditaUsuario/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { UsuarioProvider } from "../../hooks/user";
import { EmpresaProvider } from "../../hooks/empresa";


export function EditUser() {
    
  return (
    <UsuarioProvider>
      <EmpresaProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'2'} />
            <div style={{width: '100%'}}>
                <Topbar pageTitle={'Editar usuário'} />
                <Box  className="container_admin">
                    <FormEditaUsuario />
                </Box>
            </div>
        </div>
      </EmpresaProvider>
    </UsuarioProvider>
  );
};


