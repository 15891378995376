import React from "react";
import {Box} from '@material-ui/core'
import { FormCadastraCliente } from "../../components/FormCadastraCliente/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { ClienteProvider } from "../../hooks/cliente";
import { EmpresaProvider } from "../../hooks/empresa";


export function NewCliente() {
    
  return (
    <ClienteProvider>
      <EmpresaProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'3'} />
            <div style={{width: '100%'}}>
                <Topbar pageTitle={'Cadastrar pastas'} />
                <Box className="container_admin">
                    <FormCadastraCliente />
                </Box>
            </div>
        </div>
      </EmpresaProvider>
    </ClienteProvider>
  );
};


