
import React, {useState} from "react";
import {Box} from '@material-ui/core'
import { ItemRespostasTable } from "../../components/ItemRespostasTable";

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { ItemRespostasProvider } from "../../hooks/itemRespostas";
import { EmpresaProvider } from "../../hooks/empresa";
import { CriaNovoItemRespostasModal } from "../../components/CriaNovoItemRespostasModal";
import { EditaItemRespostasModal } from "../../components/EditaItemRespostasModal";

export function ItemRespostas() {
    
  const [abreModalAdd, setAbreModalAdd] = useState(false);
  const [abreModalEdita, setAbreModalEdita] = useState(false);

  function handleAbreModalAdd(){
      setAbreModalAdd(true);
  }

  function handleFechaAddtoModal(){
    setAbreModalAdd(false);
  }
    function handleAbreModalEdita(){
      setAbreModalEdita(true);
  }

  function handleFechaEditaModal(){
    setAbreModalEdita(false);
  }

  return (
      <ItemRespostasProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'7'} />
            <div style={{width: '100%'}}>
              <Topbar pageTitle={'Respostas'} />
              <Box className="container_admin">
                  <ItemRespostasTable onOpenAddModal={handleAbreModalAdd} onOpenEditaModal={handleAbreModalEdita}   />
              </Box>
            </div>
            <EmpresaProvider>
              {abreModalAdd ? <CriaNovoItemRespostasModal
                abre={abreModalAdd}
                fecha={handleFechaAddtoModal}
              /> : null}
              {abreModalEdita ? <EditaItemRespostasModal
                abre={abreModalEdita}
                fecha={handleFechaEditaModal}
              /> : null}
            </EmpresaProvider>
        </div>
      </ItemRespostasProvider>
  );
};


