import styled from 'styled-components'


export const Container = styled.div`
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255,255,255,0.5);
    z-index: 9999;
    html, body, #root, main {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
    }

    span {
        color: white;
        font-size: 30px;
        text-align: center;
        font-family: Helvetica, sans-serif;
    }

    .loader {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    .loader-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .loader-container span {
        color: var(--sistema);
        margin-bottom: 50px;
    }
    .loader div {
        position: absolute;
        border: 4px solid var(--sistema);
        opacity: 1;
        border-radius: 50%;
        animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .loader div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes loader {
        0% {
            top: 28px;
            left: 28px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: -1px;
            left: -1px;
            width: 58px;
            height: 58px;
            opacity: 0;
        }
    }
`
