import * as S from "./styles";
import { useState, useCallback} from 'react';
import { useTag } from "../../hooks/tag"
import { TextField, FormControl, Dialog, DialogContent, DialogTitle, DialogActions, Button,Paper } from "@material-ui/core";

import Draggable from 'react-draggable';
function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} /> 
      </Draggable>
    );
}


export function AdicionaTagitemModal({ abre, fecha, uuid}) {

    const { criaItemTag, VisualizarItemTagPorUUID } = useTag();


    const [nome_tag, setNomeTag] = useState('');


    const handleListaItemTag = useCallback(async () => {
        await VisualizarItemTagPorUUID(uuid);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);
    

    const HandleCriaItemTag = async (e) => {
        e.preventDefault();
        
        let data = {
            vinculo: uuid,
            nome: nome_tag,
            status: 1
        }

        let result = await criaItemTag(data);

        if(result.erro === false) {
            fecha();
            // eslint-disable-next-line react-hooks/rules-of-hooks
            handleListaItemTag();
        }
    };


    return (
        <Dialog
            open={abre}
            onClose={fecha}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            className="modal_dialog"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <p> Adicionar item</p>
            </DialogTitle>
            <DialogContent>
                <S.FormQrCode onSubmit={HandleCriaItemTag}> 
                    <S.Column>
                        <FormControl variant="outlined" >
                            <TextField
                            variant="outlined"
                            label={'Nome'}
                            value={nome_tag}
                            placeholder=""
                            onChange={event => setNomeTag(event.target.value)}
                            />
                        </FormControl>

                    </S.Column>

                    <DialogActions>
                        <Button autoFocus onClick={fecha} color="primary">
                            Cancelar
                        </Button>
                        <Button
                            className="botao add"
                            variant="contained"
                            type="submit"
                        >
                        Cadastrar
                        </Button>
                    </DialogActions>
                </S.FormQrCode>
            </DialogContent>
        </Dialog>
    )
}



