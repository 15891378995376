import React from "react";
import {Box} from '@material-ui/core'
import { FormCadastraTarefa } from "../../components/FormCadastraTarefa/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { TarefaProvider } from "../../hooks/tarefa";
import { UsuarioProvider } from "../../hooks/user";
import { OrigemProvider } from "../../hooks/origem";

import { CheckListProvider } from "../../hooks/checklist";
import { EmpresaProvider } from "../../hooks/empresa";
import { TagProvider } from "../../hooks/tag";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment'
import MomentUtils from "@date-io/moment";

export function NewTarefa() {
    
  return (
      <TarefaProvider>
          <UsuarioProvider>
            <OrigemProvider>
              <CheckListProvider>
                <TagProvider>
                    <EmpresaProvider>
                      <div style={{display: 'flex'}}>
                          <Sidebar activePage={'4'} />
                          <div style={{width: '100%'}}>
                              <Topbar pageTitle={'Cadastrar auditoria'} />
                              <Box className="container_admin">

                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <FormCadastraTarefa />
                                </MuiPickersUtilsProvider>
                              </Box>
                          </div>
                      </div>
                    </EmpresaProvider>
                  </TagProvider>
              </CheckListProvider>
            </OrigemProvider>
          </UsuarioProvider>
      </TarefaProvider>
  );
};


