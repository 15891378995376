import styled from 'styled-components'


export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  padding: 20px;

  .react-calendar {
    width: 100%;
  }

  .calander-details {
    font-size: 1.5em;
    line-height: 1.5em;
    color: #333;
    &:first-letter {
      text-transform: capitalize;
    }
  }

  .circleBackground{
    display: flex;
    flex-direction: row;

  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 5px;
  }

.circle  h1{
  color: #FFF;
}

  .circle.iniciado {
    background-color: var(--info-color);
  }

  .circle.atrasada {
    background-color: var(--error-color);
  }

  .circle.pendente {
    background-color: var(--warning-color);
  }

  .circle.concluida {
    background-color: var(--green);
  }

  .circle.andamento {
    background-color: var(--warning-color);
  }
  
  .legenda{
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      p {
          font-size: 1.2em;
          line-height: 1.5em;
      }
      .atrasada::before {
          content: " ";
          width: 10px;
          height: 40px;
          background: var(--error-color);
          border-color: var(--error-color);
          border-style: solid;
          border-radius: 5px;
          padding-right: 20px;
          margin-right: 10px;
      }
      .pendente{
          margin-right: 20px;
      }
      .pendente::before {
          content: " ";
          width: 10px;
          height: 40px;
          background: var(--warning-color);
          border-color: var(--warning-color);
          border-style: solid;
          border-radius: 5px;
          padding-right: 20px;
          margin-right: 10px;
      }
      .iniciado{
          margin-right: 20px;
      }
      .iniciado::before {
          content: " ";
          width: 10px;
          height: 40px;
          background: var(--info-color);
          border-color: var(--info-color);
          border-style: solid;
          border-radius: 5px;
          padding-right: 20px;
          margin-right: 10px;
      }
      .concluida{
          margin-left: 20px;
      }
      .concluida::before {
          content: " ";
          width: 10px;
          height: 40px;
          background: var(--green);
          border-color: var(--green);
          border-style: solid;
          border-radius: 5px;
          padding-right: 20px;
          margin-right: 10px;
      }
      .andamento{
          margin-left: 20px;
      }
      .andamento::before {
          content: " ";
          width: 10px;
          height: 40px;
          background: var(--warning-color);
          border-color: var(--warning-color);
          border-style: solid;
          border-radius: 5px;
          padding-right: 20px;
          margin-right: 10px;
      }
  }
  .container {
    width: 100%;
    background-color: #FFF;
    padding: 20px;
    h2 {
      font-size: 1.4em;
      line-height: 1.4em;
    }
  }

  .events-list {
    width: 100%;
    font-size: 0.9em;
  }

  .events-list tr td {
    padding: 5px 20px 5px 0;
  }

  .events-list tr td:last-child {
    padding: 5px 0;
    text-align: right;
  }


  .events-list .event-date {
    margin: 3px 0;
    padding: 2px 10px;
    border-left: 5px solid #CFCFCF;
    -webkit-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -o-transition: all .25s linear;
    -ms-transition: all .25s linear;
    transition: all .25s linear;
    color: #333;
    font-size: 1.2em;
    font-weight: 600;
    text-align: left;
   
    &.atrasada {
        border-left: 5px solid var(--error-color);
    }
    &.pendente {
        border-left: 5px solid var(--warning-color);
    }
    &.iniciado {
        border-left: 5px solid var(--info-color);
    }
    &.concluida {
        border-left: 5px solid var(--green);
    }
    &.andamento {
        border-left: 5px solid var(--warning-color);
    }
    cursor: pointer
  }

`


export const ContainerPagination = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
    border-top: 1px solid #CCC;
    padding-top: 40px;
    .rs-pagination > li.rs-pagination-btn-active > a{
        color: var(--red);
    }
    .rs-table-pagination-start {
        display: none;
    }
`
