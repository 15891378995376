import React from 'react';

import Sidebar from '../../components/Sidebar/Sidebar';
import {Box} from '@material-ui/core'
import Topbar from '../../components/Topbar/index';
import { AcaoProvider } from '../../hooks/acao';
import { AuditoriaProvider } from '../../hooks/auditoria';
import { HistoricoProvider } from '../../hooks/historico';
import AcaoLista from './lista';

export function Acao() {
  return (
    <AcaoProvider>
      <AuditoriaProvider>
        <HistoricoProvider>
          <div style={{ display: 'flex' }}>
            <Sidebar activePage={'8'} />
            <div style={{ width: '100%' }}>
              <Topbar pageTitle={'Tarefas'} />
              <Box className="container_admin">
                <AcaoLista />            
              </Box>
            </div>
          </div>
        </HistoricoProvider>
      </AuditoriaProvider>
    </AcaoProvider>
  );
}
