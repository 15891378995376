
import React from "react";
import {Box} from '@material-ui/core'
import { RespostaTableUUID } from "../../components/RespostaTableUUID/index";

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { TarefaProvider } from "../../hooks/tarefa";
import { ClienteProvider } from "../../hooks/cliente";
import { RespostaProvider } from "../../hooks/resposta";

export function DetalheResposta() {
    
    
  return (
      <TarefaProvider>
        <ClienteProvider>
          <RespostaProvider>
            <div style={{display: 'flex'}}>
                <Sidebar activePage={'5'} />
                <div style={{width: '100%'}}>
                  <Topbar pageTitle={'Respostas'} />
                  <Box  className="container_admin">
                      <RespostaTableUUID />
                  </Box>
                </div>
            </div>
          </RespostaProvider>
        </ClienteProvider>
      </TarefaProvider>
  );
};


