import { PerfilForm } from "../../components/PerfilForm/index";
import Topbar from "../../components/Topbar/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import { PerfilProvider } from "../../hooks/perfil";
import {Box} from '@material-ui/core'

export function Perfil() {

    return (
        <div>
            <PerfilProvider>
                <div style={{display: 'flex'}}>
                    <Sidebar activePage={'perfil'} />
                    <div style={{width: '100%'}}>
                        <Topbar pageTitle={'Perfil'} />
                        <Box  className="container_admin">
                            <PerfilForm />
                        </Box>
                    </div>
                </div>
            </PerfilProvider>
        </div>
    );
}
