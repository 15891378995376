import React, { useState } from 'react';

import { userLogin, userSenha } from '../hooks/auth';
import logo from '../assets/logo_azul.svg';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ErrorMessage from '../components/ErrorMessage';
import Alert from '@material-ui/lab/Alert';
import {Box} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: '3px'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  senha: {
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mostraFormSenha, setMostraFormSenha] = useState(false);
  
  const classes = useStyles();

  const handleSubmit = async event => {
    event.preventDefault();

    setIsLoading(true);

    try {
			localStorage.clear();
      if(!mostraFormSenha){
        await userLogin({ email, password });
      }else if(mostraFormSenha){var url = window.location.href;
        url = url.split('/');
        url = url[2].split('.');
        url = url[0];
        let result = await userSenha({ email, cliente: url });
        setSucesso(result);
        setEmail('');
      }
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  const handleSenha = async () => {
    setMostraFormSenha(!mostraFormSenha);
    setPassword('');
    setEmail('');
  };


  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="Logo" style={{marginBottom: "20px"}} />
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          {error && <ErrorMessage message={error} />}
          {sucesso && 
          <Box my={4}>
              <Alert severity="success">{sucesso}</Alert>
          </Box>
          }
            <TextField
              variant="outlined"
              margin="normal"
              autoComplete={false}
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              onChange={event => setEmail(event.currentTarget.value)}
            />
          {!mostraFormSenha ?
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete={false}
              onChange={event => setPassword(event.currentTarget.value)}
            />
          : null }
          {!mostraFormSenha ?
          <div style={{cursor: "pointer", marginTop: '20px'}} onClick={() => handleSenha()}>
              <Typography variant="subtitle1" gutterBottom 
            className={classes.senha}>
                Esqueci minha senha
              </Typography>
          </div>
          : null}
          {mostraFormSenha ?
            <Typography variant="subtitle2" gutterBottom>Digite um e-mail válido para receber as instruções para recuperar a sua senha</Typography>
          : null}
          {!mostraFormSenha ?
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isLoading ? (
              <CircularProgress
                isIndeterminate
                size="24px"
                color="red"
              />
            ) : (
              'Entrar'
            )}
          </Button>
          : 
          <>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{marginTop: '20px'}}
            className={classes.submit}
          >
            {isLoading ? (
              <CircularProgress
                isIndeterminate
                size="24px"
                color="inherit"
              />
            ) : (
              'Recuperar senha'
            )}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="inherit"
            onClick={() => handleSenha()}
          >
            Cancelar
          </Button>
          </>
        }
        </form>
      </div>
    </Container>
  );
}