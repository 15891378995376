import * as S from "./styles";
import { useState } from 'react';
import { useCheckList } from "../../hooks/checklist"
import { useTarefa } from "../../hooks/tarefa"
import CloseIcon from "@material-ui/icons/Close";
import { FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle, DialogActions, ButtonGroup, Button, IconButton, Grid } from "@material-ui/core";

import {
    useParams
  } from "react-router-dom";

export function CriaNovoCheckListTarefaModal({ abre, fecha }) {
    const { checklistLista, isLoading } = useCheckList();
    const { vinculaChecklist } = useTarefa();
    
    const [checklist, setCheckList] = useState("");    
    const { id } = useParams();


    const handleCriaChecklist = async event => {
        event.preventDefault();
        
        let data = {
            checklist,
            tarefa: id
        }

        await vinculaChecklist(data, fecha);
 
    }

    return (

        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container onSubmit={handleCriaChecklist}>
            <DialogTitle className="titulo">
                Vincule um questionário
                <IconButton className="fecha" onClick={fecha}>
                <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent  className="grid_container">
                <Grid container>
                    <Grid item xs={12}>
                        <S.Column>
                            <FormControl className="form_control" variant="outlined" >
                                <InputLabel id="demo-simple-select-required-label">Questionário</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={event => setCheckList(event.target.value)}
                                    value={checklist}
                                    label={'Questionário'}
                                    placeholder="Questionário"
                                >
                                    <MenuItem value="">
                                        <em>Selecione um questionário</em>
                                    </MenuItem>
                                    
                                    {isLoading ? 
                                        <MenuItem>Aguarde</MenuItem>
                                    : checklistLista.map(lista =>  (
                                        <MenuItem key={lista.uuid} value={lista.uuid}>{lista.titulo}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </S.Column>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions className="container_acao">
                <ButtonGroup
                orientation="horizontal"
                color="primary"
                className="buttonGroup"
                aria-label="vertical outlined primary button group"
                >
                <Button
                    className="botao add"
                    variant="contained"
                    type="submit"
                >
                    Cadastrar
                </Button>
                </ButtonGroup>
            </DialogActions>
            </S.Container>
        </Dialog>
    )
}