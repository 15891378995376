import * as S from "./styles";


export function Loading() {

    return (
        <S.Container >
            <div className="loader-container">
                <span>Carregando</span>
                <div className="loader">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </S.Container>
    );
}
