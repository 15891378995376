import * as S from "./styles";
import React from "react";
import { Dialog, DialogTitle, IconButton, DialogContent} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";


export function MensagemModal({abre, fecha, mensagem}) {

    return (
        <Dialog open={abre} onClose={fecha} >
            <S.Container>
                <DialogTitle className="titulo">
                    <IconButton className="fecha" onClick={fecha}>
                        <CloseIcon />
                        Fechar
                    </IconButton>
                </DialogTitle> 
                <DialogContent>
                    {mensagem ?
                        <S.Container>
                            <p>{mensagem}</p>
                        </S.Container>
                    : null}
                </DialogContent>
            </S.Container>
        </Dialog>
    );
}


