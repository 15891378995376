import styled from 'styled-components'

export const InfoQuestionario =  styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
    h1 {
        font-size:1.5em;
        line-height: 1.5em;
    }
    p{
        font-size:1.4em;
        line-height: 1.5em;
    }
`

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
    @media (max-width: 768px) {
	    flex-direction: column;
    }
    .MuiFormControl-root{
        margin-bottom: 20px;
    }
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-right: 5px;
    .MuiFormControl-root{
        margin-bottom: 8px;
    }
`;

export const TituloGrande = styled.h1`
	display: flex;
	width: 100%;
	margin-right: 5px;
    font-size: 1.2em;
    line-height: 1.5em;
    span{
        font-weight: bold;
    }
`;

export const Respostas = styled.div`
	display: flex;
    flex-direction: column;
	width: 100%;
	margin-right: 5px;
    font-size: 1.2em;
    line-height: 1.5em;
    span{
        font-weight: bold;
    }
    p{
        margin-top: 5px;
    }
    p span {
        color: #666;
    }
`;
export const TituloPequeno = styled.div`
	display: flex;
	width: 100%;
	margin-right: 5px;
    font-size: 1em;
    line-height: 1.5em;
`;

export const ButtonDeletar = styled.div`
	display: flex;
    align-items: center;
    justify-content: center;
	width: 100px;
    height: 40px;
	margin-right: 5px;
    font-size: 1.2em;
    line-height: 1.5em;
    text-align: center;
    color: #FFF;
    background-color: var(--red);
    border-radius: 0.25rem;
    border: none;
    margin-bottom: 1.25rem;
    transition: filter 0.2s;
    cursor: pointer;

    &:hover{
        filter: brightness(0.9)
    }
    &:focus{
        outline: none
    }
    span{
        font-weight: bold;
    }
`;
export const ButttonEditar = styled.div`
	display: flex;
    align-items: center;
    justify-content: center;
	width: 100px;
    height: 40px;
	margin-right: 5px;
    font-size: 1em;
    line-height: 1.5em;
    text-align: center;
    color: #FFF;
    background-color: var(--green);
    border-radius: 0.25rem;
    border: none;
    margin-bottom: 1.25rem;
    transition: filter 0.2s;
    cursor: pointer;

    &:hover{
        filter: brightness(0.9)
    }
    &:focus{
        outline: none
    }
`;

export const ButttonChecklist = styled.div`
	display: flex;
    align-items: center;
    justify-content: center;
	width: 100px;
    height: 40px;
	margin-right: 5px;
    font-size: 1em;
    line-height: 1.5em;
    text-align: center;
    color: #FFF;
    background-color: var(--text-title);
    border-radius: 0.25rem;
    border: none;
    margin-bottom: 1.25rem;
    transition: filter 0.2s;
    cursor: pointer;

    &:hover{
        filter: brightness(0.9)
    }
    &:focus{
        outline: none
    }
`;

export const Container = styled.div`
    margin-top: 2rem;
    padding-left: 20px;
    padding-right: 20px;

    button {
        height: 3rem;
        padding: 0 2rem;
        font-size: 1rem;
        color: #FFF;
        background-color: var(--info-color);
        border-color: var(--info-color);
        border-radius: 0.25rem;
        border: none;
        margin-bottom: 1.25rem;
        transition: filter 0.2s;
        cursor: pointer;

        &:hover{
            filter: brightness(0.9)
        }
        &:focus{
            outline: none
        }
    }
    .MuiPagination-ul{
        button{
            height: auto;
            padding: 5px;
        }
    }
    table {
        width: 100%;
        border-spacing: 0 0.5rem;

        th {
            color: var(--text-body);
            font-weight: 400;
            padding: 15px 2rem;
            text-align: left;
            line-height: 1.5em;
            background-color: #FFFFFF;
            filter: brightness(0.9);
            &.status {
                text-align: center;
            }
            &.acao {
                max-width: 50px;
                text-align: center;
            }
            @media screen and (max-width: 600px) {
                &:nth-child(2) {
                    display: none;
                }
            }
        }

        tr {
            cursor: pointer;
            border: 2px solid #CCC;
            &:hover{
                filter: brightness(0.9);
            }
        }
        
        td {
            padding: 1rem 2rem;
            border: 0;
            background: var(--shape);
            font-weight: 400;
            color: var(--text-body);

            @media screen and (max-width: 600px) {
                &:nth-child(2) {
                    display: none;
                }
            }

            &:first-child{
                color: var(--text-title);
            }

            &.deletar{
                color: #CCC;
                text-align: center;
                p{
                    color: var(--red);
                }
                button {
                    background-color: transparent;
                    color: #CCC;
                    padding: 0px;
                    margin-bottom:0px;
                }
            }


        }

        .ativo  {
            vertical-align: middle;
            div{
                margin: 0 auto;
                content: ' ';
                height: 25px;
                width: 8px;
                background: var(--green);
                border-radius: 2px;
            }
        }

        .inativo {
            vertical-align: middle;
            div{
                margin: 0 auto;
                content: ' ';
                height: 25px;
                width: 8px;
                background: var(--red);
                border-radius: 2px;
            }
        }
    }
`

export const ContainerPagination = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .rs-pagination > li.rs-pagination-btn-active > a{
        color: var(--red);
    }
    .rs-table-pagination-start {
        display: none;
    }
`