import styled from 'styled-components'

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
    @media (max-width: 768px) {
	    flex-direction: column;
    }
    .MuiFormControl-root{
        margin-bottom: 20px;
    }
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-right: 5px;
    .MuiFormControl-root{
        margin-bottom: 8px;
    }
`;

export const Container = styled.div`
    min-height: 60vh;
    max-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        font-size: 1.5em;
            line-height: 1.5em;
        border-bottom: 1px solid #CCCCCC;
        margin: 10px 0px;
    }
    .topo {
        width: 100%;
    }
    .topo h2{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .titulo {
        width: 100%;
        h2 {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;
            font-size: 1.2em;
            line-height: 1.5em;
        }
        span {
            font-size: 0.9em;
            line-height: 1.2em;
            font-weight: normal;
            margin-left: 5px;
        }
        .close  {
          position: absolute;
          right: 10px;
          top: 10px;
          color: #CCC;
        }
    }

    .grid_container {
        margin-top: 10;
        margin-bottom: 20;
        width: 100%;
        flex-grow: 1;
        background-color: #f6f6f6;
        padding: 20px;

        .form_control {
            margin-bottom: 20px;
        }
    }

`
