import styled from 'styled-components'

export const Content = styled.div`
    max-width: 600px;
    text-align: center;
`

export const TituloErro = styled.h2`
    font-size: 18vw;
    color: #fff;
    line-height: 1em;
`

export const TituloErroSubtitulo = styled.h4`
    position: relative;
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #111;
    background: #fff;
    font-weight: 300;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 3px;
`

export const Message = styled.p`
    color: #fff;
    font-size: 1.2em;
`

export const LinkHome = styled.a`
    position: relative;
    display: inline-block;
    padding: 10px 25px;
    background: var(--red);
    color: #fff;
    text-decoration: none;
    margin-top: 25px;
    border-radius: 25px;
    border-bottom: 4px solid var(--red);

    &:hover{
        color: #fff;
    }
    &:active{
        color: #fff;
    }
`