import styled from 'styled-components'

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
    @media (max-width: 768px) {
	    flex-direction: column;
    }
    .MuiFormControl-root{
        margin-bottom: 20px;
    }
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-right: 5px;
    .MuiFormControl-root{
        margin-bottom: 8px;
    }
`;

export const Container = styled.div`
    min-height: 15vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid var(--info-color);
    padding-bottom: 15px;

    p {
        color: var(--info-color);
        font-size: 1.5em;
        line-height: 1.5em;
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 3px;
        text-align: center;
    }
    button.fecha {
        background-color: var(--red);
        color: #FFF;
        font-size: 0.9em;
        line-height: 1.5em;
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 3px;
        .MuiSvgIcon-root{
            margin-right: 5px;
        }
    }
`