import api from "../services/api";
import {createContext, useState, useContext, } from 'react';
import { toast } from 'react-toastify';

const EmailContext = createContext({});

export const EmailProvider = (props) =>  {
    const [emailPorUUID, setEmailPorUUID] = useState([]);
    const [isLoadingEmail, setIsLoadingEmail] = useState(false);
    const [isLoadingEditID, setIsLoadingEditID] = useState(false);


    //  EMAIL POR UUID
    
    async function VisualizarEmailPorUUID(uuid, setDestinatario, setAssunto, setCorpoEmail, setCheckedEmail) {
        try {
            setIsLoadingEditID(true);
            const responseedit = await api.get('/email/'+uuid);
            if(responseedit.data && responseedit.data.length > 0) {
                setCheckedEmail(true);
                setDestinatario(responseedit.data[0]?.destinatario);
                setAssunto(responseedit.data[0]?.assunto);
                setCorpoEmail(responseedit.data[0]?.corpo);
            }
            setEmailPorUUID(responseedit.data);
            setIsLoadingEditID(false);
            
            return {
                erro: false
            }
        } catch (err) {
            setIsLoadingEditID(true);
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingEditID(false);
            return {
                erro: true,
            }
        }
    }
    
    
    // CRIA EMAIL

    async function criaEmail(dados) {
        try {
            setIsLoadingEmail(true);
            const responseedit = await api.post('/email', dados); 
            toast.success(responseedit.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoadingEmail(false);
            return {
                erro: false
            }
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEmail(false);

            return {
                erro: true
            }
        }
    }
    // EDITA ITEM EMAIL

    async function editaEmail(uuid, dados) {
        try {
            setIsLoadingEmail(true);
            const responseedit = await api.put('/email/'+uuid, dados); 
            toast.success(responseedit.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoadingEmail(false);
            return {
                erro: false
            }
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEmail(false);

            return {
                erro: true
            }
        }
    }

    // DELETA ITEM EMAIL
    async function deletaEmail(uuid) {
        try {
            setIsLoadingEmail(true);
            const responseedit = await api.delete('/email/'+uuid); 
            toast.success(responseedit.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoadingEmail(false);
            return {
                erro: false
            }
          
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingEmail(false);

            return {
                erro: true
            }
        }
    }
    

    

    return (
        <EmailContext.Provider value={{isLoadingEmail,  isLoadingEditID, VisualizarEmailPorUUID, emailPorUUID, criaEmail, editaEmail, deletaEmail}}>  
            {props.children}
        </EmailContext.Provider>
    )

}



export function useEmail() {
    const context = useContext(EmailContext);

    return context;
}
