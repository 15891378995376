import React from "react";
import {Box} from '@material-ui/core'
import { VisualizaTarefa } from "../../components/VisualizaTarefa/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import { TarefaProvider } from "../../hooks/tarefa";
import { HistoricoProvider } from "../../hooks/historico";
import { OrigemProvider } from "../../hooks/origem";
import { CheckListProvider } from "../../hooks/checklist";
import { TagProvider } from "../../hooks/tag";
import { EmailProvider } from "../../hooks/email";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment'
import MomentUtils from "@date-io/moment";

export function VisualizarTarefa() {

  return (
      <TarefaProvider>
          <HistoricoProvider>
            <OrigemProvider>
              <CheckListProvider>
                <TagProvider>
                    <EmailProvider>
                      <div style={{display: 'flex'}}>
                          <Sidebar activePage={'4'} />
                          <div style={{width: '100%'}}>
                              <Topbar pageTitle={"Visualizar Auditorias"} />
                              <Box className="container_admin">
                                  
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <VisualizaTarefa />
                                </MuiPickersUtilsProvider>
                              </Box>
                          </div>
                      </div>
                    </EmailProvider>
                  </TagProvider>
                </CheckListProvider>
              </OrigemProvider>
          </HistoricoProvider>
      </TarefaProvider>
  );
};


