import { useState, useEffect } from 'react';
import * as S from "./styles";
import Skeleton from "react-loading-skeleton";
import { useTarefa } from "../../hooks/tarefa";
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Paper } from '@material-ui/core';
import Draggable from 'react-draggable';
import { Loading } from "../Loading/index";


import {
    useParams
  } from "react-router-dom";

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export function CheckListTarefaTable({onOpenAddModal}) {
    let history = useHistory();
    const { checklistPage, isLoadingEdit, deletarCheckListTarefa, getCheckListTarefaPorUuid, checklistLista, paginationChecklist } = useTarefa();
    const { id } = useParams();



    const [openConfirm, setOpenConfirmarDeletar] = useState(false);
    const [nomeCheckList, setNomeCheckList] = useState('');
    const [uuidCheckList, setUuidCheckList] = useState('');
    const [loading, setLoading] = useState(false);
  
    const handleSelect = async (event, value) => {
        await checklistPage(value);
    }
    
    const handleClickOpenEdit = async (uuid) => {
        history.push("/checklist/"+uuid);
    };


    function onOpenNewTarefaModal() {
        onOpenAddModal();
    }
  
    const handleClickOpenConfirm = async (nome, uuid, id) => {
        setOpenConfirmarDeletar(true);
        setNomeCheckList(nome);
        setUuidCheckList(uuid);
    };

    const handleCloseConfirm = async () => {
        setOpenConfirmarDeletar(false);
        setNomeCheckList('');
        setUuidCheckList('');
    };
    

    const handleDeletaConfirm = async (uuid) => {
        setOpenConfirmarDeletar(false);
        let data = {
            checklist:uuid,
            tarefa:id
        }
        await deletarCheckListTarefa(data, handleCloseConfirm);
        setNomeCheckList('');
        setUuidCheckList('');
    };

    
    useEffect(() => {
        setLoading(true);
            getCheckListTarefaPorUuid(id);
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <S.Container>
            <button type="button" onClick={onOpenNewTarefaModal}>
            Novo Questionário
            </button>

            <table>
                <thead>
                    <tr>
                        <th>Título</th>
                        <th>Data de criação</th>
                        <th className="status">Status</th>
                        <th className="acao">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoadingEdit || loading ? 
                        <Loading />
                    : checklistLista.map(lista =>  (
                        <tr
                            key={lista.uuid}
                        >
                            <td
                            onClick={() => handleClickOpenEdit(lista.uuid,paginationChecklist[0].pagina_atual)} data-label="Título">{isLoadingEdit ? <Skeleton /> : lista.titulo}</td>
                            <td
                            onClick={() => handleClickOpenEdit(lista.uuid,paginationChecklist[0].pagina_atual)} data-label="Data de criação">
                                {isLoadingEdit ? <Skeleton /> : lista.data_criacao?.br}
                            </td>
                            <td className={lista.status === 1 ? 'ativo' : 'inativo'} 
                            onClick={() => handleClickOpenEdit(lista.uuid,paginationChecklist[0].pagina_atual)} data-label="Status">
                                {isLoadingEdit ? <Skeleton /> : <div></div>}
                            </td>
                            <td className="deletar" 
                            onClick={() => handleClickOpenConfirm(lista.titulo, lista.uuid, id)} data-label="Status">
                                <p
                                >
                                    Deletar
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <S.ContainerPagination>
                {paginationChecklist.length ?
                <Pagination
                    showLastButton={true}
                    showFirstButton={true}
                    color="primary"
                    count={paginationChecklist[0].ultima_pagina}
                    page={Number(paginationChecklist[0].pagina_atual)}
                    onChange={handleSelect}
                />
                : null}
            </S.ContainerPagination>
            
            <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Deletar
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Tem certeza que deseja deletar o <b> {nomeCheckList || ''}</b>, essa ação não poderá ser desfeita
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleCloseConfirm()} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => handleDeletaConfirm(uuidCheckList || '')} color="secondary">
                    Deletar
                </Button>
                </DialogActions>
            </Dialog> 
        </S.Container>
    );
}
