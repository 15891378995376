/* import React, {useState} from "react"; */
import {Box} from '@material-ui/core'

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import {CalendarioComponent2} from "../../components/CalendarioComponent2/index";

/* import { CriarTarefaModal } from "../../components/CriarTarefaModal/index"; */
import { TarefaProvider } from "../../hooks/tarefa";
import { OrigemProvider } from "../../hooks/origem";

export function Agenda() {

 /*  
    const [abreModalTarefa, setAbreModalTarefa] = useState(false);
    
    function handleAbreModalTarefa(){
        setAbreModalTarefa(true);
    }

    function handleFechaModalTarefaModal(){
        setAbreModalTarefa(false);
    } */

  return (
      <TarefaProvider>
          <OrigemProvider>
            <div style={{display: 'flex'}}>
                <Sidebar activePage={'4'} />
                <div  style={{width: '100%', marginBottom: '40px'}}>
                  <Topbar pageTitle={'Agenda'} />
                    <Box className="container_admin">
                        <CalendarioComponent2 />
                        {/* <CalendarioComponent onOpenAddModal={handleAbreModalTarefa} /> */}
                  </Box>
                </div>
            </div>
        {/*     {abreModalTarefa ?
                <CriarTarefaModal 
                abre={abreModalTarefa}
                fecha={handleFechaModalTarefaModal}
                />
            : null} */}
          </OrigemProvider>
    </TarefaProvider>
  );
};


