import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 2rem;
    padding-left: 20px;
    padding-right: 20px;

    button {
        height: 3rem;
        padding: 0 2rem;
        font-size: 1rem;
        color: #FFF;
        background-color: var(--info-color);
        border-color: var(--info-color);
        border-radius: 0.25rem;
        border: none;
        margin-bottom: 1.25rem;
        transition: filter 0.2s;
        cursor: pointer;

        &:hover{
            filter: brightness(0.9)
        }
        &:focus{
            outline: none
        }
    }
    .MuiPagination-ul{
        button{
            height: auto;
            padding: 5px;
        }
    }
    table {
        width: 100%;
        border-spacing: 0 0.5rem;

        th {
            color: var(--text-body);
            font-weight: 400;
            padding: 15px 2rem;
            text-align: left;
            line-height: 1.5em;
            background-color: #FFFFFF;
            filter: brightness(0.9);
            &.status {
                text-align: center;
            }
            &.acao {
                max-width: 50px;
                text-align: center;
            }
            @media screen and (max-width: 600px) {
                &:nth-child(2) {
                    display: none;
                }
            }
        }

        tr {
            cursor: pointer;
            border: 2px solid #CCC;
            &:hover{
                filter: brightness(0.9);
            }
        }
        
        td {
            padding: 1rem 2rem;
            border: 0;
            background: var(--shape);
            font-weight: 400;
            color: var(--text-body);


            &:first-child{
                color: var(--text-title);
            }

            &.deletar{
                color: #CCC;
                text-align: center;
                p{
                    color: var(--red);
                }
                button {
                    background-color: transparent;
                    color: #CCC;
                    padding: 0px;
                    margin-bottom:0px;
                }
            }


        }

        .ativo  {
            vertical-align: middle;
            div{
                margin: 0 auto;
                content: ' ';
                height: 25px;
                width: 8px;
                background: var(--green);
                border-radius: 2px;
            }
        }

        .inativo {
            vertical-align: middle;
            div{
                margin: 0 auto;
                content: ' ';
                height: 25px;
                width: 8px;
                background: var(--red);
                border-radius: 2px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }
        
        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        
        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }
        
        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right;
            &.deletar, &.ativo, &.inativo {
                text-align: right;
                max-width: 100%;
                color: var(--text-body);
            }
            &.ativo, &.inativo {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                div {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    margin: 0px;
                    width: 100%;
                    max-width: 8px;
                }
            }
        }
        
        table td::before {
            /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }
        
        table td:last-child {
            border-bottom: 0;
        }
    }
`

export const ContainerPagination = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .rs-pagination > li.rs-pagination-btn-active > a{
        color: var(--red);
    }
    .rs-table-pagination-start {
        display: none;
    }
`
